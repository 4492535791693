import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    fontWeight: "400 !important",
    borderRadius: "6px !important",
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
    padding: "12px 32px 12px 12px !important",
  },

  listMenu: {
    color: Colors.font1,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const pcStatusOptions = [
  { label: "All statuses", value: "All statuses" },
  { label: "Draft", value: "Draft" },
  { label: "Sent", value: "Sent" },
  { label: "Dismissed", value: "Dismissed" },
  { label: "Approved", value: "Approved" },
];

export default function PcStatus(props) {
  const classes = useStyles();

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        value={props.defaultValue ? props.defaultValue : "All statuses"}
        id="grouped-select"
        label="Grouping"
        onChange={props.onChange}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
          disableScrollLock: true,
        }}
      >
        {pcStatusOptions &&
          pcStatusOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              classes={{
                root: classes.listMenu,
              }}
            >
              <FiberManualRecordIcon
                sx={{
                  color:
                    option.label === "Draft"
                      ? Colors.font1
                      : option.label === "Sent"
                      ? Colors.primary
                      : option.label === "Dismissed"
                      ? "#f74040"
                      : option.label === "Approved"
                      ? "#188652"
                      : "#c3c3c6",
                  fontSize: 15,
                  margin: "0 6px 0 0",
                }}
              />
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
