import React, { useState, useRef } from "react";
import styles from "./ContactForm.module.css";
import { useForm, Controller } from "react-hook-form";
import SelectQuery from "../../UIs/SelectQuery";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { postRequest } from "../../../axios/axios";
import LoaderSpin from "../../NewDashboard/Commons/LoaderSpin";
import SuccessModal from "./SuccessModal";
import Colors from "../../../Constants/Colors";
import { NavLink } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

const ContactForm = () => {
  const [file, setFile] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const ref = useRef(null);
  const {
    register,
    reset,
    setValue,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const uploadFileHandler = () => {
    const file = ref.current.files[0];
    if (file) {
      if (file.size > 2097152) {
        setFileError(true);
      } else {
        setFile(file);
      }
    }
  };

  const removeFileHandler = () => {
    ref.current.value = null;
    setFile(null);
  };

  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[A-Za-z0-9+._]+@[A-Za-z0-9]+\.[A-Za-z]{2}/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("query", data.query);
    formData.append("message", data.message);
    formData.append("agreement", data.agreement);
    setIsLoading(true);
    postRequest("/contact", {}, formData)
      .then((data) => {
        if (data && data.data && data.data.success) {
          setIsLoading(false);
          setShowModal(true);
          reset();
          removeFileHandler();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          toast.error(
            <div className={styles.toast_text}>{err.response.data.message}</div>
          );
        }
        if (err.isAxiosError) {
          toast.error(<div className={styles.toast_text}>{err.message}</div>);
        }
      });
  };

  return (
    <div className={styles.section}>
      <div className="container-fluid">
        <div className="d-flex justify-content-center">
          <div className={styles.form_rectangle}>
            <div className={styles.form_header}>
              <div className="d-flex flex-column justify-content-center align-items-center p-3 w-100 h-100">
                <div className={`${styles.form_header_heading} mb-3`}>
                  Contact our support team
                </div>
                <div className={`${styles.form_header_text} mb-1`}>
                  Share your query with us.
                </div>
                <div className={styles.form_header_text}>
                  We'll get back to you as soon as possible.
                </div>
              </div>
            </div>
            <div className={styles.form_footer}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-0 mb-md-3">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="w-100 mb-2">
                      <label
                        htmlFor="name"
                        className={`${styles.input_text} mb-2`}
                      >
                        Name<span className={styles.required}>*</span>
                      </label>
                      <div className="position-relative">
                        <input
                          className={`${styles.input_rectangle} ${
                            errors.name ? `${styles.input_error}` : ""
                          }`}
                          style={{ paddingLeft: "2.75rem" }}
                          type="text"
                          id="name"
                          name="name"
                          placeholder="George"
                          {...register("name", {
                            required: "Name can not be empty.",
                          })}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "1rem",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <PersonOutlineOutlinedIcon
                            sx={{ fontSize: 24, color: Colors.frontendFont2 }}
                          />
                        </span>
                      </div>

                      {errors.name && (
                        <div className={`${styles.error_text} mt-2`}>
                          {errors.name.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="w-100 mb-2">
                      <label
                        htmlFor="email"
                        className={`${styles.input_text} mb-2`}
                      >
                        Email<span className={styles.required}>*</span>
                      </label>
                      <div className="position-relative">
                        {" "}
                        <input
                          className={`${styles.input_rectangle} ${
                            errors.email ? `${styles.input_error}` : ""
                          }`}
                          style={{ paddingLeft: "2.75rem" }}
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Example@email.com"
                          {...register("email", {
                            required: "Email can not be empty.",
                            validate: validateEmail,
                            onBlur: (e) =>
                              setValue("email", e.target.value.trim()),
                          })}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "1rem",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <MailOutlineIcon
                            sx={{
                              fontSize: 22,
                              color: Colors.frontendFont2,
                            }}
                          />
                        </span>
                      </div>

                      {errors.email && (
                        <div className={`${styles.error_text} mt-2`}>
                          {errors.email.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-100 mb-4">
                  <label
                    htmlFor="query"
                    className={`${styles.input_text} mb-2`}
                  >
                    What's your query about?
                    <span className={styles.required}>*</span>
                  </label>
                  <div>
                    <Controller
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This field is required.",
                        },
                      }}
                      name="query"
                      render={({ field: { onChange, value } }) => (
                        <SelectQuery
                          placeholder="Choose a subject"
                          onChange={onChange}
                          invalid={errors.query}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>
                  {errors.query && (
                    <div className={`${styles.error_text} mt-2`}>
                      {errors.query.message}
                    </div>
                  )}
                </div>
                <div className="w-100 mb-4">
                  <label
                    htmlFor="message"
                    className={`${styles.input_text} mb-2`}
                  >
                    Tell us more<span className={styles.required}>*</span>
                  </label>

                  <textarea
                    className={`${styles.textarea_rectangle} ${
                      errors.message ? `${styles.input_error}` : ""
                    }`}
                    type="text"
                    rows="4"
                    id="message"
                    name="message"
                    placeholder="Enter your message here"
                    {...register("message", {
                      required: "Message can not be empty.",
                    })}
                  />
                  {errors.message && (
                    <div className={`${styles.error_text}`}>
                      {errors.message.message}
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <div className="d-flex gap-3 flex-wrap flex-sm-nowrap justify-content-between align-items-center">
                    <div className="d-flex gap-3 flex-wrap flex-xs-nowrap align-items-center">
                      <div>
                        <div className={`${styles.file_text}`}>Attach file</div>
                        <input
                          ref={ref}
                          className={styles.file_input}
                          id="file"
                          type="file"
                          size="20"
                          accept=".jpg, .jpeg, .png"
                          onChange={() => uploadFileHandler()}
                        />
                      </div>
                      <div
                        className={`${styles.file_terms} d-flex align-items-center`}
                      >
                        {file
                          ? `${file.name} (${Math.random(
                              file.size / (1024 * 1024)
                            ).toFixed(2)}MB)`
                          : "Up to 2 Mb in PNG, JPG, or JPEG format."}
                        {file && (
                          <ClearIcon
                            sx={{
                              fontSize: 20,
                              color: Colors.font1,
                              cursor: "pointer",
                              marginLeft: "5px",
                              opacity: 0.85,
                              "&:hover": {
                                opacity: 1,
                              },
                            }}
                            onClick={removeFileHandler}
                          />
                        )}
                      </div>
                    </div>
                    {!file && (
                      <div className="d-flex justify-content-end">
                        <label className={styles.file_button} htmlFor="file">
                          Upload
                        </label>
                      </div>
                    )}
                  </div>
                  {fileError && (
                    <div className={`${styles.error_text} mt-2`}>
                      File's size can't exceed more than 2MB.
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <div className={`d-flex align-items-center`}>
                    <input
                      name="agreement"
                      className={`form-check-input ${styles.checkBox_input}`}
                      type="checkbox"
                      id="flexCheckChecked"
                      {...register("agreement", {
                        required: "This field is required.",
                      })}
                    />
                    <label
                      className={`form-check-label ${styles.checkBox_label}`}
                      htmlFor="flexCheckChecked"
                    >
                      I accept the terms outlined by Zodot's
                      <span className="ms-1">
                        <NavLink
                          className={styles.btn_begin}
                          style={{
                            textDecorationThickness: "1.5px",
                            fontSize: "15px",
                            fontWeight: 600,
                            color: Colors.frontendFont1,
                          }}
                          to={"/privacy-policy"}
                        >
                          Privacy policy
                        </NavLink>
                      </span>
                    </label>
                  </div>
                  {errors.agreement && (
                    <div className={`${styles.error_text} mt-2`}>
                      {errors.agreement.message}
                    </div>
                  )}
                </div>

                <div className={`w-100 mt-4 mb-3`}>
                  <button className={styles.button}>
                    {isloading ? <LoaderSpin /> : "Send"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {showModal && (
          <SuccessModal
            show={showModal}
            handleClose={() => setShowModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default ContactForm;
