import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";

import { toast } from "react-toastify";
import LoaderSpin from "../../Commons/LoaderSpin";
import { editClientInfo } from "../../../../store/service/invoiceService";

import TextStyles from "../../../../Constants/TextStyles";

import { Modal } from "react-bootstrap";
import Colors from "../../../../Constants/Colors";
import SelectCountry from "../../Commons/SelectCountry";
import Constant from "../../Commons/Constant";

const EditClientInfo = ({ selectedData, show, handleClose }) => {
  const isLoading = useSelector((state) => state.invoices.isLoading);

  const dispatch = useDispatch();
  const {
    register,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      street:
        selectedData && selectedData.client_id && selectedData.client_id.street,
      suite:
        selectedData && selectedData.client_id && selectedData.client_id.suite,
      city:
        selectedData && selectedData.client_id && selectedData.client_id.city,
      zip_code:
        selectedData &&
        selectedData.client_id &&
        selectedData.client_id.zip_code,
      country:
        selectedData &&
        selectedData.client_id &&
        selectedData.client_id.country &&
        Constant.countryOptions.filter(
          (data) => data.name === selectedData.client_id.country
        )[0],
      state:
        selectedData && selectedData.client_id && selectedData.client_id.state,
      tax_id_label:
        selectedData &&
        selectedData.client_id &&
        selectedData.client_id.tax_id_label,
      tax_id_number:
        selectedData &&
        selectedData.client_id &&
        selectedData.client_id.tax_id_number,
    },
  });

  const formsubmit = (data) => {
    const Obj = {
      invoice_id: selectedData._id,
      client_id: selectedData.client_id._id,
      zip_code: data.zip_code,
      street: data.street,
      suite: data.suite,
      city: data.city,
      country: data.country ? data.country.name : null,
      state: data.state,
      tax_id_label: data.tax_id_label,
      tax_id_number: data.tax_id_number,
    };
    dispatch(editClientInfo(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
        reset();
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight500px"
        fullscreen
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center justify-content-between">
            <TextStyles.FontSize20px>
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.company_name}
            </TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body className="modal-body">
            <TextStyles.FontSize14px className="row">
              <div className="col-12 col-sm-6 mb-2">
                <label htmlFor="street_address" className="mb-2">
                  Street address
                </label>
                <TextStyles.InputRectangle
                  invalid={errors.street}
                  id="street_address"
                  type="text"
                  placeholder="Street address"
                  name="street"
                  {...register("street")}
                />
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <label htmlFor="suite_address" className="mb-2">
                  Suite address
                </label>
                <TextStyles.InputRectangle
                  invalid={errors.suite}
                  id="suite_address"
                  type="text"
                  placeholder="suite address"
                  name="suite"
                  {...register("suite")}
                />
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="row">
              <div className="col-12 col-sm-6 mb-2">
                <label htmlFor="city" className="mb-2">
                  City
                </label>
                <TextStyles.InputRectangle
                  invalid={errors.city}
                  id="city"
                  type="text"
                  placeholder="City"
                  name="city"
                  {...register("city")}
                />
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <label htmlFor="zip_code" className="mb-2">
                  Zip Code
                </label>
                <TextStyles.InputRectangle
                  id="zip_code"
                  type="text"
                  placeholder="Zip Code"
                  name="zip_code"
                  {...register("zip_code")}
                />
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="row">
              <div className="col-12 col-sm-6 mb-2">
                <label htmlFor="state" className="mb-2">
                  State
                </label>
                <TextStyles.InputRectangle
                  invalid={errors.state}
                  id="state"
                  type="text"
                  placeholder="State"
                  name="state"
                  {...register("state")}
                />
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <label htmlFor="country" className="mb-2">
                  Country
                </label>
                <div>
                  <Controller
                    control={control}
                    id="country"
                    name="country"
                    render={({ field: { onChange, value } }) => (
                      <SelectCountry
                        placeholder="Select country"
                        onChange={onChange}
                        defaultValue={value}
                      />
                    )}
                  />
                </div>
              </div>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="row">
              <div className="col-12 col-sm-6 mb-2">
                <label htmlFor="tax_id_label" className="mb-2">
                  Tax ID label
                </label>
                <TextStyles.InputRectangle
                  id="tax_id_label"
                  type="text"
                  placeholder="Tax ID label"
                  name="tax_id_label"
                  {...register("tax_id_label")}
                />
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <label htmlFor="tax_id_number" className="mb-2">
                  Tax ID number
                </label>
                <TextStyles.InputRectangle
                  id="tax_id_number"
                  type="text"
                  placeholder="Tax ID number"
                  name="tax_id_number"
                  {...register("tax_id_number")}
                />
              </div>
            </TextStyles.FontSize14px>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button disabled={isLoading}>
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditClientInfo;
