import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AutoCompleteSelectProject } from "../../Commons/AutoComplete";

import Styled from "./CreateInvoiceStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

const Dashboard = (props) => {
  const {
    selected,
    setSelected,
    setNext,
    selectedExistProject,
    setSelectedExistProject,
    enteredProjectName,
    setEnteredProjectName,
    backBtn,
  } = props;

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  // this code is to select different type of mode to create invoices.
  useEffect(() => {
    setSelectedExistProject(null);
    setEnteredProjectName(null);
  }, [selected]);

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <TextStyles.FontSize27px className="d-flex justify-content-center text-center my-4">
        What do you want to invoice?
      </TextStyles.FontSize27px>

      <div className="d-flex flex-wrap gap-5 justify-content-center align-items-center pb-5">
        <Styled.DashBoardContainer
          onClick={() => setSelected("eProjectInvoice")}
          style={{
            border:
              selected === "eProjectInvoice"
                ? `2px solid ${Colors.primary}`
                : "",
          }}
        >
          <TextStyles.FontSize20px className="mb-2" style={{ fontWeight: 500 }}>
            An existing project
          </TextStyles.FontSize20px>
          <TextStyles.FontSize14px className="mb-3">
            Choose from your list of existing projects to fill in the details
            for your invoice.
          </TextStyles.FontSize14px>
          <AutoCompleteSelectProject
            placeholder="Select project"
            options={
              projectOptionsData &&
              projectOptionsData.map((d) => {
                return {
                  _id: d._id ? d._id : null,
                  title: d && d.project_name ? d.project_name : null,
                  client_id:
                    d && d.client_id && d.client_id._id
                      ? d.client_id._id
                      : null,
                  client_name:
                    d && d.client_id && d.client_id.company_name
                      ? d.client_id.company_name
                      : null,
                };
              })
            }
            onChange={(value) => {
              setSelectedExistProject(value);
            }}
            label="Select project"
            defaultValue={selectedExistProject && selectedExistProject}
          />
        </Styled.DashBoardContainer>
        <Styled.DashBoardContainer
          onClick={() => setSelected("cProjectInvoice")}
          style={{
            border:
              selected === "cProjectInvoice"
                ? `2px solid ${Colors.primary}`
                : "",
          }}
        >
          <TextStyles.FontSize20px className="mb-2" style={{ fontWeight: 500 }}>
            A new project
          </TextStyles.FontSize20px>
          <TextStyles.FontSize14px className="mb-2">
            Create an invoice and start a new project using the client's details
            you have.
          </TextStyles.FontSize14px>
          <TextStyles.InputRectangle
            type="text"
            placeholder="Project name"
            id="input1"
            name="project_name"
            aria-describedby="emailHelp"
            onChange={(e) => {
              let name = e.target.value.trim();
              if (name) {
                setEnteredProjectName(e.target.value);
              }
            }}
          />
          <TextStyles.FontSize14px id="emailHelp">
            required<span style={{ color: Colors.red }}>*</span>
          </TextStyles.FontSize14px>
        </Styled.DashBoardContainer>
        <Styled.DashBoardContainer
          onClick={(e) => {
            setSelected("quickInvoice");
          }}
          style={{
            border:
              selected === "quickInvoice" ? `2px solid ${Colors.primary}` : "",
          }}
        >
          <TextStyles.FontSize20px className="mb-2" style={{ fontWeight: 500 }}>
            Just a quick invoice
          </TextStyles.FontSize20px>
          <TextStyles.FontSize14px>
            Create an invoice from scratch without creating a project to track
            time or income.
          </TextStyles.FontSize14px>
        </Styled.DashBoardContainer>
      </div>
      {backBtn}
      <Styled.SubmitButton
        disabled={
          (selected === "eProjectInvoice" &&
            selectedExistProject !== null &&
            selectedExistProject !== "Select Project") ||
          (selected === "cProjectInvoice" &&
            enteredProjectName !== null &&
            enteredProjectName.length > 0) ||
          selected === "quickInvoice"
            ? false
            : true
        }
        onClick={() => {
          setNext(true);
        }}
      >
        Next
      </Styled.SubmitButton>
    </div>
  );
};

export default Dashboard;
