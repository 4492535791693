import React, { useState, useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteTask,
  // fetchTasks,
  updateTaskStatus,
  editTaskDueDate,
  createQuickTask,
} from "../../../../../store/service/dashboardService";

import { toast } from "react-toastify";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import NoTaskImage from "../../../../../Assets/assetsnew/no-task.svg";

import EditTask from "../../../dashboard/fourthrow/EditTask";

import DeleteModal from "../../../Commons/DeleteModal";

import { ReactTooltip } from "../../../Commons/ReactTooltip";
import Pagination from "../../../Commons/Pagination";

import Styled from "./TodoStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import {
  createActivityList,
  fetchAllActivityLists,
  start_timer_activity,
} from "../../../../../store/service/timeTrackingService";
import { timeTrackingActions } from "../../../../../store/storage/timetrackingSlice";
import LoaderSpin from "../../../Commons/LoaderSpin";

import { postRequest } from "../../../../../axios/axios";
import URL from "../../../../../axios/constant";

const Todo = ({ showInput, setShowInput }) => {
  const dispatch = useDispatch();
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  const [taskData, setTaskData] = useState(null);
  const [taskTotalCount, setTaskTotalCount] = useState(null);

  const c_data_position = useSelector((state) => state.clients.c_data_position);
  const isLoading = useSelector((state) => state.dashboard.isLoading);
  const isTTLoading = useSelector((state) => state.timeTracking.isLoading);

  // const taskData = useSelector((state) => state.dashboard.taskData);
  // const taskTotalCount = useSelector((state) => state.dashboard.taskTotalCount);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const defaultData = useMemo(() => {
    return (
      taskData &&
      taskData.map((item) => {
        const repeat_task_id = item.repeat_task_id;
        const _id = item._id;
        const due_date = item.due_date ? new Date(item.due_date) : null;
        const hide_until_date = item.hide_until_date
          ? new Date(item.hide_until_date)
          : null;
        const client_id = item.client_id ? item.client_id : null;
        const project_id = item.project_id ? item.project_id : null;
        const status = item.status;
        const task_name = item.task_name ? item.task_name : null;
        const repeat_task = item.repeat_task ? item.repeat_task : null;

        return {
          _id,
          due_date,
          hide_until_date,
          client_id,
          project_id,
          status,
          task_name,
          repeat_task,
          repeat_task_id,
        };
      })
    );
  }, [taskData]);

  const { control: control2, reset: reset2 } = useForm({
    defaultValues: {
      update_task: defaultData && defaultData,
    },
  });

  const { fields: fields2 } = useFieldArray({
    control: control2,
    name: "update_task",
  });

  useEffect(() => {
    reset2({
      update_task: defaultData && defaultData,
    });
  }, [taskData]);

  const RemoveInputHandler = (e) => {
    if (e.keyCode === 8 && e.target.value === "") {
      setShowInput(false);
    }
  };

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const [updateRefresh, setUpdateRefresh] = useState(false);

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 2;

  useEffect(() => {
    setPage(1);
  }, [itemsPerPage]);

  useEffect(() => {
    if (taskData && taskData.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [taskData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  const fetchTaskHandler = async (data) => {
    const token = localStorage.getItem("token");
    setIsTaskLoading(true);
    postRequest(URL.FETCH_TASKS.url, token, data)
      .then((data) => {
        if (data.data.success) {
          setIsTaskLoading(false);
          setTaskData(data.data.data.data);
          setTaskTotalCount(data.data.data.taskTotalCount);
        }
      })
      .catch((err) => {
        setIsTaskLoading(false);
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>
          );
        }
        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>
          );
        }
      });
  };

  useEffect(() => {
    const Obj = {
      client_id: c_data_position._id,
      status: "todo",
      page: page,
      perPage: itemsPerPage,
    };
    fetchTaskHandler(Obj);
    // dispatch(fetchTasks(Obj));
  }, [updateRefresh, itemsPerPage, page]);

  const formsubmit = (data) => {
    const Obj = {
      assign_to_client_id: c_data_position._id,
      task_name: data.task_name,
    };
    dispatch(createQuickTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setTaskData([data.payload.data, ...taskData]);
        setUpdateRefresh(!updateRefresh);
        // setShowInput(false);
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const doneTaskCallApi = (data) => {
    let Obj = {
      task_id: data._id,
      status: "done",
    };
    dispatch(updateTaskStatus(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            Task completed successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const setDueDateApiCall = (newDate, data) => {
    const Obj = {
      _id: data._id,
      due_date: new Date(newDate),
    };
    dispatch(editTaskDueDate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const deleteTaskHandler = (data) => {
    const Obj = {
      task_id: data && data._id,
    };
    dispatch(deleteTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleCloseDeleteModal();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const startTimerHandler = (data) => {
    const Obj = {
      client_id: c_data_position._id,
      activity_name: data.task_name,
      start_time: new Date(),
    };

    dispatch(
      timeTrackingActions.addNewActivityList({ activity_name: data.task_name })
    );
    dispatch(timeTrackingActions.startTimer({ project_id: null, ...Obj }));
    dispatch(createActivityList({ activity_name: data.task_name })).then(
      (data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists());
        }
      }
    );
    dispatch(start_timer_activity(Obj));
  };

  return (
    <div>
      {showInput && (
        <Styled.TodoRectangle
          style={{
            cursor: "auto",
            borderRadius:
              showInput && taskData && taskData.length > 0
                ? "12px 12px 0 0"
                : showInput && taskData && taskData.length === 0
                ? "12px"
                : "",
          }}
        >
          <form
            className="d-flex justify-content-start align-items-center w-100"
            onSubmit={handleSubmit(formsubmit)}
          >
            <RadioButtonUncheckedIcon
              className="radioButton"
              fontSize="medium"
              sx={{
                color: Colors.font2,
                cursor: "pointer",
              }}
            />
            <Styled.Input
              id="addTask"
              invalid={errors.title}
              type="text"
              style={{ background: "none" }}
              placeholder="Write a to-do and hit enter..."
              name="task_name"
              {...register("task_name", {
                required: "Task name is required.",
              })}
              onKeyDownCapture={(event) => {
                if (!isLoading) {
                  RemoveInputHandler(event);
                }
              }}
            />
          </form>
        </Styled.TodoRectangle>
      )}

      {fields2 && fields2.length > 0 ? (
        fields2.map((data, index) => {
          return (
            <Styled.TodoRectangle
              key={index}
              style={{
                marginTop: 2,
                borderRadius:
                  taskData && taskData.length === 1 && !showInput
                    ? "12px"
                    : taskData && taskData.length - 1 === index
                    ? "0 0 12px 12px"
                    : taskData &&
                      taskData.length - taskData.length === index &&
                      !showInput
                    ? "12px 12px 0  0"
                    : "",
              }}
              onClick={() => {
                setShow(true);
                setSelectedTaskData(data);
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <div
                  className="d-flex justify-content-between align-items-center three-dots w-100"
                  style={{ maxWidth: 900 }}
                >
                  <TextStyles.FontSize14px className="d-flex align-items-center three-dots w-100">
                    <div
                      className="d-flex align-items-center radioButtonWrapper"
                      style={{ width: 30 }}
                    >
                      <RadioButtonUncheckedIcon
                        className="radioButton"
                        fontSize="medium"
                        sx={{
                          color: Colors.font2,
                          cursor: "pointer",
                        }}
                      />
                      <TaskAltIcon
                        className="radioCheckedButton"
                        fontSize="medium"
                        sx={{
                          color: Colors.font2,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!isLoading) {
                            doneTaskCallApi(data);
                          }
                        }}
                      />
                    </div>
                    <div
                      className="d-flex flex-column w-100 ms-2"
                      style={{ minWidth: 50 }}
                    >
                      <TextStyles.FontSize14px className="text-start three-dots w-75">
                        {data.task_name}
                      </TextStyles.FontSize14px>
                      <TextStyles.GreyFont14px className="text-start three-dots w-100">
                        {data.project_id && data.project_id.project_name}
                      </TextStyles.GreyFont14px>
                    </div>
                  </TextStyles.FontSize14px>

                  <div className="d-flex align-items-center justify-content-end w-100">
                    <TextStyles.GreyFont14px
                      className="me-3 three-dots"
                      style={{ minWidth: 50 }}
                    >
                      {data.client_id && data.client_id.company_name}
                    </TextStyles.GreyFont14px>

                    <TextStyles.FontSize14px
                      style={{ maxWidth: 90 }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Controller
                        control={control2}
                        name={`update_task.${index}.due_date`}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className="widthofdatepicker three-dots"
                            onChange={(value) => {
                              onChange(value);
                              setDueDateApiCall(value, data);
                            }}
                            selected={value}
                            placeholderText="Set due date"
                            todayButton="Today"
                            dateFormat="dd MMM yyyy"
                            disabled={isLoading ? true : false}
                            // minDate={new Date()}
                          />
                        )}
                      />
                    </TextStyles.FontSize14px>
                  </div>
                </div>

                <div
                  className="w-100 d-none d-sm-flex"
                  style={{
                    maxWidth: 150,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div
                      id={`dashboard-startTimer${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isTTLoading) {
                          startTimerHandler(data);
                        }
                      }}
                    >
                      <PlayCircleIcon
                        sx={{
                          color: Colors.primary,
                          fontSize: 24,
                          background: Colors.white,
                          cursor: "pointer",
                          padding: "2px 6px",
                          borderRadius: "6px",
                          width: 35,
                          height: 30,
                        }}
                      />
                    </div>

                    <ReactTooltip
                      anchorId={`dashboard-startTimer${index}`}
                      content="Start timer"
                    />
                    <div
                      id={`dashboard-doneTask${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isLoading) {
                          doneTaskCallApi(data);
                        }
                      }}
                    >
                      <CheckCircleOutlineIcon
                        sx={{
                          fontSize: 24,
                          color: Colors.font2,
                          background: Colors.white,
                          cursor: "pointer",
                          padding: "2px 6px",
                          borderRadius: "6px",
                          width: 35,
                          height: 30,
                        }}
                      />
                    </div>

                    <ReactTooltip
                      anchorId={`dashboard-doneTask${index}`}
                      content="Done task"
                    />
                    <div
                      id={`dashboard-editTask${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShow(true);
                        setSelectedTaskData(data);
                      }}
                    >
                      <BorderColorOutlinedIcon
                        sx={{
                          color: Colors.success,
                          cursor: "pointer",
                          fontSize: 30,
                          background: Colors.white,
                          padding: "2px 6px",
                          borderRadius: "6px",
                          width: 35,
                          height: 30,
                        }}
                      />
                    </div>

                    <ReactTooltip
                      anchorId={`dashboard-editTask${index}`}
                      content="Edit task"
                    />
                    <div
                      id={`dashboard-deteteTask${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteModal(true);
                        setConfirmDialog({
                          onConfirm: () => deleteTaskHandler(data),
                        });
                      }}
                    >
                      <DeleteForeverRoundedIcon
                        sx={{
                          color: Colors.red,
                          cursor: "pointer",
                          fontSize: 30,
                          background: Colors.white,
                          padding: "2px 2px",
                          borderRadius: "6px",
                          width: 35,
                          height: 30,
                        }}
                      />
                    </div>

                    <ReactTooltip
                      anchorId={`dashboard-deteteTask${index}`}
                      content="Delete task"
                    />
                  </div>
                </div>
                <div
                  className="w-100 d-flex d-sm-none"
                  style={{
                    maxWidth: 50,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div
                    className="dropdown"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <button
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{
                          background: Colors.white,
                          cursor: "pointer",
                          borderRadius: "6px",
                          padding: "2px 4px",
                          height: "30px",
                          color: "grey",
                        }}
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <button className="dropdown-item">
                          <TextStyles.FontSize14px
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!isTTLoading) {
                                startTimerHandler(data);
                              }
                            }}
                          >
                            Start timer
                          </TextStyles.FontSize14px>
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item">
                          <TextStyles.FontSize14px
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!isLoading) {
                                doneTaskCallApi(data);
                              }
                            }}
                          >
                            Done task
                          </TextStyles.FontSize14px>
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item">
                          <TextStyles.FontSize14px
                            onClick={(e) => {
                              e.stopPropagation();
                              setShow(true);
                              setSelectedTaskData(data);
                            }}
                          >
                            Edit task
                          </TextStyles.FontSize14px>
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item">
                          <TextStyles.FontSize14px
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowDeleteModal(true);
                              setConfirmDialog({
                                onConfirm: () => deleteTaskHandler(data),
                              });
                            }}
                          >
                            Delete task
                          </TextStyles.FontSize14px>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Styled.TodoRectangle>
          );
        })
      ) : isTaskLoading && taskData === null ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isTaskLoading &&
        !showInput && (
          <Styled.ListBox>
            <img src={NoTaskImage} alt="no-task-img" />
            <TextStyles.GreyFont18px className="mt-2">
              There are no tasks created yet.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}

      <div className="d-flex justify-content-center pt-4">
        {taskTotalCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(taskTotalCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>

      {show && (
        <EditTask
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
          selectedTaskData={selectedTaskData}
          handleClose={handleClose}
          show={show}
        />
      )}

      <DeleteModal
        title="task"
        confirmDialog={confirmDialog}
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
    </div>
  );
};

export default Todo;
