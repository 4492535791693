import styled from "styled-components";
import Colors from "../../../Constants/Colors";

const SliderBox = styled.div`
  width: 27.94rem;
  border-radius: 0.75rem;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
`;

const SelectedItem = styled.div`
  height: 3.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  cursor: pointer;
  font-family: Poppins;
  font-size: 0.875rem;
  background-color: ${Colors.primary};
  color: ${Colors.white};

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const NotSelectedItem = styled(SelectedItem)`
  color: ${Colors.font1} !important;
  background-color: ${Colors.white} !important;

  &:hover {
    background-color: ${Colors.backgroundPink} !important;
    color: ${Colors.primary};
  }
`;

const EditIncomesBox = styled.div`
  width: 100%;
  max-width: 740px;
  height: auto;
  min-height: 100vh;
  padding: 1.25rem 3rem;
  border-radius: 6px;
  background-color: ${Colors.white};
  border-top: 4px solid ${Colors.primary};
  box-shadow: 0px 0px 2px 0px lightgrey;

  @media (max-width: 576px) {
    padding: 1.25rem 1rem;
  }
`;

const StatementContainer = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  gap: 3rem;
  margin-bottom: 0.7rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

export default {
  SliderBox,
  SelectedItem,
  NotSelectedItem,
  EditIncomesBox,
  StatementContainer,
};
