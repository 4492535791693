import React, { useState, useEffect, useRef } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import DisabledByDefaultSharpIcon from "@mui/icons-material/DisabledByDefaultSharp";
import ClearIcon from "@mui/icons-material/Clear";
import receiptImage from "../../../../Assets/assetsnew/receipt.svg";
import { useForm, Controller } from "react-hook-form";
import {
  AutoCompleteSelectClient,
  AutoCompleteSelectProject,
} from "../../Commons/AutoComplete";
import LoaderSpin from "../../Commons/LoaderSpin";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { editTransaction } from "../../../../store/service/transactionService";
import Styled from "../TranscationStyles";
import TextStyles from "../../../../Constants/TextStyles";
import { CustomDatePicker } from "../../Commons/CustomDatePicker";
import SelectCurrency from "../../Commons/SelectCurrency";
import Colors from "../../../../Constants/Colors";
import { fetchProjectsForAClient } from "../../../../store/service/projectService";
import { fetchClientForAProject } from "../../../../store/service/clientService";

const EditExpense = ({
  show,
  handleClose,
  selectedExpenseData,
  setUpdateRefresh,
  updateRefresh,
}) => {
  const {
    _id,
    project_id,
    client_id,
    file,
    file_url,
    total_money,
    currency,
    paid_date,
    purpose,
    note,
  } = selectedExpenseData;

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.transaction.isLoading);
  const clientForAProject = useSelector(
    (state) => state.clients.clientForAProject
  );

  const projectsForAClient = useSelector(
    (state) => state.project.projectsForAClient
  );
  const isClientLoading = useSelector((state) => state.clients.isLoading);
  const isProjectLoading = useSelector((state) => state.project.isLoading);
  const fileRef = useRef(null);

  const [defaultFile, setDefaultFile] = useState(
    file && file_url
      ? [
          {
            uri: file && file_url,
          },
        ]
      : []
  );

  const [selectedDocs, setSelectedDocs] = useState([]);
  const [fileErrors, setfileErrors] = useState(false);
  const {
    register,
    reset,
    formState: { errors },
    control,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      project: project_id && {
        title:
          project_id && project_id.project_name
            ? project_id.project_name
            : null,
        _id: project_id && project_id._id ? project_id._id : null,
        client_id: client_id && client_id._id ? client_id._id : null,
        client_name:
          client_id && client_id.company_name ? client_id.company_name : null,
      },
      client: client_id && {
        label: client_id && client_id.company_name,
        _id: client_id && client_id._id,
      },
      currency: currency && currency,
      total_rate: total_money && total_money,
      paid_date: paid_date && new Date(paid_date),
      purpose: purpose && purpose,
      note: note && note,
    },
  });

  const changedCurrency = watch("currency");
  const currencySymbol = changedCurrency
    ? changedCurrency.symbol
    : currency.symbol;

  const selectProject = watch("project");
  const selectClient = watch("client");

  useEffect(() => {
    dispatch(
      fetchClientForAProject({
        project_id: selectProject && selectProject._id,
      })
    );
    dispatch(
      fetchProjectsForAClient({
        client_id: selectClient && selectClient._id,
      })
    );
  }, [selectProject, selectClient]);

  const formsubmit = (data) => {
    let formData = new FormData();
    formData.append("transaction_id", _id);
    formData.append("file_url", selectedDocs[0]);
    formData.append("file_delete", defaultFile.length > 0 ? false : true);
    formData.append("project_id", data.project && data.project._id);
    formData.append("client_id", data.client && data.client._id);
    formData.append("total_money", data.total_rate);
    formData.append(
      "currency",
      data.currency ? JSON.stringify(data.currency) : JSON.stringify(currency)
    );
    formData.append(
      "paid_date",
      data.paid_date ? data.paid_date : paid_date && new Date(paid_date)
    );
    formData.append("purpose", data.purpose);
    formData.append("note", data.note);
    formData.append("transaction_type", "expense");
    dispatch(editTransaction(formData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        if (setUpdateRefresh) {
          setUpdateRefresh(!updateRefresh);
        }
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const onChangeFileHandler = (el) => {
    setSelectedDocs(Array.from(el.target.files));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files;
    if (file.length > 1) {
      setfileErrors(true);
      toast.error(
        <TextStyles.FontSize14px>
          Please don't drag multiple files.
        </TextStyles.FontSize14px>
      );
    } else {
      setfileErrors(false);
      setSelectedDocs(Array.from(event.dataTransfer.files));
    }
  };

  const removeFileHandler = () => {
    if (selectedDocs.length > 0) {
      setSelectedDocs([]);
      setfileErrors(false);
    }
    if (file) {
      setDefaultFile([]);
      setfileErrors(false);
    }
    if (fileRef.current) {
      fileRef.current.value = null; // Reset input value to allow re-selection
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      dialogClassName="ToMakeFullscreenModal"
    >
      <div className="modal-header d-flex justify-content-center">
        <TextStyles.FontSize14px
          style={{
            position: "absolute",
            left: "1rem",
            top: "1.25rem",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <ClearIcon
            sx={{
              fontSize: 30,
              color: Colors.font2,
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </TextStyles.FontSize14px>

        <TextStyles.FontSize20px
          style={{ fontWeight: 500, fontSize: "1.5rem" }}
        >
          Edit expense
        </TextStyles.FontSize20px>
      </div>
      <div className="modal-body d-flex justify-content-center">
        <div
          className="d-flex justify-content-center w-100 h-100 py-3"
          style={{ maxWidth: 1400 }}
        >
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-start justify-content-md-between gap-3 gap-md-4 gap-lg-4 gap-xl-5 w-100 h-100">
            <div className="w-100 h-100">
              <Styled.ImageBox
                fileErrors={fileErrors}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{ position: "absolute", right: 12, top: 6 }}
                  onClick={removeFileHandler}
                >
                  <DisabledByDefaultSharpIcon
                    sx={{
                      color: "grey",
                      background: "tranparent",
                      display:
                        selectedDocs.length > 0 || defaultFile.length > 0
                          ? "inline"
                          : "none",
                    }}
                  />
                </div>
                <label
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  htmlFor="uploadfiles"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    ref={fileRef}
                    style={{ display: "none" }}
                    id="uploadfiles"
                    type="file"
                    accept="image/*,.pdf,.doc,.docx,.xls,.xlsx, .csv,"
                    // multiple
                    onChange={onChangeFileHandler}
                  />

                  {selectedDocs.length === 0 && defaultFile.length === 0 && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={receiptImage} alt="reciept" />
                      <TextStyles.FontSize18px className="mt-4">
                        Attach a receipt
                      </TextStyles.FontSize18px>
                      <TextStyles.GreyFont14px className="mt-2">
                        Click to upload a file, or just drag & drop.
                      </TextStyles.GreyFont14px>
                    </div>
                  )}

                  {(selectedDocs.length > 0 || defaultFile.length > 0) && (
                    <DocViewer
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "450px",
                      }}
                      documents={
                        selectedDocs.length > 0
                          ? selectedDocs.map((file) => ({
                              uri: window.URL.createObjectURL(file),
                              fileName: file.name,
                            }))
                          : defaultFile
                      }
                      prefetchMethod="POST"
                      pluginRenderers={DocViewerRenderers}
                      config={{
                        header: {
                          disableHeader: true,
                          disableFileName: true,
                          retainURLParams: true,
                        },
                      }}
                    />
                  )}
                </label>
              </Styled.ImageBox>
            </div>
            <div className="w-100 h-100">
              <form onSubmit={handleSubmit(formsubmit)}>
                <TextStyles.FontSize14px className="mb-3">
                  <TextStyles.FontSize14px className="mb-3">
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                        <label htmlFor="select_client" className="mb-2">
                          Select client
                        </label>
                        <Controller
                          control={control}
                          name="client"
                          rules={{
                            required: {
                              value: true,
                              message: "Client is required.",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <AutoCompleteSelectClient
                              placeholder="Select client"
                              isLoading={isClientLoading}
                              options={
                                clientForAProject &&
                                clientForAProject.map((data) => {
                                  return {
                                    label: data && data.company_name,
                                    _id: data && data._id,
                                  };
                                })
                              }
                              onChange={onChange}
                              defaultValue={value}
                            />
                          )}
                        />
                        {errors.client && (
                          <TextStyles.InValidFeedback className="mt-2">
                            {errors.client.message}
                          </TextStyles.InValidFeedback>
                        )}
                      </div>
                      <div className="col-12 col-lg-6">
                        <TextStyles.FontSize14px className="mb-2">
                          Select project
                        </TextStyles.FontSize14px>
                        <Controller
                          control={control}
                          name="project"
                          render={({ field: { onChange, value } }) => (
                            <AutoCompleteSelectProject
                              placeholder="Select project"
                              isLoading={isProjectLoading}
                              options={
                                projectsForAClient &&
                                projectsForAClient.map((d) => {
                                  return {
                                    _id: d && d._id,
                                    title: d && d.project_name,
                                    client_id:
                                      d && d.client_id && d.client_id._id,
                                    client_name:
                                      d &&
                                      d.client_id &&
                                      d.client_id.company_name,
                                  };
                                })
                              }
                              onChange={onChange}
                              defaultValue={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </TextStyles.FontSize14px>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                      <label htmlFor="total_rate" className="mb-2">
                        Amount
                      </label>
                      <Styled.InputBox>
                        <span>{currencySymbol}</span>
                        <input
                          style={{
                            width: "84%",
                          }}
                          type="text"
                          name="total_rate"
                          id="total_rate"
                          placeholder="00.00"
                          {...register("total_rate", {
                            required: {
                              value: true,
                              message: "Amount is required.",
                            },
                            validate: (amount) =>
                              amount > 0 || "Must be greater than 0.",
                            pattern: {
                              value: /^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                              message: "Please enter a valid number",
                            },
                          })}
                        />
                      </Styled.InputBox>

                      {errors.total_rate && (
                        <TextStyles.InValidFeedback className="mt-2">
                          {errors.total_rate.message}
                        </TextStyles.InValidFeedback>
                      )}
                    </div>
                    <div className="col-12 col-lg-6">
                      <label htmlFor="currency" className="mb-2">
                        Select currency
                      </label>
                      <Controller
                        control={control}
                        name="currency"
                        render={({ field: { onChange, value } }) => (
                          <SelectCurrency
                            placeholder="Select currency"
                            onChange={onChange}
                            defaultValue={value}
                          />
                        )}
                      />
                    </div>
                  </div>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="date" className="mb-2">
                    Date
                  </label>
                  <TextStyles.DateRectangle>
                    <Controller
                      name={"paid_date"}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CustomDatePicker
                            onChange={onChange}
                            selected={value}
                            placeholderText="Set date"
                            todayButton="Today"
                            dateFormat="dd MMM yyyy"
                            isClearable={false}
                          />
                        );
                      }}
                    />
                  </TextStyles.DateRectangle>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-2">
                  <label htmlFor="purpose" className="mb-2">
                    Purpose
                  </label>
                  <TextStyles.InputRectangle
                    type="text"
                    id="purpose"
                    name="purpose"
                    placeholder="Dinner with client"
                    {...register("purpose", {
                      required: {
                        value: true,
                        message: "Purpose is required.",
                      },
                    })}
                  />
                  {errors.purpose && (
                    <TextStyles.InValidFeedback>
                      {errors.purpose.message}
                    </TextStyles.InValidFeedback>
                  )}
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px>
                  <label htmlFor="addNote" className="mb-2">
                    Add note
                  </label>
                  <TextStyles.InputRectangle
                    type="text"
                    id="addNote"
                    name="note"
                    {...register("note")}
                    placeholder="Add a note..."
                  />
                </TextStyles.FontSize14px>

                <div className="row py-3">
                  <div className="col-6">
                    <TextStyles.CancelButton onClick={handleClose}>
                      Cancel
                    </TextStyles.CancelButton>
                  </div>
                  <div className="col-6">
                    <TextStyles.Button
                      disabled={fileErrors.length > 0 || isLoading}
                    >
                      {isLoading ? <LoaderSpin /> : "Save"}
                    </TextStyles.Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditExpense;
