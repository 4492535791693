import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateTerminationRequestPC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

import FeedbackModal from "../../PageModals/FeedbackModal.jsx";

const Feedback = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const heading = "Feedback";

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData && selectedData._id,
      number_of_termination_request: {
        required: false,
        num_of_termination_request_require: false,
        num_request: "0",
      },
    };
    dispatch(updateTerminationRequestPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.number_of_termination_request &&
          selectedData.number_of_termination_request.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        {selectedData &&
        selectedData.number_of_termination_request &&
        selectedData.number_of_termination_request
          .num_of_termination_request_require === true ? (
          <div>
            <Styled.EditButtonToolTip
              className="contract_review_edit_tp"
              style={{ marginLeft: -60 }}
              onClick={() => setShowModal(true)}
            >
              <BorderColorOutlinedIcon
                sx={{ color: Colors.white, fontSize: 20 }}
              />
              <span className="ms-1">Edit</span>
            </Styled.EditButtonToolTip>
            <Styled.EditButtonToolTip
              backgroundColor="#383838"
              className="contract_review_edit_tp"
              style={{ marginLeft: 60 }}
              onClick={() => {
                setShowRemoveModal(true);
                setConfirmDialog({
                  onConfirm: hideClauseHandler,
                });
              }}
            >
              <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
              <span className="ms-1">Hide</span>
            </Styled.EditButtonToolTip>
          </div>
        ) : (
          <Styled.EditButtonToolTip
            className="contract_review_edit_tp"
            backgroundColor={Colors.white}
            color={Colors.font1}
            onClick={() => setShowModal(true)}
          >
            show
          </Styled.EditButtonToolTip>
        )}

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>
          {selectedData &&
          selectedData.number_of_termination_request &&
          selectedData.number_of_termination_request
            .num_of_termination_request_require === true &&
          selectedData.number_of_termination_request.num_request ? (
            <div>
              <div>
                Client will have the opportunity to provide feedback up to
                <span
                  className="mx-1"
                  style={{ color: Colors.primary, fontWeight: 500 }}
                >
                  {selectedData.number_of_termination_request.num_request}
                </span>{" "}
                times and Contractor will make revisions up to
                <span
                  className="mx-1"
                  style={{ color: Colors.primary, fontWeight: 500 }}
                >
                  {selectedData.number_of_termination_request.num_request}
                </span>{" "}
                times based on the provided feedback.
              </div>
              <div>
                <div className="mt-3">
                  a.Clients as well as contractors must establish a process for
                  providing feedback, such as scheduled review meetings or
                  periodic feedback sessions.
                </div>
                <div className="mt-3">
                  b. Both the parties define the preferred method of feedback
                  communication, whether it's in person, email, or through the
                  project management system.
                </div>
                <div className="mt-3">
                  c. Client is expected to provide feedback on deliverables or
                  milestones within a specific time frame.
                </div>
              </div>
            </div>
          ) : (
            "Note: This section shows if only applicable."
          )}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {/* modal section below */}
      {showModal && (
        <FeedbackModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          selectedData={selectedData}
        />
      )}

      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default Feedback;
