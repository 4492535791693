import React from "react";
import { useDispatch } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import TextStyles from "../../../../../Constants/TextStyles";
import { createInvoice } from "../../../../../store/service/invoiceService";
import Styled from "./InvoiceSetupStyles";
import { toast } from "react-toastify";
import Colors from "../../../../../Constants/Colors";

const InvoiceSetup = (props) => {
  const { selectedProject, reminder_id, page, setPage, backBtn, submitBtn } =
    props;
  const dispatch = useDispatch();
  const { register, control, handleSubmit, watch, getValues } = useForm({
    defaultValues: {
      serviceLists:
        selectedProject.service_list && selectedProject.service_list,
    },
  });

  const { fields } = useFieldArray({
    name: "serviceLists",
    control,
  });

  const onSubmit = async (data) => {
    const Obj = {
      client_id:
        selectedProject &&
        selectedProject.client_id &&
        selectedProject.client_id._id,
      currency: selectedProject && selectedProject.currency,
      project_id: selectedProject && selectedProject._id,
      invoice_services: data.serviceLists
        .filter((d) => d.checkbox === true)
        .map((d) => {
          return {
            service_name: d.service_name,
            service_rate: d.service_rate,
            service_rate_type: d.service_rate_type,
            service_rate_count: d.num_quantity ? parseFloat(d.num_quantity) : 1,
            desc: d.desc,
          };
        }),
      reminder_id: reminder_id,
    };

    dispatch(createInvoice(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const allUnchecked = () => {
    return getValues()
      .serviceLists.map((item) => item.checkbox && item.checkbox)
      .every((item) => item === false);
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <TextStyles.FontSize27px className="text-center mb-3 mb-md-4 mb-lg-5">
        What do you want to invoice?
      </TextStyles.FontSize27px>

      <div className="d-flex justify-content-center align-items-center w-100">
        <div className="w-100" style={{ maxWidth: 650 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {fields.map((item, index) => {
                const fieldName = `serviceLists[${index}]`;
                const ratecount = watch(`${fieldName}.num_quantity`);
                let checkbox = watch(`${fieldName}.checkbox`);
                if (checkbox === undefined) checkbox = true;
                let total = 0;
                if (ratecount === undefined) {
                  total += item.service_rate * 1;
                } else {
                  total += item.service_rate * ratecount;
                }
                return (
                  <div key={index}>
                    <Styled.SetupBox className="mb-4">
                      <div className="d-flex justify-content-between align-items-center three-dots w-100">
                        <TextStyles.CheckBox
                          className="form-check d-flex align-items-center three-dots"
                          style={{ minWidth: 100 }}
                        >
                          <input
                            style={{ cursor: "pointer" }}
                            className="form-check-input"
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            defaultChecked={true}
                            {...register(`${fieldName}.checkbox`)}
                          />
                          <TextStyles.FontSize14px
                            className="form-check-label three-dots"
                            htmlFor="flexCheckChecked"
                            style={{
                              color: checkbox === false ? "grey" : "",
                            }}
                          >
                            {item.service_name}
                          </TextStyles.FontSize14px>
                        </TextStyles.CheckBox>
                        <TextStyles.GreyFont20px
                          className="d-flex"
                          style={{ minWidth: 100 }}
                        >
                          <span className="three-dots">
                            {selectedProject &&
                              selectedProject.currency &&
                              selectedProject.currency.symbol}
                            {item.service_rate
                              ? parseFloat(item.service_rate).toFixed(2)
                              : "00.00"}
                            {item.service_rate_type === "Flat fee" && ""}
                            {item.service_rate_type === "Per hour" && "/hour"}
                            {item.service_rate_type === "Per day" && "/day"}
                            {item.service_rate_type === "Per item" && "/item"}
                            {item.service_rate_type === "Per word" && "/word"}
                          </span>
                        </TextStyles.GreyFont20px>
                      </div>

                      {checkbox === true && (
                        <>
                          <div className="mb-2 mt-3">
                            <TextStyles.FontSize14px
                              style={{ color: "rgb(106 106 106)" }}
                            >
                              Quantity
                            </TextStyles.FontSize14px>
                          </div>
                          <div className="d-flex justify-content-start align-items-center">
                            <Styled.inputBox style={{ maxWidth: 120 }}>
                              <input
                                readOnly={item.service_rate_type === "Flat fee"}
                                style={{ width: 80 }}
                                type="number"
                                id="service_rate"
                                min={"0"}
                                step="0.01"
                                defaultValue={1}
                                name="num_quantity"
                                placeholder="00.00"
                                {...register(`${fieldName}.num_quantity`)}
                              />
                            </Styled.inputBox>
                            <TextStyles.FontSize14px
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              {item.service_rate_type === "Flat fee" && (
                                <TextStyles.FontSize14px>
                                  flat fee
                                </TextStyles.FontSize14px>
                              )}
                              {item.service_rate_type === "Per hour" && (
                                <TextStyles.FontSize14px>
                                  hour/s
                                </TextStyles.FontSize14px>
                              )}
                              {item.service_rate_type === "Per day" && (
                                <TextStyles.FontSize14px>
                                  day/s
                                </TextStyles.FontSize14px>
                              )}
                              {item.service_rate_type === "Per item" && (
                                <TextStyles.FontSize14px>
                                  item/s
                                </TextStyles.FontSize14px>
                              )}
                              {item.service_rate_type === "Per word" && (
                                <TextStyles.FontSize14px>
                                  word/s
                                </TextStyles.FontSize14px>
                              )}
                            </TextStyles.FontSize14px>
                          </div>
                          <TextStyles.FontSize20px className="d-flex justify-content-between align-items-center three-dots w-100 mt-3">
                            <div
                              className="three-dots"
                              style={{ minWidth: 100 }}
                            >
                              Total service charges
                            </div>
                            <div
                              className="three-dots"
                              style={{ color: Colors.primary, minWidth: 50 }}
                            >
                              {selectedProject &&
                                selectedProject.currency &&
                                selectedProject.currency.symbol}
                              {total > 0
                                ? parseFloat(total).toFixed(2)
                                : "00.00"}
                            </div>
                          </TextStyles.FontSize20px>
                        </>
                      )}
                    </Styled.SetupBox>
                  </div>
                );
              })}
            </div>
            {!allUnchecked() && submitBtn}
          </form>
        </div>
        {backBtn}
      </div>
    </div>
  );
};

export default InvoiceSetup;
