import React from "react";
import TimerBox from "./TimerBox";
import TimerList from "./TimerList";

const Timer = () => {
  return (
    <div>
      <TimerBox />
      <TimerList />
    </div>
  );
};

export default Timer;
