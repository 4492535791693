import React, { useEffect } from "react";

import HomePage from "./Components/HomePage/HomePage.jsx";
import TaskManagements from "./Components/Features/TaskManagements/TaskManagements.jsx";
import Invoicing from "./Components/Features/Invoices/Invoicing.jsx";
import Agreements from "./Components/Features/Agreements/Agreements.jsx";
import Transactions from "./Components/Features/Transactions/Transactions.jsx";
import Statements from "./Components/Features/Statements/Statements.jsx";
import TimeTracking from "./Components/Features/TimeTracking/TimeTracking.jsx";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageNotFound from "./Components/path404error/patherror.jsx";
import Layout from "./HOC/Layout/Layout";
import Reviews from "./Components/Reviews/Reviews.jsx";
import WhyZodot from "./Components/WhyZodot/WhyZodot.jsx";
import TermsOfService from "./Components/TermsOfService/TermsOfservice.jsx";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy.jsx";
import ContactUs from "./Components/ContactUs/ContactUs.jsx";

import ScrollTop from "./HOC/ScrollTop/ScrollTop";
import "react-dev-utils/webpackHotDevClient";

import DashboardDrawer from "./Components/NewDashboard/DashboardDrawer";
import { ToastContainer } from "react-toastify";
import Login from "./Components/auth/Login";
import Signup from "./Components/auth/SignUp";
import EmailVerify from "./Components/auth/EmailVerify";
import ForgotPassword from "./Components/auth/ForgotPassword.jsx";
import resetPassword from "./Components/auth/ChangePassword.js";
import SuccessSignup from "./Components/auth/SuccessSignup.jsx";

import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./Components/auth/ProtectedRoute";
import CreateAgreement from "./Components/NewDashboard/Proposals/CreateAgreements/CreateAgreements";
import EditAgreements from "./Components/NewDashboard/Proposals/EditAgreements/EditAgreement";

import CreateInvoices from "./Components/NewDashboard/invoices/CreateInvoices/CreateInvoices";
import EditInvoices from "./Components/NewDashboard/invoices/EditInvoices/EditInvoices";

// project related invoices
import CreateInvoicesInProject from "./Components/NewDashboard/projects/editProject/Invoices/CreateInvoices/CreateInvoices";
import EditInvoicesInProject from "./Components/NewDashboard/projects/editProject/Invoices/EditInvoices/EditInvoices";

// client realated invoices
import CreateInvoicesInClient from "./Components/NewDashboard/clients/editClient/Invoices/CreateInvoices/CreateInvoices.jsx";
import EditInvoicesInClient from "./Components/NewDashboard/clients/editClient/Invoices/EditInvoices/EditInvoices";
import LaunchToSetting from "./Components/NewDashboard/DB/LaunchToSetting";
import "./index.css";
import PublicViewInvoice from "./Components/NewDashboard/invoices/PublicViewInvoice/PublicViewInvoice.jsx";
import PublicViewAgreements from "./Components/NewDashboard/Proposals/PublicViewAgreements/ViewAgreements.jsx";
import ViewAgreements from "./Components/NewDashboard/Proposals/ViewAgreements/ViewAgreements.jsx";
import LaunchToTimers from "./Components/NewDashboard/DB/LaunchToTimers";
import LaunchToStatements from "./Components/NewDashboard/DB/LaunchToStatements.jsx";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  window.addEventListener("storage", (event) => {
    if (event.key === "logout") {
      if (window.location.href.split("/")[3] === "db") {
        window.location.href = "/";
      }
    }
  });
  window.addEventListener("storage", (event) => {
    if (event.key === "token") {
      window.location.reload();
    }
  });

  useEffect(() => {
    AOS.init({
      delay: 20,
      duration: 500,
      easing: "linear",
      offset: 0, // Animation duration in milliseconds
    });

    return () => {
      AOS.refresh(); // Optionally refresh AOS on component unmount
    };
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer autoClose={3000} />
      <Switch>
        <Layout>
          <ScrollTop />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/features/invoicing" exact component={Invoicing} />
            <Route path="/features/agreements" exact component={Agreements} />
            <Route
              path="/features/transactions"
              exact
              component={Transactions}
            />
            <Route
              path="/features/time-tracking"
              exact
              component={TimeTracking}
            />
            <Route
              path="/features/task-management"
              exact
              component={TaskManagements}
            />
            <Route path="/features/statements" exact component={Statements} />
            <Route path="/reviews" exact component={Reviews} />
            <Route path="/why-zodot" exact component={WhyZodot} />

            <Route path="/terms-of-service" exact component={TermsOfService} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/contact" exact component={ContactUs} />

            <Route path="/db/signup" exact component={Signup} />

            <Route path="/db/signup-success" exact component={SuccessSignup} />

            <Route path="/db/login" exact component={Login} />

            <Route path="/db/forgot" exact component={ForgotPassword} />
            <Route
              path="/db/verify/:token/:email"
              exact
              component={EmailVerify}
            />

            <Route
              path="/db/reset-password/:resetPasswordToken"
              exact
              component={resetPassword}
            />
            <ProtectedRoute path="/db/home" exact component={DashboardDrawer} />
            <ProtectedRoute
              path="/db/clients"
              exact
              component={DashboardDrawer}
            />

            <ProtectedRoute
              path="/db/clients/:id/tasks"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/address"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/projects"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/invoices"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/transactions"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/notes"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/clients/:id/invoices/new"
              component={CreateInvoicesInClient}
            />
            <ProtectedRoute
              path="/db/clients/:id/invoices/edit"
              component={EditInvoicesInClient}
            />

            <ProtectedRoute
              path="/db/projects"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/terms"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/tasks"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/timers"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/weekviews"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/reports"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/invoices"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/transactions"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/notes"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/projects/:id/invoices/new"
              component={CreateInvoicesInProject}
            />
            <ProtectedRoute
              path="/db/projects/:id/invoices/edit"
              component={EditInvoicesInProject}
            />

            <ProtectedRoute
              path="/db/transactions"
              exact
              component={DashboardDrawer}
            />

            <ProtectedRoute
              path="/db/timetracking"
              exact
              component={LaunchToTimers}
            />
            <ProtectedRoute
              path="/db/timetracking/timers"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/timetracking/weekview"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/timetracking/reports"
              exact
              component={DashboardDrawer}
            />

            <ProtectedRoute
              path="/db/agreements"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/agreements/new"
              exact
              component={CreateAgreement}
            />
            <ProtectedRoute
              path="/db/agreements/edit"
              exact
              component={EditAgreements}
            />

            <ProtectedRoute
              path="/db/agreements/view/:id"
              exact
              component={ViewAgreements}
            />

            <Route path="/db/setting" exact component={LaunchToSetting} />
            <ProtectedRoute
              path="/db/setting/business"
              exact
              component={DashboardDrawer}
            />

            <ProtectedRoute
              path="/db/setting/business/business_info"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/setting/business/currency"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/setting/business/brand_logo"
              exact
              component={DashboardDrawer}
            />

            <ProtectedRoute
              path="/db/setting/templates"
              exact
              component={DashboardDrawer}
            />

            <ProtectedRoute
              path="/db/setting/account"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/setting/account/user_info"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/invoices"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/invoices/new"
              exact
              component={CreateInvoices}
            />
            <ProtectedRoute
              path="/db/invoices/edit"
              exact
              component={EditInvoices}
            />
            <ProtectedRoute
              path="/db/invoices/view/:id"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/invoices/view/:id/details"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/invoices/view/:id/notes"
              exact
              component={DashboardDrawer}
            />

            <ProtectedRoute
              path="/db/statements"
              exact
              component={LaunchToStatements}
            />

            <ProtectedRoute
              path="/db/statements/incomes"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/statements/expenses"
              exact
              component={DashboardDrawer}
            />
            <ProtectedRoute
              path="/db/statements/taxes"
              exact
              component={DashboardDrawer}
            />

            <Route
              path="/db/invoices/view/public/:id/:token"
              exact
              component={PublicViewInvoice}
            />
            <Route
              path="/db/agreements/view/public/:id/:token"
              exact
              component={PublicViewAgreements}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Layout>
      </Switch>
    </BrowserRouter>
  );
};
export default App;
