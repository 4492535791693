import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import TextStyles from "../../../../../Constants/TextStyles";
import { updateAddNonCompletePC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import SwitchButton from "../../../Commons/SwitchButton";

const NonCompeteModal = ({
  show,
  handleClose,
  heading,
  paragraph,
  selectedData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const [selector, setSelector] = useState(
    selectedData &&
      selectedData.add_non_complete &&
      selectedData.add_non_complete.add_non_complete_clause_require === true
      ? true
      : false
  );
  const addNonCompleteText =
    "Contractor agrees that throughout the duration of providing Services and for [XX] months after the termination of Services or expiration of this Agreement, within a [XX-mile radius of Client's primary business location], Contractor shall refrain from engaging in any activities directly and substantially competitive with Client's current business, which is narrowly defined as [description of Client's primary current business].";

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
      add_non_complete_clause_text:
        selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.add_non_complete_clause_text
          ? selectedData.add_non_complete.add_non_complete_clause_text
          : addNonCompleteText,
    },
  });

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      add_non_complete: {
        required: true,
        heading: item.heading,
        paragraph: item.paragraph,
        add_non_complete_clause_require: selector === true ? true : false,
        add_non_complete_clause_text:
          selector === true ? item.add_non_complete_clause_text : null,
      },
    };
    dispatch(updateAddNonCompletePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            Clause updated successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder={heading}
              style={{ opacity: 0.5 }}
              id="headline"
              name="heading"
              {...register("heading")}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              rows={2}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              style={{ opacity: 0.5 }}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize18px className="mb-3">
            Options
          </TextStyles.FontSize18px>
          <TextStyles.GreyFont14px className="d-flex gap-3 flex-wrap justify-content-between align-items-center">
            <div className="d-flex">Add Non-Compete?</div>
            <div className="d-flex justify-content-end">
              <SwitchButton
                defaultChecked={selector}
                onChange={(event) => setSelector(event.target.checked)}
              />
            </div>
          </TextStyles.GreyFont14px>
          {selector && (
            <>
              <TextStyles.GreyFont14px className="my-2">
                Add custom non-compete clause
              </TextStyles.GreyFont14px>
              <TextStyles.FontSize14px>
                <ErrorOutlineOutlinedIcon
                  sx={{ color: "#ff9900", fontSize: 20 }}
                />
                <span className="ms-2">
                  We strongly advise freelancers against signing non-compete
                  agreements that restrict their ability to pursue or access
                  work opportunities during and after the agreement's term. If a
                  hiring party insists on a non-compete, it is recommended to
                  seek legal counsel from an attorney.
                </span>
              </TextStyles.FontSize14px>
              <TextStyles.FontSize14px className="my-2">
                <TextStyles.Textarea
                  defaultValue={addNonCompleteText}
                  rows={5}
                  placeholder="Add text here..."
                  style={{ padding: 12 }}
                  name="add_non_complete_clause_text"
                  {...register("add_non_complete_clause_text")}
                />
              </TextStyles.FontSize14px>
            </>
          )}
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default NonCompeteModal;
