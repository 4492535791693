import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import NoProjectImage from "../../../../../Assets/assetsnew/no-project.svg";

import { Popover } from "antd";

import Colors from "../../../../../Constants/Colors";
import Styled from "../../../projects/projectStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  // fetchProjects,
  changeProjectStatus,
  fetchProjectForOptions,
} from "../../../../../store/service/projectService";
import { toast } from "react-toastify";
import ArchiveModal from "../../../Commons/ArchiveModal";
import EndModal from "../../../Commons/EndModal";
import { ReactTooltip } from "../../../Commons/ReactTooltip";
import SelectProjects from "../../../Commons/SelectProjects";
import SelectProjectStatus from "../../../Commons/SelectProjectStatus";
import Pagination from "../../../Commons/Pagination";

import CreateProject from "../../../projects/createProject/createProject";
import EditProjectModal from "../../../projects/editProject/editProjectModal/EditProjectModal";
import SelectDate from "../../../Commons/SelectDate";
import LoaderSpin from "../../../Commons/LoaderSpin";
import { postRequest } from "../../../../../axios/axios";
import URL from "../../../../../axios/constant";

export default function projects() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [isProjectLoading, setIsProjectLoading] = useState(false);
  const [projects, setProjects] = useState(null);
  const [prTotalCount, setPrTotalCount] = useState(null);

  // const prTotalCount = useSelector((state) => state.project.prTotalCount);
  // const isLoading = useSelector((state) => state.project.isLoading);
  // const projects = useSelector((state) => state.project.projects);
  const c_data_position = useSelector((state) => state.clients.c_data_position);

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const handleCloseArchiveModal = () => setShowArchiveModal(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const handleCloseEndModal = () => setShowEndModal(false);

  const [selectedProject, setSelectedProject] = useState(null);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);

  // filtered state
  const [updateRefresh, setUpdateRefresh] = useState(false);
  const [filteredProject, setFilteredProject] = useState(null);
  const [filteredProjectStatus, setFilteredProjectStatus] = useState("Running");
  const [filterdDateRange, setFilteredDateRange] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const toggleShowFilter = () => setShowFilter(!showFilter);

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const [selectedSortingOne, setSelectedSortingOne] =
    useState("project_start_date");

  const sortingOptionChangeHandler = (event) => {
    setSelectedSortingOne(event.target.value);
  };

  const [selectedSortingTwo, setSelectedSortingTwo] = useState("desc");

  const sortingOrderingChangeHandler = (event) => {
    setSelectedSortingTwo(event.target.value);
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    setPage(1);
  }, [
    filteredProject,
    filteredProjectStatus,
    filterdDateRange,
    itemsPerPage,
    // updateRefresh,
  ]);

  useEffect(() => {
    if (projects && projects.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [projects]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  const fetchProjectHandler = async (data) => {
    const token = localStorage.getItem("token");
    setIsProjectLoading(true);
    postRequest(URL.FETCH_PROJECT.url, token, data)
      .then((data) => {
        if (data.data.success) {
          setIsProjectLoading(false);
          setProjects(data.data.data.data);
          setPrTotalCount(data.data.data.prTotalCount);
        }
      })
      .catch((err) => {
        setIsProjectLoading(false);
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>
          );
        }
        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>
          );
        }
      });
  };

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).format("YYYY-MM-DD");
      end_time = moment(filterdDateRange[0]).format("YYYY-MM-DD");
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(6, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(14, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(29, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(44, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(59, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(89, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "This month") {
        const startDateOfThisMonth = moment(new Date())
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfThisMonth = moment(new Date())
          .endOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last month") {
        const endDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        const startDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "This year") {
        const endDateOfThisYear = moment(new Date())
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfThisYear = moment(new Date())
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisYear).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last year") {
        const endDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastYear).format("YYYY-MM-DD");
      }
    }

    const Obj = {
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      project_id: filteredProject !== 0 ? filteredProject : null,
      client_id: c_data_position && c_data_position._id,
      status:
        filteredProjectStatus === "All statuses" ? null : filteredProjectStatus,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    fetchProjectHandler(Obj);
    // dispatch(fetchProjects(Obj));
  }, [
    updateRefresh,
    filteredProject,
    filteredProjectStatus,
    filterdDateRange,
    page,
    itemsPerPage,
    selectedSortingOne,
    selectedSortingTwo,
  ]);

  useEffect(() => {
    dispatch(fetchProjectForOptions());
  }, [updateRefresh]);

  const EndProjectHandler = (id, status) => {
    dispatch(changeProjectStatus({ project_id: id, status })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseEndModal();
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            Project ended successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const ArchiveProjectHandler = (id, status) => {
    dispatch(changeProjectStatus({ project_id: id, status })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseArchiveModal();
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            Project archived successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const UnarchiveProjectHandler = (id, status) => {
    dispatch(changeProjectStatus({ project_id: id, status })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            Project unarchived successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <FolderSharedTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label
            className="form-check-label ms-2"
            htmlFor="company_name"
            onClick={() => setSelectedSortingOne("company_name")}
          >
            Client name
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label
            className="form-check-label ms-2"
            htmlFor="project_name"
            onClick={() => setSelectedSortingOne("project_name")}
          >
            Project name
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label
            className="form-check-label ms-2"
            htmlFor="project_start_date"
            onClick={() => setSelectedSortingOne("project_start_date")}
          >
            Start date
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_start_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_start_date"}
              value="project_start_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label
            className="form-check-label ms-2"
            htmlFor="project_end_date"
            onClick={() => setSelectedSortingOne("project_end_date")}
          >
            End date
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_end_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_end_date"}
              value="project_end_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <AdjustOutlinedIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label
            className="form-check-label ms-2"
            htmlFor="status"
            onClick={() => setSelectedSortingOne("status")}
          >
            Status
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="status"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "status"}
              value="status"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <PaidTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label
            className="form-check-label ms-2"
            htmlFor="totalInvoicedAmount"
            onClick={() => setSelectedSortingOne("totalInvoicedAmount")}
          >
            Invoiced amount
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="totalInvoicedAmount"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "totalInvoicedAmount"}
              value="totalInvoicedAmount"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <ArrowUpwardTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label className="form-check-label ms-2" htmlFor="asc">
            Ascending (A-Z)
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <ArrowDownwardTwoToneIcon
            sx={{ color: Colors.font2, fontSize: 21 }}
          />
          <label className="form-check-label ms-2" htmlFor="desc">
            Descending (Z-A)
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const setSortingActiveHandler = (selectedSort) => {
    setSelectedSortingOne(selectedSort);

    // Change arrow
    if (selectedSortingTwo === "desc") setSelectedSortingTwo("asc");
    if (selectedSortingTwo === "asc") setSelectedSortingTwo("desc");
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-3 justify-content-sm-between align-items-center my-4">
        <TextStyles.FontSize20px className="d-flex justify-content-start">
          Projects
        </TextStyles.FontSize20px>

        <div className="d-flex justify-content-end align-items-center ms-auto">
          <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
            Filter
          </TextStyles.HoverEffectSpan>

          <div
            className="client-partion-rectangle mx-2"
            style={{
              width: "2px",
              height: "23px",
              backgroundColor: "#7d849e",
            }}
          />

          <TextStyles.FontSize16px className="me-2">
            <Popover
              placement="bottomRight"
              content={sortingContent}
              trigger="click"
              open={openSorting}
              onOpenChange={handleOpenSortingChange}
            >
              <TextStyles.HoverEffectSpan sort={true}>
                Sort
                <ArrowDropDownOutlinedIcon
                  sx={{
                    color: Colors.font2,
                    fontSize: 27,
                    transform: openSorting ? "rotate(180deg)" : "",
                  }}
                />
              </TextStyles.HoverEffectSpan>
            </Popover>
          </TextStyles.FontSize16px>

          <Styled.Oval onClick={() => setShow(true)}>
            <AddOutlinedIcon sx={{ fontSize: 20, color: Colors.white }} />
          </Styled.Oval>
        </div>
      </div>
      {showFilter && (
        <div className="d-flex flex-wrap justify-content-start mb-3">
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>

            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                setFilteredDateRange(value);
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) => setFilteredProject(e.target.value)}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Status
            </TextStyles.FontSize14px>
            <SelectProjectStatus
              defaultValue={filteredProjectStatus && filteredProjectStatus}
              onChange={(e) => setFilteredProjectStatus(e.target.value)}
            />
          </div>
        </div>
      )}
      <Styled.TableHead className="mt-3">
        <div className="d-flex gap-2 align-items-center">
          <TextStyles.FontSize14px
            style={{
              width: "120%",
              display: "flex",
              textAlign: "start",
              minWidth: 30,
            }}
          >
            <ReactTooltip anchorId="projects" content="Sort by project" />
            <span
              className="three-dots hover-project"
              id="projects"
              role="button"
              onClick={() => setSortingActiveHandler("project_name")}
            >
              Project
              {selectedSortingOne === "project_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-project"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-project"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            style={{
              width: "120%",
              display: "flex",
              textAlign: "start",
              minWidth: 30,
            }}
          >
            <ReactTooltip anchorId="totalClients" content="Sort by client" />
            <span
              className="three-dots hover-company"
              id="totalClients"
              role="button"
              onClick={() => setSortingActiveHandler("company_name")}
            >
              Client
              {selectedSortingOne === "company_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-company"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-company"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            style={{
              width: "100%",
              display: "flex",
              textAlign: "start",
              minWidth: 30,
            }}
          >
            <ReactTooltip anchorId="startDate" content="Sort by start date" />
            <span
              className="three-dots hover-start-date"
              id="startDate"
              role="button"
              onClick={() => setSortingActiveHandler("project_start_date")}
            >
              Start date
              {selectedSortingOne === "project_start_date" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-start-date"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-start-date"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            style={{
              width: "100%",
              display: "flex",
              textAlign: "start",
              minWidth: 30,
            }}
          >
            <ReactTooltip anchorId="endDate" content="Sort by end date" />
            <span
              className="three-dots hover-end-date"
              id="endDate"
              role="button"
              onClick={() => setSortingActiveHandler("project_end_date")}
            >
              End date
              {selectedSortingOne === "project_end_date" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-end-date"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-end-date"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "center",
              textAlign: "start",
              minWidth: 30,
            }}
          >
            <ReactTooltip anchorId="status" content="Sort by status" />
            <span
              className="three-dots hover-status"
              id="status"
              role="button"
              onClick={() => setSortingActiveHandler("status")}
            >
              Status
              {selectedSortingOne === "status" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-status"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-status"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              minWidth: 30,
            }}
          >
            <ReactTooltip
              anchorId="amountInvoiced"
              content="Sort by amount invoiced"
            />
            <span
              className="three-dots hover-amount"
              id="amountInvoiced"
              role="button"
              onClick={() => setSortingActiveHandler("totalInvoicedAmount")}
            >
              Amount invoiced
              {selectedSortingOne === "totalInvoicedAmount" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-amount"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-amount"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="project-actions"
            style={{
              width: "80%",
            }}
          />
        </div>
      </Styled.TableHead>

      {projects && projects.length > 0 ? (
        projects.map((item, index) => {
          return (
            <Styled.TableCell
              key={index}
              style={{
                borderRadius:
                  projects.length - 1 === index ? "0 0 12px 12px" : "",
              }}
              onClick={() => {
                setShowEditProjectModal(true);
                setSelectedProject(item);
              }}
            >
              <div className="d-flex gap-2 align-items-center w-100">
                <TextStyles.FontSize14px
                  style={{
                    width: "120%",
                    display: "flex",
                    textAlign: "start",
                    minWidth: 30,
                  }}
                >
                  <span className="three-dots">{item.project_name}</span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  style={{
                    width: "120%",
                    display: "flex",
                    textAlign: "start",
                    minWidth: 30,
                  }}
                >
                  <span className="three-dots">
                    {item.client_id && item.client_id.company_name}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  style={{
                    width: "100%",
                    display: "flex",
                    textAlign: "start",
                    minWidth: 30,
                  }}
                >
                  <span className="three-dots">
                    {item.project_start_date &&
                      moment(item.project_start_date).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  style={{
                    width: "100%",
                    display: "flex",
                    textAlign: "start",
                    minWidth: 30,
                  }}
                >
                  <span className="three-dots">
                    {item.project_end_date &&
                      moment(item.project_end_date).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "start",
                    minWidth: 30,
                  }}
                >
                  <TextStyles.FontSize14px
                    className="three-dots"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.2rem",
                      fontWeight: 600,
                      boxSizing: "border-box",
                      padding: "0.25rem 0.5rem",
                      maxHeight: "1.5rem",
                      background:
                        item.status === "Upcoming"
                          ? "#FFF5E6"
                          : item.status === "Running"
                          ? "#DDF8EC"
                          : item.status === "Ended"
                          ? "#ffeded"
                          : item.status === "Archived"
                          ? "#fafafa"
                          : "",

                      color:
                        item.status === "Upcoming"
                          ? "#f09528"
                          : item.status === "Running"
                          ? "#188652"
                          : item.status === "Ended"
                          ? "#f74040"
                          : item.status === "Archived"
                          ? Colors.font2
                          : "",
                    }}
                  >
                    <span className="three-dots">
                      {new Date(item.project_start_date) > new Date() &&
                      item.status === "Active"
                        ? "Upcoming"
                        : new Date(item.project_start_date) <= new Date() &&
                          item.status === "Active"
                        ? "Running"
                        : item.status}
                    </span>
                  </TextStyles.FontSize14px>
                </div>
                <TextStyles.FontSize14px
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    minWidth: 30,
                  }}
                >
                  <span className="three-dots">
                    {item.currency && item.currency.symbol}
                    {parseFloat(item.totalInvoicedAmount).toFixed(2)}
                  </span>
                </TextStyles.FontSize14px>
                <div
                  className="project-actions"
                  style={{
                    width: "80%",
                  }}
                >
                  <div className="editBtn_desktop">
                    <BorderColorOutlinedIcon
                      id={`edit_project${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowEditProjectModal(true);
                        setSelectedProject(item);
                      }}
                      sx={{
                        color: Colors.success,
                        cursor: "pointer",
                        fontSize: 30,
                        background: Colors.white,
                        padding: "2px 6px",
                        borderRadius: "6px",
                        width: 35,
                        height: 30,
                      }}
                    />

                    <ReactTooltip
                      anchorId={`edit_project${index}`}
                      content="Edit project"
                    />
                  </div>

                  <div
                    className="dropdown"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <button
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{
                          background: Colors.white,
                          cursor: "pointer",
                          borderRadius: "6px",
                          padding: "2px 4px",
                          height: "30px",
                          color: "grey",
                        }}
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="editBtn_mobile">
                        <button className="dropdown-item">
                          <TextStyles.FontSize14px
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowEditProjectModal(true);
                              setSelectedProject(item);
                            }}
                          >
                            Edit project
                          </TextStyles.FontSize14px>
                        </button>
                      </li>

                      {item.status !== "Ended" && (
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowEndModal(true);
                                setConfirmDialog({
                                  onConfirm: () =>
                                    EndProjectHandler(item._id, "Ended"),
                                });
                              }}
                            >
                              End project
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}
                      {item.status !== "Archived" && (
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowArchiveModal(true);
                                setConfirmDialog({
                                  onConfirm: () =>
                                    ArchiveProjectHandler(item._id, "Archived"),
                                });
                              }}
                            >
                              Archive project
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}
                      {item.status === "Archived" && (
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                UnarchiveProjectHandler(item._id, "Running");
                              }}
                            >
                              Unarchive project
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Styled.TableCell>
          );
        })
      ) : isProjectLoading && (projects === null || projects.length === 0) ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isProjectLoading &&
        (projects === null || projects.length === 0) && (
          <Styled.ListBox>
            <img src={NoProjectImage} alt="no-project-img" />
            <TextStyles.GreyFont18px className="mt-2">
              There are no projects created yet.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}

      <div className="d-flex justify-content-center py-5">
        {prTotalCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(prTotalCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>

      {show && (
        <CreateProject
          show={show}
          handleClose={() => setShow(false)}
          updateRefresh={updateRefresh}
          setUpdateRefresh={setUpdateRefresh}
          client_id={c_data_position}
        />
      )}

      {showEditProjectModal && (
        <EditProjectModal
          selectedProject={selectedProject}
          show={showEditProjectModal}
          handleClose={() => setShowEditProjectModal(false)}
          updateRefresh={updateRefresh}
          setUpdateRefresh={setUpdateRefresh}
        />
      )}

      <EndModal
        title="project"
        confirmDialog={confirmDialog}
        showEndModal={showEndModal}
        handleCloseEndModal={handleCloseEndModal}
      />
      <ArchiveModal
        title="project"
        confirmDialog={confirmDialog}
        showArchiveModal={showArchiveModal}
        handleCloseArchiveModal={handleCloseArchiveModal}
      />
    </div>
  );
}
