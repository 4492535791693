import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { postRequest } from "./../../axios/axios";
import URL from "../../axios/constant";
import logo from "../../Assets/assetsnew/backend_logo_with_name.webp";
import loginBg from "../../Assets/frontendImages/loginBg.svg";

import Styled from "./AuthStyles";
import TextStyles from "../../Constants/TextStyles";
import LoaderSpin from "../NewDashboard/Commons/LoaderSpin";

const EmailVerify = () => {
  const userData = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    verifyUserApiCall();
  }, [userData]);

  const verifyUserApiCall = async () => {
    setIsLoading(true);
    postRequest(URL.VERIFY_USER.url, {}, { token: userData.token })
      .then((data) => {
        if (data && data.data && data.data.success) {
          localStorage.clear();
          setIsLoading(false);
          
          history.push("/db/login");
          toast.success(
            <TextStyles.FontSize14px>
              {data.data.message}
            </TextStyles.FontSize14px>
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>
          );
        }
        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>
          );
        }
      });
  };

  const resendEmail = async () => {
    setIsLoading(true);
    await postRequest(URL.RESEND_EMAIL.url, {}, { email: userData.email })
      .then((data) => {
        if (data && data.data && data.data.success) {
          setIsLoading(false);
          toast.success(
            <TextStyles.FontSize14px>
              {data.data.message}
            </TextStyles.FontSize14px>
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>
          );
        }
        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>
          );
        }
      });
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ width: "100%", height: "100vh" }}
    >
      {isLoading ? (
        <LoaderSpin />
      ) : (
        <div style={{ backgroundColor: "#fafbfc", width: "100%" }}>
          <div
            className="fixed-top"
            style={{
              backgroundColor: "#fafbfc",
              width: "100%",
              zIndex: 1000,
              boxShadow: "0 2px 4px 2px rgba(180, 180, 200, 0.2)",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center px-2 px-sm-3"
              style={{ height: 80, width: "100%" }}
            >
              <TextStyles.FontSize27px
                className="d-flex justify-content-center"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/")}
              >
                <img src={logo} alt="logo" style={{ maxWidth: 220 }} />
              </TextStyles.FontSize27px>
              <TextStyles.FontSize16px className="d-flex justify-content-end align-items-center w-100">
                <div className="d-none d-sm-flex me-3">
                  Don’t have an account?
                </div>
                <Styled.LinkSpan
                  className="text-center"
                  onClick={() => history.push("/db/signup")}
                >
                  Sign up
                </Styled.LinkSpan>
              </TextStyles.FontSize16px>
            </div>
          </div>
          <div style={{ paddingTop: 80 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: `url(${loginBg})`,
                backgroundSize: "cover",
                backgroundPosition: "bottom",
                minHeight: "calc(100vh - 80px)",
                width: "100%",
              }}
            >
              <div className="container-fluid pb-3">
                <div className="d-flex justify-content-center align-items-center">
                  <Styled.Box>
                    <TextStyles.FontSize27px
                      className="text-center mb-4"
                      style={{ fontWeight: 500, lineHeight: "1.2" }}
                    >
                      Token expired
                    </TextStyles.FontSize27px>
                    <TextStyles.GreyFont16px className="text-center mb-3">
                      If token is expired, please resend email to verify your
                      account.
                    </TextStyles.GreyFont16px>
                    <div className="d-flex justify-content-center mb-3">
                      <TextStyles.Button
                        disabled={isLoading}
                        style={{
                          fontSize: 16,
                        }}
                        onClick={resendEmail}
                      >
                        <span className="d-flex justify-content-center">
                          Resend email
                        </span>
                      </TextStyles.Button>
                    </div>

                    <TextStyles.FontSize16px className="text-center">
                      Already verified?
                      <Styled.Link
                        style={{
                          paddingLeft: 6,
                        }}
                        onClick={() => history.push("/db/login")}
                      >
                        Login now
                      </Styled.Link>
                    </TextStyles.FontSize16px>
                  </Styled.Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailVerify;
