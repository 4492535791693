import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { createTask } from "../../../../store/service/dashboardService";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

import LoaderSpin from "../../Commons/LoaderSpin";
import { Modal } from "react-bootstrap";

import {
  AutoCompleteSelectProject,
  AutoCompleteSelectClient,
} from "../../Commons/AutoComplete";

import { CustomDatePicker } from "../../Commons/CustomDatePicker";
import SelectFrequency from "../../Commons/SelectFrequency";
import SelectFrequencyTimes from "../../Commons/SelectFrequencyTimes";
import Constant from "../../Commons/Constant";

import Styled from "./EditTaskStyles";
import TextStyles from "../../../../Constants/TextStyles";
import { fetchClientForAProject } from "../../../../store/service/clientService";
import { fetchProjectsForAClient } from "../../../../store/service/projectService";
import { dashboardActions } from "../../../../store/storage/dashboardSlice";
import Colors from "../../../../Constants/Colors";

const CreateTask = ({ handleClose, show, section }) => {
  const [repeatTask, setRepeatTask] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.dashboard.isLoading);
  const clientForAProject = useSelector(
    (state) => state.clients.clientForAProject
  );

  const projectsForAClient = useSelector(
    (state) => state.project.projectsForAClient
  );

  const isClientLoading = useSelector((state) => state.clients.isLoading);
  const isProjectLoading = useSelector((state) => state.project.isLoading);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm();

  const repeatTaskStatus = watch("repeat_task_status");
  useEffect(() => {
    setRepeatTask(repeatTaskStatus);
  }, [repeatTaskStatus]);

  const selectProject = watch("select_project");
  const selectClient = watch("select_client");

  useEffect(() => {
    dispatch(
      fetchClientForAProject({
        project_id: selectProject && selectProject._id,
      })
    );
    dispatch(
      fetchProjectsForAClient({
        client_id: selectClient && selectClient._id,
      })
    );
  }, [selectProject, selectClient]);

  const hideDate = watch("hide_until_date");
  const dueDate = watch("due_date");

  const [hideDateErrorMessage, setHideDateErrorMessage] = useState(null);

  const validateHideDate = () => {
    if (hideDate && dueDate === null) {
      setHideDateErrorMessage("Please select due date before hide date!");
      return false;
    } else if (hideDate && dueDate !== null) {
      const hDate = moment(hideDate);
      const dDate = moment(dueDate);
      if (dDate.isSameOrBefore(hDate)) {
        setHideDateErrorMessage(
          `Hide date should be less than ${moment(new Date(dueDate)).format(
            "DD MMM YYYY"
          )}.`
        );
        return false;
      } else {
        setHideDateErrorMessage(null);
        return true;
      }
    } else {
      setHideDateErrorMessage(null);
      return true;
    }
  };

  useEffect(() => {
    validateHideDate();
  }, [dueDate, hideDate]);

  const formSubmit = (data) => {
    const Obj = {
      assign_to_client_id: data.select_client ? data.select_client._id : null,
      assign_to_project_id: data.select_project
        ? data.select_project._id
        : null,
      task_name: data.task_name,
      due_date: data.due_date ? data.due_date : null,
      hide_until_date: data.hide_until_date ? data.hide_until_date : null,
      starting_date:
        data.repeat_task_status === true && data.starting_date !== undefined
          ? data.starting_date
          : data.repeat_task_status === true && data.starting_date === undefined
          ? new Date()
          : null,
      frequency: data.repeat_task_status === true ? data.frequency : "One time",
      times:
        data.repeat_task_status === true && data.frequency === "One time"
          ? 1
          : data.repeat_task_status === true && data.frequency !== "One time"
          ? data.times
          : 1,
      recurring: data.repeat_task_status,
    };

    dispatch(createTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(dashboardActions.updateNewCreatedTaskFromHeader());
        handleClose();
        reset();
        if (section === "navbar") {
          if (history.location.pathname !== "/db/home") {
            history.push("/db/home");
          }
        }

        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <Modal
      scrollable={repeatTask}
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      centered
    >
      <form onSubmit={handleSubmit(formSubmit)} className="modal-content">
        <div
          className="modal-header d-flex justify-content-between"
          style={{ padding: "15px 24px" }}
        >
          <TextStyles.FontSize20px>Create</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              opacity: 0.7,
              cursor: "pointer",
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </div>

        <div className="modal-body" style={{ padding: "15px 24px" }}>
          <TextStyles.FontSize14px>
            <label htmlFor="taskName" className="mb-2">
              Task Name
            </label>
            <Styled.Input
              id="taskName"
              rows={2}
              type="text"
              placeholder="Enter task name"
              name="task_name"
              {...register("task_name", {
                required: "Task name is required.",
              })}
            />
            {errors.task_name && (
              <TextStyles.InValidFeedback>
                {errors.task_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="my-3">
            <div className="row">
              <div className="col-6">
                <label htmlFor="due_date" className="mb-2">
                  Due date
                </label>
                <TextStyles.DateRectangle>
                  <Controller
                    name={"due_date"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomDatePicker
                          onChange={onChange}
                          selected={value}
                          placeholderText="Set due date"
                          todayButton="Today"
                          dateFormat="dd MMM yyyy"
                          isClearable={true}
                        />
                      );
                    }}
                  />
                </TextStyles.DateRectangle>
              </div>
              <div className="col-6">
                <label htmlFor="hide_until_date" className="mb-2">
                  Hide until
                </label>
                <TextStyles.DateRectangle invalid={hideDateErrorMessage}>
                  <Controller
                    name={"hide_until_date"}
                    control={control}
                    rules={{ validate: validateHideDate }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomDatePicker
                          onChange={onChange}
                          selected={value}
                          placeholderText="Set hide date"
                          todayButton="Today"
                          dateFormat="dd MMM yyyy"
                          isClearable={true}
                          invalid={hideDateErrorMessage}
                        />
                      );
                    }}
                  />
                </TextStyles.DateRectangle>
              </div>
              {hideDateErrorMessage && (
                <TextStyles.InValidFeedback className="mt-2">
                  {hideDateErrorMessage}
                </TextStyles.InValidFeedback>
              )}
            </div>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="project" className="mb-2">
              Select project
            </label>
            <Controller
              control={control}
              name="select_project"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteSelectProject
                  placeholder="Select project"
                  isLoading={isProjectLoading}
                  options={
                    projectsForAClient &&
                    projectsForAClient.map((d) => {
                      return {
                        _id: d && d._id,
                        title: d && d.project_name,
                        client_id: d && d.client_id && d.client_id._id,
                        client_name:
                          d && d.client_id && d.client_id.company_name,
                      };
                    })
                  }
                  onChange={onChange}
                  defaultValue={value}
                />
              )}
            />
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="select_client" className="mb-2">
              Select client
            </label>

            <Controller
              control={control}
              name="select_client"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteSelectClient
                  placeholder="Select client"
                  isLoading={isClientLoading}
                  options={
                    clientForAProject &&
                    clientForAProject.map((data) => {
                      return {
                        label: data && data.company_name,
                        _id: data && data._id,
                      };
                    })
                  }
                  onChange={onChange}
                  defaultValue={value}
                />
              )}
            />
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <TextStyles.CheckBox className="form-check">
              <input
                name="repeat_task_status"
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                {...register("repeat_task_status")}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Repeat task
              </label>
            </TextStyles.CheckBox>
          </TextStyles.FontSize14px>

          {repeatTask && (
            <>
              <TextStyles.FontSize14px className="mb-3">
                <div className="row">
                  <div className="col">
                    <Controller
                      control={control}
                      name="frequency"
                      render={({ field: { onChange, value } }) => (
                        <SelectFrequency
                          options={Constant.taskFrequencyOptions}
                          placeholder="Select frequency"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>
                  <div className="col">
                    <Controller
                      control={control}
                      name="times"
                      render={({ field: { onChange, value } }) => (
                        <SelectFrequencyTimes
                          options={Constant.taskFrequencyTimesOptions}
                          placeholder="Select times"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>
                </div>
              </TextStyles.FontSize14px>
              <TextStyles.FontSize14px className="mb--3">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="starting_date" className="mb-2">
                      Starting date
                    </label>
                    <TextStyles.DateRectangle invalid={errors.starting_date}>
                      <Controller
                        name={"starting_date"}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CustomDatePicker
                              onChange={onChange}
                              selected={value}
                              placeholderText="Set starting date"
                              todayButton="Today"
                              dateFormat="dd MMM yyyy"
                              isClearable={true}
                              // minDate={
                              //   new Date(moment(new Date()).add(1, "days"))
                              // }
                            />
                          );
                        }}
                      />
                    </TextStyles.DateRectangle>
                  </div>
                </div>
              </TextStyles.FontSize14px>
            </>
          )}
        </div>

        <div className="modal-footer" style={{ display: "inline-block" }}>
          <div className="d-flex gap-4 justify-content-between align-items-center">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button type="submit" disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateTask;
