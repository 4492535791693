import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";

import EditProposalReview from "../ProposalReviews/Review";
import EditContractReview from "../ContractReviews/Review";
import Send from "../Send/Send";

import Styled from "../CreateAgreements/CreateAgreementsStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { BackButton } from "../../Commons/BackAndForwardButton";
export default function EditProposal() {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const history = useHistory();
  const [page, setPage] = useState(0);

  const backBtn = () => {
    history.push("/db/agreements");
  };

  const createProposal = ["Review proposal", "Send"];
  const createContract = ["Review contract", "Send"];
  const createDocuments = ["Review proposal", "Review contract", "Send"];

  const [pageTitle, setPageTitle] = useState(createProposal);
  const [btn, setBtn] = useState("Edit proposal");

  useEffect(() => {
    if (selectedData.section === "Proposal and contract") {
      setPageTitle(createDocuments);
      setBtn("Edit proposal and contract");
    } else if (selectedData.section === "Contract") {
      setPageTitle(createContract);
      setBtn("Edit contract");
    } else {
      setPageTitle(createProposal);
      setBtn("Edit proposal");
    }
  }, []);

  const pageDisplay = () => {
    if (page === 0) {
      if (btn === "Edit proposal" || btn === "Edit proposal and contract") {
        return (
          <EditProposalReview
            page={page}
            setPage={setPage}
            backBtn={
              <div
                style={{ position: "fixed", left: 20, top: "50vh" }}
                onClick={() => history.goBack()}
              >
                <BackButton />
              </div>
            }
          />
        );
      } else {
        return (
          <EditContractReview
            page={page}
            setPage={setPage}
            backBtn={
              <div
                style={{
                  position: "fixed",
                  left: 20,
                  top: "50vh",
                  zIndex: 200,
                }}
                onClick={() => history.goBack()}
              >
                <BackButton />
              </div>
            }
          />
        );
      }
    } else if (pageTitle.length === 3 && page === 1) {
      return (
        <EditContractReview
          page={page}
          setPage={setPage}
          backBtn={
            <div
              style={{ position: "fixed", left: 20, top: "50vh", zIndex: 200 }}
              onClick={() => setPage(page - 1)}
            >
              <BackButton />
            </div>
          }
        />
      );
    } else {
      return (
        <Send
          backBtn={
            <div
              style={{ position: "fixed", left: 20, top: "50vh", zIndex: 200 }}
              onClick={() => setPage(page - 1)}
            >
              <BackButton />
            </div>
          }
        />
      );
    }
  };

  return (
    <div>
      <div
        style={{
          background: Colors.backgroundGrey,
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            background: Colors.white,
            borderBottom: "2px solid #dfe1e9",
            zIndex: 200,
          }}
          className="fixed-top"
        >
          <div className="p-2">
            <TextStyles.FontSize14px
              style={{
                position: "absolute",
                left: "0.75rem",
                top: "0.75rem",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={backBtn}
              >
                <ClearIcon
                  sx={{
                    fontSize: 27,
                    color: Colors.font2,
                    cursor: "pointer",
                    opacity: 0.7,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize20px
              className="d-flex justify-content-center w-100 ps-4 ps-sm-none"
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: 24,
                minWidth: 200,
              }}
            >
              <span className="three-dots mx-2"> {btn}</span>
            </TextStyles.FontSize20px>
          </div>
          <Styled.ProgressBar>
            <div
              className="w-100"
              style={{
                height: "100%",
                paddingTop: "3px",
                backgroundColor: Colors.primary,
                color: Colors.white,
                justifyContent: "center",
                display: "flex",
                minWidth: "5rem",
              }}
            >
              <span className="three-dots mx-2">{pageTitle[0]}</span>{" "}
            </div>
            <div
              className="w-100"
              style={
                page > 0
                  ? {
                      height: "100%",
                      paddingTop: "3px",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                    }
                  : {
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                    }
              }
            >
              <span className="three-dots mx-2">{pageTitle[1]}</span>
            </div>
            {pageTitle.length === 3 ? (
              <>
                <div
                  className="w-100"
                  style={
                    page > 1
                      ? {
                          height: "100%",
                          paddingTop: "3px",
                          backgroundColor: Colors.primary,
                          color: Colors.white,
                          justifyContent: "center",
                          display: "flex",
                          minWidth: "5rem",
                        }
                      : {
                          justifyContent: "center",
                          display: "flex",
                          minWidth: "5rem",
                        }
                  }
                >
                  <span className="three-dots mx-2">{pageTitle[2]}</span>
                </div>
              </>
            ) : null}
          </Styled.ProgressBar>
        </div>

        <div style={{ paddingTop: 100 }}>{pageDisplay()}</div>
      </div>
    </div>
  );
}
