import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageModals";
import { updateCancellationFeePC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import SwitchButton from "../../../Commons/SwitchButton";

const CancellationFeeModal = ({ show, handleClose, heading, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const [selector, setSelector] = useState(
    selectedData &&
      selectedData.cancellation_fee &&
      selectedData.cancellation_fee.cancellation_fee_deposit_require === true
      ? true
      : false
  );

  const [cancellationAmount, setCancellationAmount] = useState(
    selectedData &&
      selectedData.cancellation_fee &&
      selectedData.cancellation_fee.cancellation_fee_amount &&
      selectedData.cancellation_fee.cancellation_fee_deposit_require === true
      ? parseFloat(
          selectedData.cancellation_fee.cancellation_fee_amount
        ).toFixed(2)
      : ""
  );

  const paragraphWithoutCancellatonFee =
    "Note: This section shows if only applicable.";

  const paragraphWithCancellatonFee = `If the Client terminates this Agreement earlier without cause, Contractor will charge a cancellation fee of ${selectedData.currency.symbol}${cancellationAmount}.`;

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      heading: heading,
      paragraph:
        selector === true
          ? paragraphWithCancellatonFee
          : paragraphWithoutCancellatonFee,
      cancellation_fee_amount:
        selectedData &&
        selectedData.cancellation_fee &&
        selectedData.cancellation_fee.cancellation_fee_amount &&
        selectedData.cancellation_fee.cancellation_fee_deposit_require === true
          ? parseFloat(
              selectedData.cancellation_fee.cancellation_fee_amount
            ).toFixed(2)
          : "0",
    },
  });

  const amount = watch("cancellation_fee_amount");
  useEffect(() => {
    setCancellationAmount(() => amount);
  }, [amount]);

  useEffect(() => {
    if (selector === true) {
      setValue("paragraph", paragraphWithCancellatonFee);
    } else {
      setValue("paragraph", paragraphWithoutCancellatonFee);
    }
  }, [amount, selector, cancellationAmount]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      cancellation_fee: {
        required: true,
        cancellation_fee_deposit_require: selector === true ? true : false,
        cancellation_fee_amount:
          selector === true ? item.cancellation_fee_amount : "",
      },
    };

    dispatch(updateCancellationFeePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            Clause updated successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder={heading}
              id="headline"
              name="heading"
              style={{ opacity: 0.5 }}
              {...register("heading")}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              rows={2}
              style={{ opacity: 0.5 }}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize18px className="mb-3">
            Options
          </TextStyles.FontSize18px>
          <TextStyles.GreyFont14px className="d-flex gap-2 justify-content-between align-items-center">
            <div className="d-flex" style={{ minWidth: 100 }}>
              {" "}
              <span className="three-dots">Add cancellation fee amount?</span>
            </div>
            <div className="d-flex justify-content-end">
              <SwitchButton
                defaultChecked={selector}
                onChange={(event) => setSelector(event.target.checked)}
              />
            </div>
          </TextStyles.GreyFont14px>
          {selector === true && (
            <div className="d-flex gap-2 justify-content-between align-items-center mt-3">
              <TextStyles.GreyFont14px
                className="d-flex"
                style={{ minWidth: 50 }}
              >
                <span className="three-dots"> Cancellation fee</span>
              </TextStyles.GreyFont14px>
              <TextStyles.FontSize14px className="d-flex justify-content-end">
                <Styled.inputBox invalid={errors.cancellation_fee_amount}>
                  <span>
                    {selectedData &&
                      selectedData.currency &&
                      selectedData.currency.symbol}
                  </span>
                  <input
                    type="text"
                    id="cancellation_fee_amount"
                    placeholder="00.00"
                    style={{
                      width: "84%",
                      maxWidth: "8rem",
                      background: "transparent",
                    }}
                    name="cancellation_fee_amount"
                    {...register("cancellation_fee_amount", {
                      required: "Please enter cancellation fee.",
                      min: {
                        value: 1,
                        message: "Please enter more than zero.",
                      },
                      pattern: {
                        value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                        message: "Please enter a valid number",
                      },
                    })}
                  />
                </Styled.inputBox>
              </TextStyles.FontSize14px>
              {selector === true && errors.cancellation_fee_amount && (
                <TextStyles.InValidFeedback>
                  {errors.cancellation_fee_amount.message}
                </TextStyles.InValidFeedback>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading ? true : false}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CancellationFeeModal;
