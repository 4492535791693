import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateAddNonCompletePC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import NonCompeteModal from "../../PageModals/NonCompeteModal";
import Colors from "../../../../../../Constants/Colors";

const NonCompete = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData &&
    selectedData.add_non_complete &&
    selectedData.add_non_complete.heading
      ? selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.heading
      : "Non-Compete";
  const paragraph =
    selectedData &&
    selectedData.add_non_complete &&
    selectedData.add_non_complete.paragraph
      ? selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.paragraph
      : "Note: This section only show if applicable.";

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      add_non_complete: {
        required: false,
        add_non_complete_clause_require: false,
        heading: null,
        paragraph: null,
        add_non_complete_clause_text: null,
      },
    };
    dispatch(updateAddNonCompletePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.add_non_complete &&
          selectedData.add_non_complete.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        {selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.add_non_complete_clause_require ===
          true ? (
          <div>
            <Styled.EditButtonToolTip
              className="contract_review_edit_tp"
              style={{ marginLeft: -60 }}
              onClick={() => setShowModal(true)}
            >
              <BorderColorOutlinedIcon
                sx={{ color: Colors.white, fontSize: 20 }}
              />
              <span className="ms-1">Edit</span>
            </Styled.EditButtonToolTip>
            <Styled.EditButtonToolTip
              backgroundColor="#383838"
              className="contract_review_edit_tp"
              style={{ marginLeft: 60 }}
              onClick={() => {
                setShowRemoveModal(true);
                setConfirmDialog({
                  onConfirm: hideClauseHandler,
                });
              }}
            >
              <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
              <span className="ms-1">Hide</span>
            </Styled.EditButtonToolTip>
          </div>
        ) : (
          <Styled.EditButtonToolTip
            className="contract_review_edit_tp"
            backgroundColor={Colors.white}
            color={Colors.font1}
            onClick={() => setShowModal(true)}
          >
            show
          </Styled.EditButtonToolTip>
        )}

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>
          {selectedData &&
          selectedData.add_non_complete &&
          selectedData.add_non_complete.add_non_complete_clause_require === true
            ? selectedData.add_non_complete.add_non_complete_clause_text
            : paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <NonCompeteModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          paragraph={paragraph}
          heading={heading}
          selectedData={selectedData}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default NonCompete;
