import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postRequest } from "./../../axios/axios";
import URL from "../../axios/constant";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/storage/authSlice";

import { toast } from "react-toastify";

import PasswordToggle from "./PasswordToggle";

import LoaderSpin from "../NewDashboard/Commons/LoaderSpin";

import Styled from "./AuthStyles";
import Colors from "../../Constants/Colors";
import TextStyles from "../../Constants/TextStyles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../../Assets/assetsnew/backend_logo_with_name.webp";
import loginBg from "../../Assets/frontendImages/loginBg.svg";

import { LoginSocialGoogle, LoginSocialLinkedin } from "reactjs-social-login";
import { getCountryNameByUserLocation } from "../NewDashboard/Commons/utils";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { fetchUserSetupData } from "../../store/service/AuthService";
import { Redirect } from "react-router-dom";
import DeletedAccountSuccessModal from "./DeletedAccountSuccessModal";
import { Helmet } from "react-helmet";
const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [InputType, Icons] = PasswordToggle();
  const [isLoading, setIsLoading] = useState(false);
  const [isSocialLoading, setIsSocialLoading] = useState(false);
  const [userType, setuserType] = useState(null);

  // deleted account modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm();

  const pathname = localStorage.getItem("pathname")
    ? localStorage.getItem("pathname")
    : "/db/home";

  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[A-Za-z0-9+._]+@[A-Za-z0-9]+\.[A-Za-z]{2}/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const onSubmit = async (data) => {
    const Obj = {
      email: data.email.toLowerCase(),
      password: data.password,
    };
    setIsLoading(true);
    postRequest(URL.LOGIN.url, {}, Obj)
      .then((data) => {
        if (data && data.data && data.data.success) {
          localStorage.clear();
          localStorage.setItem("token", data.data.token);
          localStorage.setItem(
            "userType",
            JSON.stringify(data.data.data.userType)
          );
          const msg = data.data.message;
          dispatch(authActions.login(data.data.data));
          dispatch(fetchUserSetupData()).then((data) => {
            if (data && data.meta && data.meta.requestStatus === "fulfilled") {
              setIsLoading(false);
              history.push(pathname);
              reset();
              toast.success(
                <TextStyles.FontSize14px>{msg}</TextStyles.FontSize14px>
              );
            }
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err.response && err.response.status === 404) {
          setShow(true);
          return;
        }
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>
          );
        }
        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>
          );
        }
      });
  };

  const REDIRECT_URI = window.location.href;

  const socialLogin = async (data) => {
    const Obj = {
      country: await getCountryNameByUserLocation(),
      userType: data.provider,
      accessToken: data && data.data && data.data.access_token,
    };
    setIsSocialLoading(true);
    setuserType(data.provider);
    postRequest(URL.SOCIAL_LOGIN.url, {}, Obj)
      .then((data) => {
        if (data && data.data && data.data.success) {
          localStorage.clear();
          localStorage.setItem("token", data.data.token);
          localStorage.setItem(
            "userType",
            JSON.stringify(data.data.data.userType)
          );
          const msg = data.data.message;
          dispatch(authActions.login(data.data.data));
          dispatch(fetchUserSetupData()).then((data) => {
            if (data && data.meta && data.meta.requestStatus === "fulfilled") {
              toast.success(
                <TextStyles.FontSize14px>{msg}</TextStyles.FontSize14px>
              );
              setIsSocialLoading(false);
              history.push(pathname);
              // setuserType(null);
            }
          });
        }
      })
      .catch((err) => {
        setIsSocialLoading(false);
        if (err && err.response && err.response.status === 404) {
          setShow(true);
          return;
        }
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>
          );
        }

        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>
          );
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Log in - Zodot</title>
        <meta
          content="Streamline your freelance operations with Zodot: a free cloud-based tool for easy invoicing, time tracking, and project management."
          name="description"
        />
        <link rel="canonical" href="https://zodot.co/db/login" />
      </Helmet>
      {token ? (
        <Redirect to="/db/home" />
      ) : (
        <div style={{ backgroundColor: "#fafbfc" }}>
          <div
            className="fixed-top"
            style={{
              backgroundColor: "#fafbfc",
              width: "100%",
              zIndex: 1000,
              boxShadow: "0 2px 4px 2px rgba(180, 180, 200, 0.2)",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center px-2 px-sm-3"
              style={{ height: 80, width: "100%" }}
            >
              <TextStyles.FontSize27px
                className="d-flex justify-content-center"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/")}
              >
                <img src={logo} alt="logo" style={{ maxWidth: 200 }} />
              </TextStyles.FontSize27px>
              <TextStyles.FontSize16px className="d-flex justify-content-end align-items-center w-100">
                <div className="d-none d-sm-flex me-3">
                  Don’t have an account?
                </div>
                <Styled.LinkSpan
                  className="text-center"
                  onClick={() => history.push("/db/signup")}
                >
                  Sign up
                </Styled.LinkSpan>
              </TextStyles.FontSize16px>
            </div>
          </div>
          <div style={{ paddingTop: 80 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: `url(${loginBg})`,
                backgroundSize: "cover",
                backgroundPosition: "bottom",
                minHeight: "calc(100vh - 80px)",
                width: "100%",
              }}
            >
              <div className="container-fluid pb-3">
                <div className="d-flex justify-content-center align-items-center">
                  <Styled.Box>
                    <div className="d-flex flex-column justify-content-center">
                      <TextStyles.FontSize27px
                        className="d-flex justify-content-center mb-3"
                        style={{ fontWeight: 500 }}
                      >
                        Login
                      </TextStyles.FontSize27px>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <TextStyles.FontSize16px className="mb-3">
                          <div className="position-relative">
                            <TextStyles.InputRectangle
                              style={{ paddingLeft: "2.75rem" }}
                              type="text"
                              invalid={errors.email}
                              name="email"
                              id="email"
                              placeholder="Email address"
                              {...register("email", {
                                required: "Email is required.",
                                validate: validateEmail,
                                onBlur: (e) =>
                                  setValue("email", e.target.value.trim()),
                              })}
                              // {...register("email", {
                              //   required: "Email is required.",
                              //   pattern: {
                              //     value: /^[A-Za-z0-9+._]+@[A-Za-z0-9]+\.[A-Za-z]{2}/i,
                              //     message: "Please enter valid email address.",
                              //   },
                              // })}
                            />
                            <span
                              style={{
                                position: "absolute",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                top: "1rem",
                                left: "1rem",
                              }}
                            >
                              <MailOutlineIcon
                                sx={{ fontSize: 20, color: Colors.font2 }}
                              />
                            </span>
                          </div>

                          {errors.email && (
                            <TextStyles.InValidFeedback>
                              {errors.email.message}
                            </TextStyles.InValidFeedback>
                          )}
                        </TextStyles.FontSize16px>

                        <TextStyles.FontSize16px>
                          <div className="position-relative">
                            <Styled.PasswordInputRectangle
                              invalid={errors.password}
                              style={{ paddingLeft: "2.75rem" }}
                              type={InputType}
                              name="password"
                              id="password"
                              placeholder="Password"
                              {...register("password", {
                                required: "Password is required.",
                                pattern: {
                                  value:
                                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/i,
                                  message:
                                    "Please enter minimum six characters, at least one letter, one number and one special character",
                                },
                              })}
                            />
                            <span
                              style={{
                                position: "absolute",
                                top: "0.8rem",
                                left: "1rem",
                              }}
                            >
                              <LockOutlinedIcon
                                sx={{ fontSize: 20, color: Colors.font2 }}
                              />
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "0.85rem",
                                right: "1rem",
                                cursor: "pointer",
                              }}
                            >
                              {Icons}
                            </span>
                          </div>

                          {errors.password && (
                            <TextStyles.InValidFeedback>
                              {errors.password.message}
                            </TextStyles.InValidFeedback>
                          )}
                        </TextStyles.FontSize16px>
                        <TextStyles.GreyFont16px className="text-end">
                          <span
                            onClick={() => history.push("/db/forgot")}
                            style={{ cursor: "pointer" }}
                          >
                            Forgot password?
                          </span>
                        </TextStyles.GreyFont16px>
                        <TextStyles.Button
                          className="mt-4"
                          style={{
                            fontSize: 16,
                            opacity: isLoading ? 0.5 : 1,
                          }}
                        >
                          {isLoading ? <LoaderSpin /> : "Log in"}
                        </TextStyles.Button>
                      </form>

                      <div className="d-flex justify-content-center align-items-center my-2">
                        <hr style={{ width: "100%", height: "0.5px" }} />
                        <TextStyles.GreyFont20px
                          className="d-flex justify-content-center w-100"
                          style={{ maxWidth: "5rem" }}
                        >
                          OR
                        </TextStyles.GreyFont20px>
                        <hr style={{ width: "100%", height: "0.5px" }} />
                      </div>
                      <div className="mb-2">
                        <LoginSocialGoogle
                          client_id={
                            process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID || ""
                          }
                          redirect_uri={REDIRECT_URI}
                          isOnlyGetToken={true}
                          scope="openid profile email"
                          discoveryDocs="claims_supported"
                          onResolve={socialLogin}
                          onReject={(err) => {
                            console.log(err);
                          }}
                        >
                          <Styled.SocialLoginButton
                            style={{
                              position: "relative",
                            }}
                            backgroundColor={Colors.white}
                            color={Colors.font1}
                          >
                            <div
                              className="d-flex"
                              style={{
                                opacity:
                                  isSocialLoading && userType === "google"
                                    ? 0.5
                                    : 1,
                              }}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                  position: "absolute",
                                  width: "5rem",
                                  height: "3.15rem",
                                  left: 0,
                                  top: 0,
                                }}
                              >
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 70 70"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.406 35c0-3.468.956-6.717 2.616-9.499v-11.8h-11.8C2.539 19.783 0 27.196 0 35c0 7.803 2.539 15.216 7.222 21.299h11.8v-11.8a18.48 18.48 0 0 1-2.616-9.5z"
                                    fill="#FBBD00"
                                  />
                                  <path
                                    d="m35 53.594-8.203 8.203L35 70c7.804 0 15.216-2.539 21.299-7.222V50.991H44.51A18.591 18.591 0 0 1 35 53.594z"
                                    fill="#0F9D58"
                                  />
                                  <path
                                    d="m19.022 44.498-11.8 11.8a35.565 35.565 0 0 0 3.03 3.45C16.861 66.36 25.65 70 35 70V53.593c-6.785 0-12.731-3.653-15.978-9.095z"
                                    fill="#31AA52"
                                  />
                                  <path
                                    d="M70 35c0-2.13-.193-4.263-.573-6.341l-.308-1.681H35v16.406h16.605a18.51 18.51 0 0 1-7.094 7.606L56.3 62.778a35.565 35.565 0 0 0 3.45-3.03C66.359 53.139 70 44.349 70 35z"
                                    fill="#3C79E6"
                                  />
                                  <path
                                    d="m48.148 21.852 1.45 1.45 11.6-11.6-1.45-1.45C53.139 3.64 44.35 0 35 0l-8.203 8.203L35 16.406c4.966 0 9.636 1.934 13.148 5.446z"
                                    fill="#CF2D48"
                                  />
                                  <path
                                    d="M35 16.406V0C25.65 0 16.862 3.64 10.25 10.251a35.518 35.518 0 0 0-3.03 3.45l11.8 11.8C22.27 20.06 28.216 16.406 35 16.406z"
                                    fill="#EB4132"
                                  />
                                </svg>
                              </div>

                              <div className="d-flex justify-content-center align-items-center w-100">
                                Sign in with Google
                              </div>
                            </div>
                            {isSocialLoading && userType === "google" && (
                              <div
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  left: "auto",
                                  top: "0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                <LoaderSpin color={Colors.primary} />
                              </div>
                            )}
                          </Styled.SocialLoginButton>
                        </LoginSocialGoogle>
                      </div>
                      <div>
                        {" "}
                        <LoginSocialLinkedin
                          client_id={
                            process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_ID || ""
                          }
                          client_secret={
                            process.env.REACT_APP_LINKEDIN_LOGIN_SECERET || ""
                          }
                          redirect_uri={REDIRECT_URI}
                          scope="profile email openid"
                          isOnlyGetToken={true}
                          onResolve={socialLogin}
                          onReject={(err) => {
                            console.log(err);
                          }}
                        >
                          <Styled.SocialLoginButton
                            style={{
                              position: "relative",
                            }}
                            backgroundColor={Colors.white}
                            color={Colors.font1}
                          >
                            <div
                              className="d-flex"
                              style={{
                                opacity:
                                  isSocialLoading && userType === "linkedin"
                                    ? 0.5
                                    : 1,
                              }}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                  position: "absolute",
                                  width: "5rem",
                                  height: "3.15rem",
                                  left: 0,
                                  top: 0,
                                }}
                              >
                                <LinkedInIcon
                                  sx={{
                                    fontSize: 40,
                                    color: "#0e76a8",
                                    padding: "0px",
                                  }}
                                />
                              </div>

                              <div className="d-flex justify-content-center align-items-center w-100">
                                Sign in with LinkedIn
                              </div>
                            </div>
                            {isSocialLoading && userType === "linkedin" && (
                              <div
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  left: "auto",
                                  top: "0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <LoaderSpin color={Colors.primary} />
                              </div>
                            )}
                          </Styled.SocialLoginButton>
                        </LoginSocialLinkedin>
                      </div>
                    </div>
                  </Styled.Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DeletedAccountSuccessModal show={show} handleClose={handleClose} />
    </>
  );
};

export default Login;
