import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import ClearIcon from "@mui/icons-material/Clear";

import Setup from "./Setup/Setup.jsx";
import ProposalReview from "../ProposalReviews/Review";
import ContractReview from "../ContractReviews/Review";
import Send from "../Send/Send.jsx";

import Styled from "./CreateAgreementsStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { BackButton } from "../../Commons/BackAndForwardButton";
import { useSelector } from "react-redux";
import LoaderSpin from "../../Commons/LoaderSpin";

export default function CreateProposal(props) {
  const addAgreement = props.location.state.selectedAgreement;
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const history = useHistory();
  const [page, setPage] = useState(0);

  const backBtn = () => {
    history.push("/db/agreements");
  };

  const createProposal = ["Setup", "Review proposal", "Send"];
  const createContract = ["Setup", "Review contract", "Send"];
  const createDocuments = [
    "Setup",
    "Review proposal",
    "Review contract",
    "Send",
  ];

  const [pageTitle, setPageTitle] = useState(
    addAgreement === "Create proposal and contract"
      ? createDocuments
      : addAgreement === "Create contract"
      ? createContract
      : addAgreement === "Create proposal"
      ? createProposal
      : createProposal
  );
  const [btn, setBtn] = useState(addAgreement);

  const ProposalHandler = () => {
    setPageTitle(createProposal);
    setBtn("Create proposal");
  };

  const ContractHandler = () => {
    setPageTitle(createContract);
    setBtn("Create contract");
  };

  const BothHandler = () => {
    setPageTitle(createDocuments);
    setBtn("Create proposal and contract");
  };

  const pageDisplay = () => {
    if (page === 0) {
      return (
        <Setup
          setPage={setPage}
          page={page}
          BothHandler={BothHandler}
          ProposalHandler={ProposalHandler}
          ContractHandler={ContractHandler}
          btn={btn}
          backBtn={
            <div
              style={{ position: "fixed", left: 20, top: "50vh", zIndex: 200 }}
              onClick={() => history.goBack()}
            >
              <BackButton />
            </div>
          }
        >
          <Styled.SubmitButton
            disabled={isLoading ? true : false}
            style={{ opacity: isLoading ? 0.5 : 1 }}
          >
            {isLoading ? (
              <LoaderSpin />
            ) : btn === "Create proposal and contract" ? (
              "Create documents"
            ) : (
              btn
            )}
          </Styled.SubmitButton>
        </Setup>
      );
    } else if (page === 1) {
      if (btn === "Create proposal" || btn === "Create proposal and contract") {
        return <ProposalReview setPage={setPage} page={page} />;
      } else {
        return <ContractReview setPage={setPage} page={page} />;
      }
    } else if (pageTitle.length === 4 && page === 2) {
      return (
        <ContractReview
          setPage={setPage}
          page={page}
          backBtn={
            <div
              style={{ position: "fixed", left: 20, top: "50vh", zIndex: 200 }}
              onClick={() => setPage(page - 1)}
            >
              <BackButton />
            </div>
          }
        />
      );
    } else {
      return (
        <Send
          backBtn={
            <div
              style={{ position: "fixed", left: 20, top: "50vh", zIndex: 200 }}
              onClick={() => setPage(page - 1)}
            >
              <BackButton />
            </div>
          }
        />
      );
    }
  };

  return (
    <div
      style={{
        background: Colors.backgroundGrey,
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          background: Colors.white,
          borderBottom: "2px solid #dfe1e9",
          zIndex: 200,
        }}
        className="fixed-top"
      >
        <div className="p-2">
          <TextStyles.FontSize14px
            style={{
              position: "absolute",
              left: "0.75rem",
              top: "0.75rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={backBtn}
            >
              <ClearIcon
                sx={{
                  fontSize: 27,
                  color: Colors.font2,
                  cursor: "pointer",
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize20px
            className="d-flex justify-content-center w-100 ps-4 ps-sm-none"
            style={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: 24,
              minWidth: 200,
            }}
          >
            <span className="three-dots mx-2"> {btn}</span>
          </TextStyles.FontSize20px>
        </div>
        <div className="d-flex">
          <Styled.ProgressBar>
            <div
              className="w-100"
              style={{
                height: "100%",
                paddingTop: "3px",
                backgroundColor: Colors.primary,
                color: Colors.white,
                justifyContent: "center",
                display: "flex",
                minWidth: "5rem",
              }}
            >
              <span className="three-dots mx-2">{pageTitle[0]}</span>
            </div>
            <div
              className="w-100"
              style={
                page > 0
                  ? {
                      height: "100%",
                      paddingTop: "3px",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                    }
                  : {
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                    }
              }
            >
              <span className="three-dots mx-2"> {pageTitle[1]}</span>
            </div>
            <div
              className="w-100"
              style={
                page > 1
                  ? {
                      height: "100%",
                      paddingTop: "3px",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                    }
                  : {
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                    }
              }
            >
              <span className="three-dots mx-2">{pageTitle[2]}</span>
            </div>
            {pageTitle.length === 4 ? (
              <>
                <div
                  className="w-100"
                  style={
                    page > 2
                      ? {
                          height: "100%",
                          paddingTop: "3px",
                          backgroundColor: Colors.primary,
                          color: Colors.white,
                          justifyContent: "center",
                          display: "flex",
                          minWidth: "5rem",
                        }
                      : {
                          justifyContent: "center",
                          display: "flex",
                          minWidth: "5rem",
                        }
                  }
                >
                  <span className="three-dots mx-2">{pageTitle[3]}</span>
                </div>
              </>
            ) : null}
          </Styled.ProgressBar>
        </div>
      </div>

      <div style={{ paddingTop: 100 }}>{pageDisplay()}</div>
    </div>
  );
}
