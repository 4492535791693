import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import { deleteAddSectionTextPC } from "../../../../../../store/service/proposalAndContractService";
import EditAddSectionTextModal from "./EditAddSectionTextModal";

import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

const Text = ({ data, pc_id, position }) => {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const deleteHandler = () => {
    const Obj = {
      pc_id: pc_id,
      _id: data._id,
      position: position,
    };
    dispatch(deleteAddSectionTextPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div className="mt-4">
      <Styled.ReviewBox>
        <div className="d-flex justify-content-end">
          <HighlightOffOutlinedIcon
            onClick={() => {
              setShowRemoveModal(true);
              setConfirmDialog({
                onConfirm: () => deleteHandler(),
              });
            }}
            sx={{ cursor: "pointer", fontSize: 24, color: Colors.red }}
          />
        </div>
        <div>
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Text title</div>
            <div className="d-flex gap-1 justify-content-between align-items-center">
              <div
                className="d-flex justify-content-start w-100"
                style={{ minWidth: 100 }}
              >
                <Styled.TextBoader
                  className="d-flex w-100"
                  style={{ minWidth: 100 }}
                >
                  <span className="three-dots">{data.text_title}</span>
                </Styled.TextBoader>
              </div>

              <div
                className="d-flex justify-content-end w-100"
                style={{ minWidth: 30, maxWidth: 30 }}
              >
                <BorderColorOutlinedIcon
                  onClick={() => setEditModal(true)}
                  sx={{
                    fontSize: 24,
                    color: Colors.font2,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px>
            <div className="mb-2">Text description</div>
            <div className="d-flex gap-1 justify-content-between align-items-center">
              <Styled.TextBoader>{data.textarea}</Styled.TextBoader>
            </div>
          </TextStyles.FontSize14px>
        </div>
      </Styled.ReviewBox>
      {editModal && (
        <EditAddSectionTextModal
          show={editModal}
          handleClose={() => setEditModal(false)}
          editData={data}
          position={position}
          pc_id={pc_id}
        />
      )}

      <RemoveModal
        title={"text"}
        confirmDialog={confirmDialog}
        showRemoveModal={showRemoveModal}
        handleCloseRemoveModal={handleCloseRemoveModal}
      />
    </div>
  );
};

export default Text;
