import { createSlice } from "@reduxjs/toolkit";
import {
  fetchClients,
  fetchClientsForOption,
  fetchClientForAProject,
  createClient,
  getClientById,
  archiveClient,
  unarchiveClient,
  editClient,
  deleteClient,
  createContact,
  editContact,
  insertNotes,
  deleteContact,
  fetchClientCounts,
} from "../service/clientService";

const clientState = {
  clientData: null,
  clientOptionData: null,
  clientsTotalCount: null,
  isLoading: false,
  error: null,
  c_data_position: null,
  clientForAProject: null,
};

export const clientSlice = createSlice({
  name: "clients",
  initialState: clientState,
  reducers: {
    clientDataAtPosition: (state, action) => {
      state.c_data_position = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch clients
      .addCase(fetchClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.clientData = action.payload.data.data;
        state.clientsTotalCount = action.payload.data.clientsTotalCount;
        state.isLoading = false;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch clients for option
      .addCase(fetchClientsForOption.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(fetchClientsForOption.fulfilled, (state, action) => {
        state.clientOptionData = action.payload.data;
        // state.isLoading = false;
      })
      .addCase(fetchClientsForOption.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch clients for a project
      .addCase(fetchClientForAProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClientForAProject.fulfilled, (state, action) => {
        state.clientForAProject = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchClientForAProject.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create client
      .addCase(createClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createClient.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // get client by ID
      .addCase(getClientById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientById.fulfilled, (state, action) => {
        state.c_data_position = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getClientById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // archive client
      .addCase(archiveClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(archiveClient.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(archiveClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // unarchive client
      .addCase(unarchiveClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unarchiveClient.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(unarchiveClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete client
      .addCase(deleteClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteClient.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit client
      .addCase(editClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editClient.fulfilled, (state, action) => {
        state.c_data_position = action.payload.data;
        state.isLoading = false;
      })
      .addCase(editClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create contact
      .addCase(createContact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.c_data_position.contacts = action.payload.data.contacts;
        state.isLoading = false;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit contact
      .addCase(editContact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editContact.fulfilled, (state, action) => {
        state.c_data_position.contacts = action.payload.data.contacts;
        state.isLoading = false;
      })
      .addCase(editContact.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete contact
      .addCase(deleteContact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.c_data_position.contacts = action.payload.data.contacts;
        state.isLoading = false;
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // insert notes
      .addCase(insertNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(insertNotes.fulfilled, (state, action) => {
        state.c_data_position.notes = action.payload.data.notes;
        state.isLoading = false;
      })
      .addCase(insertNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch client counts
      .addCase(fetchClientCounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClientCounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clientsTotalCount = action.payload.data.clientsTotalCount;
      })
      .addCase(fetchClientCounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const clientActions = clientSlice.actions;

export default clientSlice.reducer;
