import React, { useState, useEffect } from "react";
import TimerBox from "./TimerBox";
import TimerList from "./TimerList";
import { useDispatch, useSelector } from "react-redux";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import { updateUserTourSetupData } from "../../../../store/service/AuthService";

const steps = [
  {
    title: (
      <TextStyles.FontSize18px>Track your work time</TextStyles.FontSize18px>
    ),
    target: ".tract_your_time_tour",
    content: (
      <TextStyles.GreyFont14px className="d-flex text-start">
        Use the time tracking section to log hours worked on your projects. View
        detailed reports of your tracked time.
      </TextStyles.GreyFont14px>
    ),
    disableBeacon: true,
    placement: "bottom-start",
  },
];

const Timer = () => {
  const dispatch = useDispatch();

  // tour related coding
  const tourData = useSelector((state) => state.auth.userTourSetupData);
  const [run, setRun] = useState(true);

  useEffect(() => {
    setRun(tourData && tourData.tt_tour);
  }, [tourData]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
    if ([EVENTS.TOUR_END, EVENTS.SKIP].includes(type)) {
      dispatch(updateUserTourSetupData({ tt_tour: false }));
    }
  };

  return (
    <div>
      <TimerBox />
      <TimerList />
      <Joyride
        steps={steps}
        run={run}
        disableBeacon
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        scrollToFirstStep={false}
        callback={handleJoyrideCallback}
        scrollOffset={200}
        styles={{
          options: {
            primaryColor: Colors.primary,
            textColor: Colors.font1,
            zIndex: 50,
            overlayColor: "rgba(0, 0, 0, 0.2)",
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0)",
          },
          beacon: {
            zIndex: 50,
          },
          overlay: {
            zIndex: 50,
          },
          tooltipTitle: {
            fontSize: 18,
            margin: 0,
          },
          tooltipContent: {
            padding: "10px 0 0 0",
          },
        }}
      />
    </div>
  );
};

export default React.memo(Timer);
