import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../Constants/TextStyles";
import { updateNonSolicitPC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";

const NonSolicitModal = ({
  show,
  handleClose,
  heading,
  paragraph,
  selectedData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
    },
  });

  const formsubmit = (data) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      non_solicit: {
        required: true,
        heading: data.heading,
        paragraph: data.paragraph,
      },
    };
    dispatch(updateNonSolicitPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            Clause updated successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="ToMakeModalBorderRudius12px"
        scrollable={true}
        centered={true}
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          style={{ height: "100%" }}
          className="modal-content"
        >
          <Modal.Header className="d-flex justify-content-between align-items-center">
            <TextStyles.FontSize20px>Edit contract</TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="headline" className="mb-2">
                Headline
              </label>
              <TextStyles.InputRectangle
                placeholder={heading}
                id="headline"
                name="heading"
                {...register("heading")}
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="headline" className="mb-2">
                Paragraph
              </label>
              <TextStyles.Textarea
                rows={8}
                placeholder="Add text here"
                id="paragraph"
                name="paragraph"
                {...register("paragraph")}
              />
            </TextStyles.FontSize14px>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button disabled={isLoading}>
                {isLoading ? <LoaderSpin /> : "Update"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default NonSolicitModal;
