import { createSlice } from "@reduxjs/toolkit";
import {
  fetchInvoices,
  createInvoice,
  editInvoiceTitle,
  editInvoiceDates,
  editClientInfo,
  addLineItemService,
  editInvoiceService,
  editLineItemService,
  deleteLineItemService,
  addAndEditInvoiceTaxes,
  addAndEditInvoiceNotes,
  deleteInvoiceTax,
  addAndEditInvoiceDiscount,
  changeInvoiceCurrency,
  deleteInvoiceDiscount,
  deleteInvoiceNotes,
  createContact,
  emailSendInvoice,
  deleteInvoice,
  paidInvoice,
  fetchInvoiceCounts,
} from "../service/invoiceService";

const invoiceInitialState = {
  invoiceData: null,
  invoiceTotalCount: null,
  isLoading: false,
  error: null,
  selectedInvoice: null,
  contactData: null,
};

export const invoiceSlice = createSlice({
  name: "invoices",
  initialState: invoiceInitialState,
  reducers: {
    editInvoice: (state, action) => {
      state.selectedInvoice = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // create invoices
      .addCase(createInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedInvoice = action.payload.data;
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch invoices
      .addCase(fetchInvoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceData = action.payload.data.data;
        state.invoiceTotalCount = action.payload.data.invoiceTotalCount;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit invoice title
      .addCase(editInvoiceTitle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editInvoiceTitle.fulfilled, (state, action) => {
        state.selectedInvoice.invoice_title = action.payload.data.invoice_title;
        state.selectedInvoice.status = action.payload.data.status;
        state.isLoading = false;
      })
      .addCase(editInvoiceTitle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit invoice dates
      .addCase(editInvoiceDates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editInvoiceDates.fulfilled, (state, action) => {
        state.selectedInvoice.due_date = action.payload.data.due_date;
        state.selectedInvoice.issued = action.payload.data.issued;
        state.selectedInvoice.status = action.payload.data.status;
        state.isLoading = false;
      })
      .addCase(editInvoiceDates.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit client info
      .addCase(editClientInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editClientInfo.fulfilled, (state, action) => {
        state.selectedInvoice.client_id = action.payload.data.client_id;
        state.selectedInvoice.status = action.payload.data.status;
        state.isLoading = false;
      })
      .addCase(editClientInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit invoice services
      .addCase(editInvoiceService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editInvoiceService.fulfilled, (state, action) => {
        state.selectedInvoice.status = action.payload.data.status;
        state.selectedInvoice.invoice_services =
          action.payload.data.invoice_services;
        state.isLoading = false;
      })
      .addCase(editInvoiceService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit line item service
      .addCase(editLineItemService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editLineItemService.fulfilled, (state, action) => {
        state.selectedInvoice.status = action.payload.data.status;
        state.selectedInvoice.line_item_services =
          action.payload.data.line_item_services;
        state.isLoading = false;
      })
      .addCase(editLineItemService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete line item service
      .addCase(deleteLineItemService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteLineItemService.fulfilled, (state, action) => {
        state.selectedInvoice.status = action.payload.data.status;
        state.selectedInvoice.line_item_services =
          action.payload.data.line_item_services;
        state.isLoading = false;
      })
      .addCase(deleteLineItemService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // add line item service
      .addCase(addLineItemService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addLineItemService.fulfilled, (state, action) => {
        state.selectedInvoice.status = action.payload.data.status;
        state.selectedInvoice.line_item_services =
          action.payload.data.line_item_services;
        state.isLoading = false;
      })
      .addCase(addLineItemService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // add and edit invoice taxes
      .addCase(addAndEditInvoiceTaxes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAndEditInvoiceTaxes.fulfilled, (state, action) => {
        state.selectedInvoice.status = action.payload.data.status;
        state.selectedInvoice.taxes = action.payload.data.taxes;
        state.isLoading = false;
      })
      .addCase(addAndEditInvoiceTaxes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete invoice taxes
      .addCase(deleteInvoiceTax.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoiceTax.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedInvoice.status = action.payload.data.status;
        state.selectedInvoice.taxes = action.payload.data.taxes;
      })
      .addCase(deleteInvoiceTax.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // add and edit invoice discount
      .addCase(addAndEditInvoiceDiscount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAndEditInvoiceDiscount.fulfilled, (state, action) => {
        state.selectedInvoice.discount = action.payload.data.discount;
        state.selectedInvoice.status = action.payload.data.status;
        state.isLoading = false;
      })
      .addCase(addAndEditInvoiceDiscount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete invoice discount
      .addCase(deleteInvoiceDiscount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoiceDiscount.fulfilled, (state, action) => {
        state.selectedInvoice.discount = action.payload.data.discount;
        state.selectedInvoice.status = action.payload.data.status;
        state.isLoading = false;
      })
      .addCase(deleteInvoiceDiscount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // change invoice currency
      .addCase(changeInvoiceCurrency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeInvoiceCurrency.fulfilled, (state, action) => {
        state.selectedInvoice.status = action.payload.data.status;
        state.selectedInvoice.currency = action.payload.data.currency;
        state.isLoading = false;
      })
      .addCase(changeInvoiceCurrency.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit invoice notes
      .addCase(addAndEditInvoiceNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAndEditInvoiceNotes.fulfilled, (state, action) => {
        state.selectedInvoice.status = action.payload.data.status;
        state.selectedInvoice.invoice_notes = action.payload.data.invoice_notes;
        state.isLoading = false;
      })
      .addCase(addAndEditInvoiceNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete invoice notes
      .addCase(deleteInvoiceNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoiceNotes.fulfilled, (state, action) => {
        state.selectedInvoice.status = action.payload.data.status;
        state.selectedInvoice.invoice_notes = action.payload.data.invoice_notes;
        state.isLoading = false;
      })
      .addCase(deleteInvoiceNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create a contact for a specific client
      .addCase(createContact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.selectedInvoice.client_id.contacts = action.payload.data.contacts;
        state.isLoading = false;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // send invoices to client
      .addCase(emailSendInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(emailSendInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedInvoice.send_email = action.payload.data.send_email;
      })
      .addCase(emailSendInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete invoice
      .addCase(deleteInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // paid invoice
      .addCase(paidInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(paidInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(paidInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch invoice count
      .addCase(fetchInvoiceCounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoiceCounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceTotalCount = action.payload.data.invoiceTotalCount;
      })
      .addCase(fetchInvoiceCounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const invoiceActions = invoiceSlice.actions;

export default invoiceSlice.reducer;
