import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { createPC } from "../../../../../store/service/proposalAndContractService";

import { toast } from "react-toastify";

import { CustomDatePicker } from "../../../Commons/CustomDatePicker";
import { AutoCompleteSelectClient } from "../../../Commons/AutoComplete";

import BillingSchedule from "../../../projects/createProject/BillingSchedule";
import Services from "../../../projects/createProject/Services";

import Styled from "./SetupStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import SelectCurrency from "../../../Commons/SelectCurrency";
import SwitchButton from "../../../Commons/SwitchButton";
import Colors from "../../../../../Constants/Colors";
import moment from "moment";

export default function Setup(props) {
  const dispatch = useDispatch();
  const [selectedBoth, setSelectedBoth] = useState(
    props.btn === "Create proposal and contract" ? true : false
  );
  const [selectedProposal, setSelectedProposal] = useState(
    props.btn === "Create proposal" ? true : false
  );
  const [selectedContract, setSelectedContract] = useState(
    props.btn === "Create contract" ? true : false
  );

  const userCurrency = useSelector((state) => state.auth.userCurrency);
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const [selectEndDate, setSelectEndDate] = useState(false);

  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      servicesList: [
        {
          service_name: "",
          service_rate: "",
          service_rate_type: "Flat fee",
          desc: "",
        },
      ],
      project_start_date: new Date(),
      currency: userCurrency && userCurrency.currency,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesList",
  });

  const changedCurrency = watch("currency");
  const currencySymbol = changedCurrency
    ? changedCurrency.symbol
    : userCurrency && userCurrency.currency && userCurrency.currency.symbol;

  const [endDateErrorMessage, setEndDateErrorMessage] = useState(null);
  const startDate = watch("project_start_date");
  const endDate = watch("project_end_date");

  const validateEndDate = () => {
    const eDate = endDate && moment(endDate);
    const sDate = moment(startDate);
    if (eDate && eDate.isBefore(sDate, "day")) {
      setEndDateErrorMessage(
        `End date should be greater than ${moment(new Date(startDate))
          .subtract(1, "d")
          .format("DD MMM YYYY")}.`
      );
      return false;
    } else {
      setEndDateErrorMessage(null);
      return true;
    }
  };

  useEffect(() => {
    validateEndDate();
  }, [startDate, endDate]);

  useEffect(() => {
    setValue("project_end_date", null);
  }, [selectEndDate]);

  const formsubmit = async (data) => {
    const Obj = {
      project_name: data.project_name,
      client_id: data.client && data.client._id,
      project_start_date: data.project_start_date
        ? data.project_start_date
        : new Date(),
      project_end_date:
        selectEndDate || data.project_end_date === undefined
          ? null
          : data.project_end_date && new Date(data.project_end_date),
      currency: data.currency
        ? data.currency
        : userCurrency && userCurrency.currency,
      section: selectedBoth
        ? "Proposal and contract"
        : selectedContract
        ? "Contract"
        : "Proposal",
      service_list: data.servicesList,
      billing_scheduled: data.billingScheduled,
    };
    dispatch(createPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        reset();
        props.setPage(props.page + 1);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "createPro&ContractSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {" "}
            {data.error.message}
          </TextStyles.FontSize14px>,
          { toastId: "createPro&ContractFail" }
        );
      }
    });
  };

  return (
    <div className="d-flex justify-content-center mx-2">
      <div style={{ width: "100%", maxWidth: "650px", paddingTop: "2rem" }}>
        <form onSubmit={handleSubmit(formsubmit)}>
          <Styled.SetupBox className="mb-4">
            <TextStyles.FontSize20px
              className="d-flex justify-content-center justify-content-sm-start mb-3"
              style={{ fontWeight: 500 }}
            >
              I want to create a
            </TextStyles.FontSize20px>
            <div className="d-flex flex-wrap gap-3 justify-content-center justify-content-sm-start mb-3">
              <div
                onClick={() => {
                  props.BothHandler();
                  setSelectedBoth(true);
                  setSelectedProposal(false);
                  setSelectedContract(false);
                }}
              >
                {selectedBoth ? (
                  <Styled.Selected>Proposal and contract</Styled.Selected>
                ) : (
                  <Styled.NotSelected>Proposal and contract</Styled.NotSelected>
                )}
              </div>
              <div
                onClick={() => {
                  props.ProposalHandler();
                  setSelectedProposal(true);
                  setSelectedBoth(false);
                  setSelectedContract(false);
                }}
              >
                {selectedProposal ? (
                  <Styled.Selected>Proposal only</Styled.Selected>
                ) : (
                  <Styled.NotSelected> Proposal only</Styled.NotSelected>
                )}
              </div>
              <div
                onClick={() => {
                  props.ContractHandler();
                  setSelectedContract(true);
                  setSelectedBoth(false);
                  setSelectedProposal(false);
                }}
              >
                {selectedContract ? (
                  <Styled.Selected>Contract only</Styled.Selected>
                ) : (
                  <Styled.NotSelected>Contract only</Styled.NotSelected>
                )}
              </div>
            </div>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="project_name" className="mb-2">
                Project name
              </label>
              <TextStyles.InputRectangle
                name="project_name"
                id="project_name"
                invalid={errors.project_name}
                type="text"
                placeholder="Project name"
                {...register("project_name", {
                  required: "Project name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Project name is required.";
                    }
                    if (trimedLength < 3) {
                      return "Project name must be at least 3 characters";
                    }
                    if (trimedLength > 40) {
                      return "Project name must be at most 40 characters";
                    }
                    return true;
                  },
                })}
              />
              {errors.project_name && (
                <TextStyles.InValidFeedback>
                  {errors.project_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="client" className="mb-2">
                Select client
              </label>
              <Controller
                control={control}
                name="client"
                rules={{
                  required: {
                    value: true,
                    message: "Client is required.",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteSelectClient
                    placeholder="Choose a client"
                    options={
                      clientOptionData &&
                      clientOptionData.map((data) => {
                        return { label: data.company_name, _id: data._id };
                      })
                    }
                    onChange={onChange}
                    defaultValue={value}
                    invalid={errors.client}
                  />
                )}
              />
              {errors.client && (
                <TextStyles.InValidFeedback className="mt-2">
                  {errors.client.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="client" className="mb-2">
                Select currency
              </label>
              <Controller
                control={control}
                name="currency"
                render={({ field: { onChange, value } }) => (
                  <SelectCurrency
                    placeholder="Select currency"
                    onChange={onChange}
                    defaultValue={value}
                    // defaultValue={userCurrency && userCurrency.currency}
                  />
                )}
              />
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <div htmlFor="project_start_date" className="mb-2">
                Start date
              </div>

              <TextStyles.DateRectangle>
                <Controller
                  name={"project_start_date"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePicker
                        onChange={onChange}
                        selected={value}
                        placeholderText="Select start date"
                        todayButton="Today"
                        dateFormat="dd MMM yyyy"
                      />
                    );
                  }}
                />
              </TextStyles.DateRectangle>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div htmlFor="project_end_date">End date</div>
                <div className="d-flex align-items-center">
                  <TextStyles.FontSize14px
                    style={{ color: Colors.primary, fontWeight: 500 }}
                  >
                    Ongoing
                  </TextStyles.FontSize14px>
                  <SwitchButton
                    defaultChecked={selectEndDate}
                    onChange={(event) => setSelectEndDate(event.target.checked)}
                  />
                </div>
              </div>
              {selectEndDate ? (
                ""
              ) : (
                <TextStyles.DateRectangle invalid={endDateErrorMessage}>
                  <Controller
                    name={"project_end_date"}
                    control={control}
                    rules={{ validate: validateEndDate }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomDatePicker
                          onChange={onChange}
                          selected={value}
                          placeholderText="Select end date"
                          todayButton="Today"
                          dateFormat="dd MMM yyyy"
                          isClearable={true}
                          invalid={endDateErrorMessage}
                        />
                      );
                    }}
                  />
                </TextStyles.DateRectangle>
              )}
              {endDateErrorMessage && (
                <TextStyles.InValidFeedback className="mt-2">
                  {endDateErrorMessage}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          </Styled.SetupBox>
          {props.backBtn}
          <Services
            control={control}
            Controller={Controller}
            watch={watch}
            register={register}
            fields={fields}
            remove={remove}
            append={append}
            currencySymbol={currencySymbol}
          />
          <BillingSchedule
            currencySymbol={currencySymbol}
            register={register}
            watch={watch}
            setValue={setValue}
            Controller={Controller}
            control={control}
          />
          {props.children}
        </form>
      </div>
    </div>
  );
}
