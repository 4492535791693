import { createSlice } from "@reduxjs/toolkit";
import {
  createQuickTask,
  createTask,
  fetchTasks,
  editTask,
  editTaskDueDate,
  deleteTask,
  updateTaskStatus,
} from "../service/dashboardService";

const dashboardInitialState = {
  taskData: null,
  taskTotalCount: null,
  isLoading: false,
  error: null,
  refreshHeaderTask: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardInitialState,
  reducers: {
    updateNewCreatedTaskFromHeader: (state) => {
      state.refreshHeaderTask = !state.refreshHeaderTask;
    },
  },

  extraReducers: (builder) => {
    builder
      // create quick task
      .addCase(createQuickTask.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createQuickTask.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.taskData === null) {
          state.taskData = [action.payload.data];
        } else {
          state.taskData.unshift(action.payload.data);
        }
      })
      .addCase(createQuickTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create task
      .addCase(createTask.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.isLoading = false;
        // handle as needed
      })
      .addCase(createTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch tasks
      .addCase(fetchTasks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.taskData = action.payload.data.data;
        state.taskTotalCount = action.payload.data.taskTotalCount;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit task
      .addCase(editTask.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editTask.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(editTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit task due date
      .addCase(editTaskDueDate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editTaskDueDate.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(editTaskDueDate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete task
      .addCase(deleteTask.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTask.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // change task status
      .addCase(updateTaskStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTaskStatus.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTaskStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
