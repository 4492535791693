import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../Constants/TextStyles";
import { Modal } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import LoaderSpin from "../../Commons/LoaderSpin";
import { convertSecondsToHHMMSS } from "../../Commons/utils";
import moment from "moment";
import { updateWeekViewMultipleActivityTimes } from "../../../../store/service/timeTrackingService";
import Colors from "../../../../Constants/Colors";

const EditWeekviewMultipleActivityTimes = ({
  show,
  handleClose,
  selectedData,
  setUpdateRefresh,
  updateRefresh,
}) => {
  const isLoading = useSelector((state) => state.timeTracking.isLoading);
  const dispatch = useDispatch();
  const [inputError, setInputError] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    // clearErrors,
    // setError,
    getValues,
  } = useForm({
    defaultValues: {
      activityList:
        selectedData &&
        selectedData.map((item) => {
          return {
            _id: item._id,
            hours:
              item.start_time &&
              convertSecondsToHHMMSS(item.timesInSecs).hours.toString(),
            minutes:
              item.start_time &&
              convertSecondsToHHMMSS(item.timesInSecs).minutes.toString(),
            seconds:
              item.start_time &&
              convertSecondsToHHMMSS(item.timesInSecs).seconds.toString(),
          };
        }),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "activityList",
  });

  const singleFieldRequired = () => {
    if (getValues().activityList && getValues().activityList.length) {
      const { length } = getValues().activityList;

      for (let i = 0; i < length; i++) {
        let field = getValues().activityList[i];

        if (
          Number(field.hours) > 0 ||
          Number(field.minutes) > 0 ||
          Number(field.seconds) > 0
        ) {
          setInputError("");
          continue;
        } else {
          let errString = `Please provide hours or minutes or seconds on activity ${
            i + 1
          }`;

          setInputError(errString);
          return errString;
        }
      }
    }
  };

  // const validateAtLeastOneField = () => {
  //   const values = getValues("activityList");
  //   for (const [index, value] of values.entries()) {
  //     if (value.hours > 0 || value.minutes > 0 || value.seconds > 0) {
  //       // Clear errors for this index
  //       // clearErrors(`activityList.${index}.hours`);
  //       // clearErrors(`activityList.${index}.minutes`);
  //       // clearErrors(`activityList.${index}.seconds`);
  //       clearErrors(`activityList.${index}`);
  //       return true;
  //     } else {
  //       if (!errors.activityList || !errors.activityList[index]) {
  //         setError(`activityList.${index}`, {
  //           type: "manual",
  //           message: "At least one field must be filled",
  //         });
  //       }

  //       // setError(`activityList.${index}.hours`, {
  //       //   type: "manual",
  //       //   message: "At least one field must be filled",
  //       // });
  //       // setError(`activityList.${index}.minutes`, {
  //       //   type: "manual",
  //       //   message: "At least one field must be filled",
  //       // });
  //       // setError(`activityList.${index}.seconds`, {
  //       //   type: "manual",
  //       //   message: "At least one field must be filled",
  //       // });
  //       // return false;
  //     }
  //   }
  // };

  const formsubmit = (data) => {
    const updateSelectedActivities =
      data &&
      data.activityList.map((d) => {
        const hours = parseFloat(d.hours) || 0;
        const minutes = parseFloat(d.minutes) || 0;
        const seconds = parseFloat(d.seconds) || 0;
        const timesInSecs = hours * 60 * 60 + minutes * 60 + seconds;
        const _id = d._id;
        return { _id, timesInSecs };
      });
    const Obj = {
      updateSelectedActivities,
    };
    dispatch(updateWeekViewMultipleActivityTimes(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={selectedData && selectedData.length > 5 ? true : false}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex gap-3 align-items-center">
          <TextStyles.FontSize20px
            style={{ display: "flex", width: "100%", minWidth: "10rem" }}
          >
            <span className="three-dots">
              {selectedData && selectedData.length > 1
                ? `Edit ${selectedData.length} activities for ${moment(
                    selectedData[0].start_time
                  ).format("DD MMM YYYY")}`
                : "Edit activity"}
            </span>
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>

        <Modal.Body className="modal-body">
          {fields &&
            fields.map((data, index) => {
              let diff = new Date(data.end_time) - new Date(data.start_time);
              return (
                <TextStyles.FontSize14px className="mb-3" key={index}>
                  {data && data.client_id && data.project_id ? (
                    <div className="mb-2">
                      <span>
                        {data &&
                          data.project_id &&
                          data.project_id.project_name}
                      </span>
                      <span>
                        {data &&
                          data.client_id &&
                          `(${data.client_id.company_name})`}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex gap-3 flex-wrap justify-content-start align-items-center">
                    <div className="d-flex justify-content-around align-items-center">
                      <div
                        className="d-flex align-items-center"
                        style={{
                          border: `1px solid ${Colors.borderInput}`,
                          padding: "0 1rem",
                          borderRadius: "0.75rem",
                          height: "3.15rem",
                        }}
                      >
                        <input
                          type="number"
                          style={{ width: 25 }}
                          name="hours"
                          min={0}
                          max={23}
                          placeholder="00"
                          defaultValue={convertSecondsToHHMMSS(
                            diff
                          ).hours.toString()}
                          {...register(`activityList.${index}.hours`, {
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Please enter valid number for hours.",
                            },
                            validate: singleFieldRequired,
                          })}
                        />
                        <div className="ms-2">hrs</div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around align-items-center">
                      <div
                        className="d-flex align-items-center"
                        style={{
                          border: `1px solid ${Colors.borderInput}`,
                          padding: "0 1rem",
                          borderRadius: "0.75rem",
                          height: "3.15rem",
                        }}
                      >
                        <input
                          type="number"
                          style={{ width: 25 }}
                          name="minutes"
                          min={0}
                          max={59}
                          placeholder="00"
                          defaultValue={convertSecondsToHHMMSS(
                            diff
                          ).minutes.toString()}
                          {...register(`activityList.${index}.minutes`, {
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Please enter valid number for minutes.",
                            },
                            validate: singleFieldRequired,
                          })}
                        />
                        <div className="ms-2">mins</div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around align-items-center">
                      <div
                        className="d-flex align-items-center"
                        style={{
                          border: `1px solid ${Colors.borderInput}`,
                          padding: "0 1rem",
                          borderRadius: "0.75rem",
                          height: "3.15rem",
                        }}
                      >
                        <input
                          type="number"
                          style={{ width: 25 }}
                          placeholder="00"
                          min={0}
                          max={59}
                          name="seconds"
                          defaultValue={convertSecondsToHHMMSS(
                            diff
                          ).seconds.toString()}
                          {...register(`activityList.${index}.seconds`, {
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Please enter valid number for seconds.",
                            },
                            validate: singleFieldRequired,
                          })}
                        />
                        <div className="ms-2">sec</div>
                      </div>
                    </div>
                  </div>

                  {/* {(errors.activityList &&
                    errors.activityList[index] &&
                    errors.activityList[index].hours && (
                      <TextStyles.InValidFeedback>
                        {errors.activityList[index].hours.message}
                      </TextStyles.InValidFeedback>
                    )) ||
                    (errors.activityList &&
                      errors.activityList[index] &&
                      errors.activityList[index].minutes && (
                        <TextStyles.InValidFeedback>
                          {errors.activityList[index].minutes.message}
                        </TextStyles.InValidFeedback>
                      )) ||
                    (errors.activityList &&
                      errors.activityList[index] &&
                      errors.activityList[index].seconds && (
                        <TextStyles.InValidFeedback>
                          {errors.activityList[index].seconds.message}
                        </TextStyles.InValidFeedback>
                      ))} */}
                  {/* {console.log(errors)} */}
                  {/* {errors.activityList && errors.activityList[index] && (
                    <TextStyles.InValidFeedback>
                      {errors.activityList[index].message}
                    </TextStyles.InValidFeedback>
                  )} */}

                  {/* {errors.activityList &&
                    errors.activityList[index] &&
                    errors.activityList[index].hours && (
                      <TextStyles.InValidFeedback>
                        {errors.activityList[index].hours.message}
                      </TextStyles.InValidFeedback>
                    )}

                  {errors.activityList &&
                    errors.activityList[index] &&
                    errors.activityList[index].minutes && (
                      <TextStyles.InValidFeedback>
                        {errors.activityList[index].minutes.message}
                      </TextStyles.InValidFeedback>
                    )}
                  {errors.activityList &&
                    errors.activityList[index] &&
                    errors.activityList[index].seconds && (
                      <TextStyles.InValidFeedback>
                        {errors.activityList[index].seconds.message}
                      </TextStyles.InValidFeedback>
                    )} */}
                </TextStyles.FontSize14px>
              );
            })}

          {errors.activityList && (
            <TextStyles.InValidFeedback>
              {inputError}
            </TextStyles.InValidFeedback>
          )}
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block", padding: "6px 12px" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditWeekviewMultipleActivityTimes;
