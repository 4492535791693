import React from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ClearIcon from "@mui/icons-material/Clear";
import { createContact } from "../../../../store/service/invoiceService";
import LoaderSpin from "../../Commons/LoaderSpin";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

const AddContactModal = ({ show, handleClose, contact, setContact }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.invoices.isLoading);
  const selectedInvoice = useSelector(
    (state) => state.invoices.selectedInvoice
  );
  const {
    register,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm();

  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[A-Za-z0-9+._]+@[A-Za-z0-9]+\.[A-Za-z]{2}/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const formsubmit = (item) => {
    const Obj = {
      client_id:
        selectedInvoice &&
        selectedInvoice.client_id &&
        selectedInvoice.client_id._id,
      contact_name: item.contact_name,
      contact_email: item.email,
    };

    dispatch(createContact(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setContact([
          ...contact,
          data.payload.data.contacts[data.payload.data.contacts.length - 1],
        ]);
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div>
      <Modal
        scrollable
        show={show}
        onHide={handleClose}
        contentClassName="ToMakeModalBorderRudius12px"
        centered
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center justify-content-between">
            <TextStyles.FontSize20px>Add contact</TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="contact_name" className="mb-2">
                Name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.contact_name}
                id="contact_name"
                placeholder="Name"
                name="contact_name"
                {...register(`contact_name`, {
                  required: "Contact can't be empty.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Contact can't be empty.";
                    }
                    return true;
                  },
                })}
              />
              {errors.contact_name && (
                <TextStyles.InValidFeedback>
                  {errors.contact_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="email" className="mb-2">
                Email
              </label>
              <TextStyles.InputRectangle
                invalid={errors.email}
                id="email"
                placeholder="Email"
                name="email"
                {...register("email", {
                  required: "Email is required.",
                  validate: validateEmail,
                  onBlur: (e) => setValue("email", e.target.value.trim()),
                })}
                // {...register(`email`, {
                //   required: "Email can't be empty.",
                //   pattern: {
                //     value: /^[A-Za-z0-9+._]+@[A-Za-z0-9]+\.[A-Za-z]{2}/i,
                //     message: "Please enter valid email address.",
                //   },
                // })}
              />
              {errors.email && (
                <TextStyles.InValidFeedback>
                  {errors.email.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button
                disabled={!isDirty || !isValid || isLoading ? true : false}
              >
                {isLoading ? <LoaderSpin /> : "Add contact"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default AddContactModal;
