import { createSlice } from "@reduxjs/toolkit";
import {
  fetchExpenses,
  fetchIncomes,
  fetchTaxes,
} from "../service/statementService";

const statementsInitialState = {
  isLoading: false,
  error: null,
  incomesData: null,
  expensesData: null,
  taxesData: null,
};

export const statementSlice = createSlice({
  name: "statements",
  initialState: statementsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch incomes
      .addCase(fetchIncomes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchIncomes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.incomesData = action.payload.data;
      })
      .addCase(fetchIncomes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch expenses
      .addCase(fetchExpenses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExpenses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.expensesData = action.payload.data;
      })
      .addCase(fetchExpenses.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch taxes
      .addCase(fetchTaxes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTaxes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.taxesData = action.payload.data;
      })
      .addCase(fetchTaxes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const statementsActions = statementSlice.actions;

export default statementSlice.reducer;
