import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateAcceptancePC } from "../../../../../../store/service/proposalAndContractService";
import AcceptanceModal from "../../PageModals/AcceptanceModal";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

const Acceptance = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData && selectedData.acceptance && selectedData.acceptance.heading
      ? selectedData &&
        selectedData.acceptance &&
        selectedData.acceptance.heading
      : "Acceptance";
  const paragraph =
    selectedData && selectedData.acceptance && selectedData.acceptance.paragraph
      ? selectedData &&
        selectedData.acceptance &&
        selectedData.acceptance.paragraph
      : "The Client's authorized representative possesses the authority to enter into this Agreement on behalf of the Client. The Client commits to cooperating and providing the Contractor with all necessary resources to complete the Services as requested, including the specified format and timeline. The Contractor affirms their expertise and capability to fulfill all agreed-upon tasks for the Client in a professional and timely manner. They will strive to meet all established deadlines and exceed the Client's expectations for the Services to the best of their abilities.";

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      acceptance: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateAcceptancePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.acceptance &&
          selectedData.acceptance.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <AcceptanceModal
          selectedData={selectedData}
          paragraph={paragraph}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default Acceptance;
