import { createSlice } from "@reduxjs/toolkit";
import {
  createTransaction,
  fetchTransactions,
  fetchTransactionsForGraph,
  deleteTransaction,
  editTransaction,
  fetchTransactionCounts,
} from "../service/transactionService";

const initialTransactionState = {
  transaction: null,
  trTotalCount: null,
  trDataForGraph: null,
  isLoading: false,
  error: null,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState: initialTransactionState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // fetch transactions
      .addCase(fetchTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transaction = action.payload.data.data;
        state.trTotalCount = action.payload.data.trTotalCount;
        state.isLoading = false;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch transactions for graph
      .addCase(fetchTransactionsForGraph.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(fetchTransactionsForGraph.fulfilled, (state, action) => {
        state.trDataForGraph = action.payload.data;
        // state.isLoading = false;
      })
      .addCase(fetchTransactionsForGraph.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })

      // add expenses (create transaction)
      .addCase(createTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTransaction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // delete transaction
      .addCase(deleteTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTransaction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // edit transaction
      .addCase(editTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editTransaction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(editTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // fetch transaction counts
      .addCase(fetchTransactionCounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTransactionCounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.trTotalCount = action.payload.data.trTotalCount;
      })
      .addCase(fetchTransactionCounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const trAction = transactionSlice.actions;
export default transactionSlice.reducer;
