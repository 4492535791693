import moment from "moment";
import { useState, useEffect } from "react";
import Constant from "../Commons/Constant";
import { getRequest } from "../../../axios/axios";

export const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return size;
};

export const diffDate = (end_date, start_date) => {
  let endDate = moment(end_date).format("DD MMM YYYY");
  let startDate = moment(start_date).format("DD MMM YYYY");
  let diff = moment(moment(new Date(endDate)).add(1, "d")).diff(
    new Date(startDate),
    "d"
  );
  let days;
  if (diff === 1) {
    days = "1 day";
  } else if (diff === -1) {
    days = "- 1 day";
  } else if (diff === 0) {
    days = "0 day";
  } else {
    days = `${diff} days`;
  }
  return days;
};

export const diffHours = (start_date, end_date) => {
  let edate = new Date(end_date) || new Date();
  let diff = Math.abs(edate - new Date(start_date));
  let days = diff / (1000 * 60 * 60);
  return days;
};

export const diffWeek = (start_date, end_date) => {
  let diff = Math.abs(new Date(end_date) - new Date(start_date));
  let weeks = Math.ceil(diff / (1000 * 60 * 60 * 24 * 7));
  return weeks;
};

export const getFileFromUrl = async (url, name) => {
  try {
    let response = await fetch(url);
    let data = await response.blob();
    const file = new File([data], name, {
      type: data.type,
    });
    return file;
  } catch (error) {
    console.log(error);
  }
};

export const convertSecondsToHHMMSS = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return {
    hours,
    minutes,
    seconds,
    formattedTime: `${formattedHours}:${formattedMinutes}:${formattedSeconds}`,
  };
};

export const convertSecondsToHHMM = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return {
    hours,
    minutes,
    formattedTime: `${hours >= 1 ? formattedHours : "0"} ${
      hours > 1 ? "hrs" : hours === 1 ? "hr" : "hr"
    } ${minutes > 0 ? formattedMinutes : ""} ${
      minutes > 1 ? "mins" : minutes === 1 ? "min" : ""
    }`,
  };
};

export const getCountryNameByUserLocation = async () => {
  try {
    const { data } = await getRequest(
      `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`
    );
    const countryCode = data && data.country;
    const country = Constant.countryOptions.find(
      (data) => data.code === countryCode
    );
    return country;
  } catch (error) {
    console.log("Error in fetching country code:", error);
  }
};
