import styled from "styled-components";
import Colors from "../../../Constants/Colors";

const BackButtonOuterCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.437rem;
  height: 3.437rem;
  border-radius: 50%;
  background-color: #ddd3ed;
  animation: shadowOfCircle ease-in-out 900ms alternate infinite;

  @keyframes shadowOfCircle {
    from {
      width: 2.5rem;
      height: 2.5rem;
    }
    to {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
`;

const BackButtonCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${Colors.white};
  border: 2px solid ${Colors.primary};
  :hover {
    background-color: ${Colors.primary};
  }
  :hover .backBtn {
    filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(225deg)
      brightness(255%) contrast(102%);
  }
`;

export default {
  BackButtonOuterCircle,
  BackButtonCircle,
};
