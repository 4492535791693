export default {
  primary: "#6c3e83",
  // font1: "#1a1a1a",
  // font2: "#7d7e82",
  font1: '#222',
  font2: '#444',
  borderInput: "#d1d1d1",
  backgroundGrey: "#f6f7fb",
  white: "#ffffff",
  tableHeader: "#ebebef",
  red: "#dc3545",
  backgroundPink: "#f2ecfb",
  hoverTableRow: '#f8f4ff',
  success: "#33cc33",
  tableHeadColor: "#f1f1f1",
  inputErrorBg: "#ffd9d9",
  hoverButtonColor: "#5b336f",
  hoverDeleteBtn: "#e66545",
  bgColorFrontend: "#2c1338",
  brandColorFrontend: "#e57cd8",
  frontendFont1: "#412a4c",
  frontendFont2: "#6b5a74",
};
