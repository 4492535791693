import React from "react";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    fontWeight: "400 !important",
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    height: "2.75rem !important",

    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: Colors.font1,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const projectStatusOptions = [
  {
    id: "1",
    value: "All statuses",
    label: "All statuses",
  },
  {
    id: "2",
    value: "Running",
    label: "Active",
  },
  {
    id: "3",
    value: "Upcoming",
    label: "Upcoming",
  },
  {
    id: "4",
    value: "Ended",
    label: "Ended",
  },
  {
    id: "5",
    value: "Archived",
    label: "Archived",
  },
];

export default function SelectProjectStatus(props) {
  const classes = useStyles();

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        value={props.defaultValue ? props.defaultValue : "Running"}
        variant="outlined"
        onChange={props.onChange}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
          disableScrollLock: true,
        }}
      >
        {projectStatusOptions &&
          projectStatusOptions.map((data, index) => (
            <MenuItem
              key={index}
              value={data.value}
              className={classes.listMenu}
            >
              <FiberManualRecordIcon
                sx={{
                  display: data.label === "All statuses" ? "none" : "",
                  color:
                    data.label === "Active"
                      ? "#188652"
                      : data.label === "Upcoming"
                      ? "#f09528"
                      : data.label === "Ended"
                      ? "#f74040"
                      : data.label === "Archived"
                      ? "#c3c3c6"
                      : "#ffffff",
                  fontSize: 15,
                  margin: "0 6px 0 0",
                }}
              />
              {data.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
