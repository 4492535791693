import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateGoverningLawPC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import GoverningLawModal from "../../PageModals/GoverningLawModal";
import Colors from "../../../../../../Constants/Colors";

const GoverningLaw = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData &&
    selectedData.governingLaw &&
    selectedData.governingLaw.heading
      ? selectedData &&
        selectedData.governingLaw &&
        selectedData.governingLaw.heading
      : "Governing Law and Dispute Resolution";

  const paragraph =
    selectedData &&
    selectedData.governingLaw &&
    selectedData.governingLaw.paragraph
      ? selectedData.governingLaw.paragraph
      : `This Agreement and any disputes arising hereunder shall be governed by the laws of Contractor's primary business location (the "Contractor's Jurisdiction"), without considering conflicts of law provisions. The Parties hereby consent to exclusive jurisdiction and venue in the courts situated in Contractor's jurisdiction.\n\nThe failure of either party to enforce its rights under this Agreement at any time shall not be deemed as a waiver of such rights.`;

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      governingLaw: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateGoverningLawPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.governingLaw &&
          selectedData.governingLaw.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <GoverningLawModal
          selectedData={selectedData}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
          paragraph={paragraph}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default GoverningLaw;
