import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import { updateRetainRightsPC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import SwitchButton from "../../../Commons/SwitchButton";

const AuthorshipCreditRightModal = ({
  show,
  handleClose,
  heading,
  selectedData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const [selector, setSelector] = useState(
    selectedData &&
      selectedData.rightToAuthorship &&
      selectedData.rightToAuthorship.retain_rights === true
      ? true
      : false
  );

  const paragraph =
    selector === true
      ? `The parties acknowledge that the Contractor is the creator of the deliverables. The Client is not obligated to proactively display the Contractor's name alongside the deliverables but must not mislead others by attributing the creation of the deliverables to anyone other than the Contractor. The Client agrees that the Contractor may use the work product as part of their portfolio, websites, galleries, and other media solely for the purpose of showcasing the Contractor's work, without any other purpose.\n\nThe Contractor shall not publish any confidential or non-public work without obtaining the prior written consent of the Client.`
      : `Both parties mutually agree that the Client must appropriately acknowledge the Contractor as the creator of the deliverables when requested. The Client is not obligated to proactively display the Contractor's name alongside the deliverables, but the Client must not mislead others by attributing the creation of the deliverables to anyone other than the Contractor.\n\nThe Contractor shall refrain from publishing any confidential or non-public work without obtaining the prior written consent of the Client.`;

  const { register, reset, handleSubmit, setValue } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
    },
  });

  useEffect(() => {
    setValue("paragraph", paragraph);
  }, [selector]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      rightToAuthorship: {
        required: true,
        heading: item.heading,
        paragraph: item.paragraph,
        retain_rights: selector === true ? true : false,
      },
    };

    dispatch(updateRetainRightsPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            Clause updated successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder={heading}
              id="headline"
              name="heading"
              // style={{ opacity: 0.5 }}
              {...register("heading")}
              // readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              rows={8}
              // style={{ opacity: 0.5 }}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              // readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize18px className="mb-3">
            Options
          </TextStyles.FontSize18px>
          <TextStyles.GreyFont14px className="d-flex gap-3 justify-content-between align-items-center">
            <div className="d-flex">
              Do you wish to retain the right to include work attribution in
              your portfolio?
            </div>
            <div className="d-flex justify-content-end">
              <SwitchButton
                defaultChecked={selector}
                onChange={(event) => setSelector(event.target.checked)}
              />
            </div>
          </TextStyles.GreyFont14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AuthorshipCreditRightModal;
