import React, { useState } from "react";
import { Popover } from "antd";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import TodayIcon from "@mui/icons-material/Today";
import Colors from "../../../Constants/Colors";
import styled from "styled-components";
import TextStyles from "../../../Constants/TextStyles";
import dayjs from "dayjs";
import "./selectDate.css";

import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const Sorting = styled.div`
  display: flex;
  min-width: 10rem;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  border-radius: 6px;
  cursor: pointer;
  background: ${(props) =>
    props.isActive ? `${Colors.backgroundPink}` : Colors.white};
  &:hover {
    background: ${Colors.backgroundPink};
  }
`;

const PopoverBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.812rem;
  font-size: 0.875rem;
  font-family: Poppins, serif;
  border-radius: 6px;
  border: 1px solid lightgrey;
  background: ${Colors.white};

  &:hover {
    border: 1px solid ${Colors.font2};
  }
`;

const SelectDate = (props) => {
  const [openDateSelector, setOpenDateSelector] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const hideSortingHandler = () => {
    setOpenDateSelector(false);
  };

  const handleOpenSortingChange = (newOpen) => {
    setOpenDateSelector(newOpen);
  };

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px
        style={{
          maxHeight: 400,
          padding: "20px 0",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Sorting
          isActive={props.defaultValue === "All time" ? true : false}
          onClick={() => {
            props.onChange("All time");
            hideSortingHandler();
          }}
        >
          All time
        </Sorting>
        <Sorting
          isActive={props.defaultValue === "Last 7 days" ? true : false}
          onClick={() => {
            props.onChange("Last 7 days");
            hideSortingHandler();
          }}
        >
          Last 7 days
        </Sorting>
        <Sorting
          isActive={props.defaultValue === "Last 15 days" ? true : false}
          onClick={() => {
            props.onChange("Last 15 days");
            hideSortingHandler();
          }}
        >
          Last 15 days
        </Sorting>

        <Sorting
          isActive={props.defaultValue === "Last 30 days" ? true : false}
          onClick={() => {
            props.onChange("Last 30 days");
            hideSortingHandler();
          }}
        >
          Last 30 days
        </Sorting>
        <Sorting
          isActive={props.defaultValue === "Last 45 days" ? true : false}
          onClick={() => {
            props.onChange("Last 45 days");
            hideSortingHandler();
          }}
        >
          Last 45 days
        </Sorting>

        <Sorting
          isActive={props.defaultValue === "Last 60 days" ? true : false}
          onClick={() => {
            props.onChange("Last 60 days");
            hideSortingHandler();
          }}
        >
          Last 60 days
        </Sorting>
        <Sorting
          isActive={props.defaultValue === "Last 90 days" ? true : false}
          onClick={() => {
            props.onChange("Last 90 days");
            hideSortingHandler();
          }}
        >
          Last 90 days
        </Sorting>
        <Sorting
          isActive={props.defaultValue === "This month" ? true : false}
          onClick={() => {
            props.onChange("This month");
            hideSortingHandler();
          }}
        >
          This month
        </Sorting>
        <Sorting
          isActive={props.defaultValue === "Last month" ? true : false}
          onClick={() => {
            props.onChange("Last month");
            hideSortingHandler();
          }}
        >
          Last month
        </Sorting>

        <Sorting
          isActive={props.defaultValue === "This year" ? true : false}
          onClick={() => {
            props.onChange("This year");
            hideSortingHandler();
          }}
        >
          This year
        </Sorting>

        <Sorting
          isActive={props.defaultValue === "Last year" ? true : false}
          onClick={() => {
            props.onChange("Last year");
            hideSortingHandler();
          }}
        >
          Last year
        </Sorting>

        <Sorting
          className="position-relative d-none d-sm-flex"
          isActive={Array.isArray(props.defaultValue) ? true : false}
        >
          <div
            onClick={() => {
              setOpenDatePicker(true);
            }}
          >
            Choose date range ...
          </div>
          {openDatePicker && (
            <RangePicker
              open={openDatePicker}
              className="custom-range-picker"
              popupClassName="custom-range-picker-popup"
              onOpenChange={(open) => setOpenDatePicker(open)}
              onChange={(date, value) => {
                setOpenDatePicker(false);
                props.onChange(value);
                handleOpenSortingChange();
              }}
            />
          )}
        </Sorting>
      </TextStyles.FontSize14px>
    );
  };

  return (
    <PopoverBox>
      <Popover
        placement="bottomRight"
        content={sortingContent}
        trigger="click"
        open={openDateSelector}
        onOpenChange={handleOpenSortingChange}
        overlayInnerStyle={{
          padding: 0,
        }}
      >
        <div
          className="px-2"
          style={{
            cursor: "pointer",
            width: "100%",
            height: "2.75rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TodayIcon
            sx={{ color: Colors.primary, fontSize: 20, marginRight: 1 }}
          />
          {props.defaultValue && typeof props.defaultValue === "object"
            ? `${dayjs(props.defaultValue[0]).format("DD MMM YYYY")} ~
            ${dayjs(props.defaultValue[1]).format("DD MMM YYYY")}`
            : props.defaultValue && typeof props.defaultValue === "string"
            ? props.defaultValue
            : "All time"}
          <ArrowDropDownOutlinedIcon
            sx={{
              color: Colors.font2,
              fontSize: 27,
              transform: openDateSelector ? "rotate(180deg)" : "",
            }}
          />
        </div>
      </Popover>
    </PopoverBox>
  );
};

export default SelectDate;
