import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TodayIcon from "@mui/icons-material/Today";

import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import "./CustomDatePicker.css";
import TextStyles from "../../../Constants/TextStyles";
import Colors from "../../../Constants/Colors";

export const CustomDatePicker = (props) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={onClick}
        ref={ref}
      >
        <div
          style={{ color: props.invalid ? Colors.red : Colors.font1 }}
          className="three-dots w-100"
        >
          {value ? value : props.placeholderText}
        </div>
        <div>
          <TodayIcon
            className="react_date_picker_Icon"
            sx={{
              color: Colors.primary,
            }}
            fontSize="medium"
          />
        </div>
      </div>
    );
  });

  return (
    <DatePicker
      selected={props.selected ? props.selected : null}
      onChange={props.onChange}
      placeholderText={props.placeholderText}
      isClearable={props.isClearable}
      disabled={props.disabled}
      shouldCloseOnSelect
      todayButton="Today"
      dropdownMode="select"
      dateFormat={props.dateFormat}
      clearText="Clear"
      minDate={props.minDate}
      maxDate={props.maxDate}
      customInput={<ExampleCustomInput />}
    />
  );
};

export const CustomTimePicker = (props) => {
  return (
    <DatePicker
      className={props.className}
      selected={props.selected ? props.selected : null}
      readOnly={props.readOnly}
      onChange={props.onChange}
      disabled={props.disabled}
      placeholderText={props.placeholderText}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={props.timeIntervals}
      timeCaption="Time"
      dateFormat="h:mm aa"
    />
  );
};

export const CustomDatePicker2 = (props) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <div
        className="d-flex justify-content-between px-2 py-3 align-items-center"
        style={{ cursor: "pointer" }}
        onClick={onClick}
        ref={ref}
      >
        <div className="d-flex flex-column three-dots w-100">
          <TextStyles.FontSize14px
            className="text-start three-dots w-100"
            style={{ fontWeight: 500 }}
          >
            {props.title}
          </TextStyles.FontSize14px>
          <TextStyles.GreyFont14px className="text-start mt-1 three-dots w-100">
            {value ? value : props.placeholderText}
          </TextStyles.GreyFont14px>
        </div>
        <div className="text-end three-dots w-100" style={{ maxWidth: 30 }}>
          <DateRangeOutlinedIcon sx={{ color: Colors.primary, fontSize: 27 }} />
        </div>
      </div>
    );
  });

  return (
    <DatePicker
      selected={props.selected ? props.selected : null}
      onChange={props.onChange}
      placeholderText={props.placeholderText}
      title={props.title}
      isClearable={props.isClearable}
      disabled={props.disabled}
      shouldCloseOnSelect
      todayButton="Today"
      dropdownMode="select"
      dateFormat={props.dateFormat}
      clearText="Clear"
      minDate={props.minDate}
      maxDate={props.maxDate}
      customInput={<ExampleCustomInput />}
    />
  );
};
