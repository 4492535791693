import React from "react";
import { useForm, Controller } from "react-hook-form";

import LoaderSpin from "../../../Commons/LoaderSpin";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  createClient,
  fetchClientsForOption,
} from "../../../../../store/service/clientService";
import Styled from "../../../projects/createProject/SetupStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import SelectCountry from "../../../Commons/SelectCountry";

const CreateClient = ({
  setChooseQuickClient,
  setCreateQuickClient,
  backBtn,
}) => {
  const dispatch = useDispatch();

  const {
    register,
    reset,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const isLoading = useSelector((state) => state.clients.isLoading);
  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[A-Za-z0-9+._]+@[A-Za-z0-9]+\.[A-Za-z]{2}/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const formSubmit = async (data) => {
    const Obj = {
      contact_email: data.email,
      company_name: data.company,
      contact_name: data.name,
      street: data.street,
      suite: data.suite,
      city: data.city,
      zip_code: data.zipcode,
      country: data.country ? data.country.name : null,
      state: data.state,
    };

    dispatch(createClient(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setChooseQuickClient(data.payload.data);
        setCreateQuickClient(false);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
        reset();
        dispatch(fetchClientsForOption());
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          {
            toastId: "fail",
          }
        );
      }
    });
  };
  return (
    <div className="d-flex justify-content-center px-2">
      <div style={{ maxWidth: 600, width: "100%" }}>
        <TextStyles.FontSize27px className="text-center mb-3">
          Create client
        </TextStyles.FontSize27px>
        <Styled.SetupBox className=" mb-4">
          {" "}
          <form onSubmit={handleSubmit(formSubmit)}>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="compnay_name" className="mb-2">
                Company name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.company}
                id="company_name"
                type="text"
                placeholder="Company name"
                name="company"
                {...register("company", {
                  required: "Company name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Company name is required.";
                    }
                    return true;
                  },
                })}
              />
              {errors.company && (
                <TextStyles.InValidFeedback>
                  {errors.company.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="contact_name" className="mb-2">
                Contact name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.name}
                id="contact_name"
                type="text"
                placeholder="Contact name"
                name="name"
                {...register("name", {
                  required: "Name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Name is required.";
                    }
                    return true;
                  },
                })}
              />
              {errors.name && (
                <TextStyles.InValidFeedback>
                  {errors.name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="contact_email" className="mb-2">
                Contact email
              </label>
              <TextStyles.InputRectangle
                invalid={errors.email}
                id="contact_email"
                type="email"
                placeholder="Contact email"
                name="email"
                {...register("email", {
                  required: "Email is required.",
                  validate: validateEmail,
                  onBlur: (e) => setValue("email", e.target.value.trim()),
                })}
              />
              {errors.email && (
                <TextStyles.InValidFeedback>
                  {errors.email.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <div className="row">
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="street" className="mb-2">
                    Street
                  </label>
                  <TextStyles.InputRectangle
                    id="street"
                    type="text"
                    placeholder="Street"
                    name="street"
                    {...register("street")}
                  />
                </TextStyles.FontSize14px>
              </div>
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="suite" className="mb-2">
                    Suite
                  </label>
                  <TextStyles.InputRectangle
                    id="suite"
                    type="text"
                    placeholder="Suite"
                    name="suite"
                    {...register("suite")}
                  />
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="city" className="mb-2">
                    City
                  </label>
                  <TextStyles.InputRectangle
                    id="city"
                    type="text"
                    placeholder="City"
                    name="city"
                    {...register("city")}
                  />
                </TextStyles.FontSize14px>
              </div>
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="zipcode" className="mb-2">
                    Zip code
                  </label>
                  <TextStyles.InputRectangle
                    id="zipcode"
                    type="text"
                    placeholder="Zip code"
                    name="zipcode"
                    {...register("zipcode")}
                  />
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="state" className="mb-2">
                    State
                  </label>
                  <TextStyles.InputRectangle
                    id="state"
                    type="text"
                    placeholder="State"
                    name="state"
                    {...register("state")}
                  />
                </TextStyles.FontSize14px>
              </div>
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="country" className="mb-2">
                    Country
                  </label>
                  <div>
                    <Controller
                      control={control}
                      id="country"
                      name="country"
                      render={({ field: { onChange, value } }) => (
                        <SelectCountry
                          placeholder="Select country"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div>
              <TextStyles.Button disabled={isLoading}>
                {isLoading ? <LoaderSpin /> : "Create client"}
              </TextStyles.Button>
            </div>
          </form>
          {backBtn}
        </Styled.SetupBox>
      </div>
    </div>
  );
};

export default CreateClient;
