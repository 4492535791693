import React, { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateReimbursementPC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

const ExpenseReimbursement = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      reimbursement: {
        required: false,
      },
    };
    dispatch(updateReimbursementPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.reimbursement &&
          selectedData.reimbursement.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          Expense Reimbursement
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>
          Client shall reimburse all reasonable expenses that have been
          pre-authorized in writing by the Client in advance. Payment for such
          expenses shall be made within
          <span
            className="mx-1"
            style={{ color: Colors.primary, fontWeight: 500 }}
          >
            {selectedData &&
              selectedData.payment_terms &&
              selectedData.payment_terms.payment_due}
          </span>
          of receipt of an itemized invoice.
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {confirmDialog && (
        <RemoveModal
          title={"Expense Reimbursement"}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default ExpenseReimbursement;
