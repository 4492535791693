import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateEntireContractPC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

import EntireContractModal from "../../PageModals/EntireContractModal";

const EntireContract = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData &&
    selectedData.entire_contract &&
    selectedData.entire_contract.heading
      ? selectedData.entire_contract.heading
      : "Entire Contract";

  const paragraph =
    selectedData &&
    selectedData.entire_contract &&
    selectedData.entire_contract.paragraph
      ? selectedData.entire_contract.paragraph
      : `This Agreement, together with Attachment B and any exhibits, schedules, or attachments, prevails over any prior oral or written arrangements and may only be modified by a future written agreement signed by both Parties.`;

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      entire_contract: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateEntireContractPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.entire_contract &&
          selectedData.entire_contract.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ms-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <EntireContractModal
          selectedData={selectedData}
          heading={heading}
          paragraph={paragraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default EntireContract;
