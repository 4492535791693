import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import TextStyles from "../../../../../Constants/TextStyles";
import ClearIcon from "@mui/icons-material/Clear";
import Colors from "../../../../../Constants/Colors";
import { postRequest } from "../../../../../axios/axios";
import LoaderSpin from "../../../Commons/LoaderSpin";
import { changeUserEmail } from "../../../../../store/service/AuthService";

const UpdateEmail = ({ show, handleClose }) => {
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    reset({
      email: localStorage.getItem("email") || "",
      OTP: localStorage.getItem("OTP") || "",
    });
  }, [isSubmitSuccessful]);

  const changeEmailHandler = (data) => {
    setIsLoading(true);

    const token = localStorage.getItem("token");
    localStorage.setItem("email", data.email);

    if (!data.OTP) {
      postRequest("/auth/profile/update-email", token, { email: data.email })
        .then((res) => {
          if (res.data.success) {
            setIsLoading(false);
            toast.success(
              <TextStyles.FontSize14px>
                {res.data.message}
              </TextStyles.FontSize14px>
            );

            setShowOTPInput(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(
            <TextStyles.FontSize14px>
              {err.response && err.response.data.message}
            </TextStyles.FontSize14px>
          );
        });
      return;
    } else {
      localStorage.setItem("OTP", data.OTP);

      dispatch(changeUserEmail(data)).then((data) => {
        if (data.payload && data.payload.success) {
          setIsLoading(false);

          toast.success(
            <TextStyles.FontSize14px>
              {data.payload.message}
            </TextStyles.FontSize14px>
          );

          localStorage.removeItem("email");
          localStorage.removeItem("OTP");
          handleClose();
        } else {
          setIsLoading(false);
          toast.error(
            <TextStyles.FontSize14px>
              {data.error && data.error.message}
            </TextStyles.FontSize14px>
          );
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <form onSubmit={handleSubmit(changeEmailHandler)}>
        <div className="modal-header border-0">
          <TextStyles.FontSize20px className="d-flex justify-content-start w-100">
            <span className="three-dots">Change Email</span>
          </TextStyles.FontSize20px>

          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </div>

        <div className="modal-body">
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="newEmail" className="mb-2">
              Email
            </label>
            <TextStyles.InputRectangle
              id="newEmail"
              placeholder="Enter new email"
              disabled={isLoading}
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[A-Za-z0-9+._]+@[A-Za-z0-9]+\.[A-Za-z]{2}/i,
                  message: "Please enter valid email.",
                },
              })}
            />

            {errors.email && (
              <TextStyles.InValidFeedback>
                {errors.email.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          {showOTPInput && (
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="OTP" className="mb-2">
                OTP
              </label>
              <TextStyles.InputRectangle
                id="OTP"
                type="number"
                placeholder="Enter OTP"
                disabled={isLoading}
                {...register("OTP", {
                  required: "OTP is required.",
                })}
              />

              {errors.OTP && (
                <TextStyles.InValidFeedback>
                  {errors.OTP.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          )}

          <TextStyles.Button disabled={isLoading}>
            {isLoading ? <LoaderSpin /> : "Submit"}
          </TextStyles.Button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateEmail;
