import styled from "styled-components";
import Colors from "../../../Constants/Colors";

const SliderBox = styled.div`
  width: 25.85rem;
  display: flex;
  height: 3.15rem;
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  background-color: ${Colors.white};
`;

const SelectedItem = styled.div`
  height: 3.15rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 1.5rem;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: ${Colors.primary};
  color: ${Colors.white};
  transition: background-color 0.4s ease-out;

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const NotSelectedItem = styled(SelectedItem)`
  color: ${Colors.font1} !important;
  background-color: ${Colors.white} !important;
  transition: background-color, color 0.4s ease-out;
  &:hover {
    background-color: ${Colors.backgroundPink} !important;
    color: ${Colors.primary};
  }
`;

const SettingRectangle = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  max-width: 21.875rem;
  // height: 14.375rem;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.9rem 1.5rem 1.6rem 1.5rem;
  border: 2px solid #dcdbdf;
  background-color: ${Colors.white};
  border-radius: 9px;

  &::after {
    content: "";
    opacity: 0;
    position: absolute;
    transform: rotate(45deg);
    width: 2.5rem;
    height: 2.5rem;
    background: ${Colors.primary};
    z-index: 1;
    top: -1.25rem;
    right: -1.25rem;
  }

  &:hover {
    border: 2px solid ${Colors.primary};
  }

  &:hover&::after {
    opacity: 1;
  }

  &:hover img {
    filter: invert(40%) sepia(52%) saturate(1786%) hue-rotate(277deg)
      brightness(50%) contrast(84%) !important;
  }
`;

const UserInfoBox = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  width: 19.875rem !important;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
  height: 14.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const UserProfilePic = styled.div`
  .profile-pic {
    color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }

  .profile-pic input {
    display: none;
  }

  .profile-pic img {
    position: absolute;
    object-fit: fill;
    top: 0;
    left: 0;
    width: 10.3125rem;
    height: 10.3125rem;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
    border-radius: 6.25rem;
    z-index: 0;
  }

  .profile-pic .-label {
    cursor: pointer;
    height: 10.3125rem;
    width: 10.3125rem;
  }

  .profile-pic:hover .-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    color: var(--white);
    transition: background-color 0.2s ease-in-out;
    border-radius: 6.25rem;
  }

  .profile-pic span {
    display: inline-flex;
    padding: 0.2em;
  }
`;

const brandLogoHover = styled.span`
  display: flex;
  cursor: pointer;
  padding: 6px 15px;

  &:hover {
    background-color: #f2ecfb;
    border-radius: 8px;
  }
`;

export default {
  SliderBox,
  SelectedItem,
  NotSelectedItem,
  SettingRectangle,
  UserInfoBox,
  UserProfilePic,
  brandLogoHover,
};
