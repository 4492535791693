import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editContact } from "../../../../../store/service/clientService";

import { useForm, Controller } from "react-hook-form";

import { toast } from "react-toastify";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../Constants/TextStyles";
import { Modal } from "react-bootstrap";
import SelectSTDCode from "../../../Commons/SelectSTDCode";
import Colors from "../../../../../Constants/Colors";

const EditContact = ({ handleClose, show, selectedContactData }) => {
  const dispatch = useDispatch();
  const data = selectedContactData && selectedContactData;
  const isLoading = useSelector((state) => state.clients.isLoading);

  const {
    register,
    reset,
    setValue,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      contact_name: data && data.contact_name,
      email: data && data.email,
      role: data && data.role,
      phone_code: data && data.phone_code,
      phone: data && data.phone,
    },
  });

  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[A-Za-z0-9+._]+@[A-Za-z0-9]+\.[A-Za-z]{2}/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const formsubmit = (data) => {
    const Obj = {
      client_id: selectedContactData && selectedContactData.client_id,
      contact_id: selectedContactData && selectedContactData._id,
      contact_name: data.contact_name,
      email: data.email,
      role: data.role,
      phone_code: data.phone_code,
      phone: data.phone,
    };
    dispatch(editContact(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
        reset();
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="ToMakeModalBorderRudius12px"
        centered
        scrollable
      >
        <form
          className="modal-content"
          onSubmit={handleSubmit(formsubmit)}
        >
          <div className="modal-header d-flex justify-content-between">
            <TextStyles.FontSize20px>
              Edit contact
            </TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </div>
          <div className="modal-body">
            <TextStyles.FontSize14px className="mb-2">
              <label htmlFor="contact_name" className="mb-2">
                Contact name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.contact_name}
                id="contact_name"
                type="text"
                placeholder="Contact name"
                name="contact_name"
                {...register("contact_name", {
                  required: "Contact name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Contact name is required.";
                    }
                    return true;
                  },
                })}
              />
              {errors.contact_name && (
                <TextStyles.InValidFeedback>
                  {errors.contact_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-2">
              <label htmlFor="email" className="mb-2">
                Email address
              </label>
              <TextStyles.InputRectangle
                invalid={errors.email}
                id="email"
                type="text"
                placeholder="Email address"
                name="email"
                {...register("email", {
                  required: "Email is required.",
                  validate: validateEmail,
                  onBlur: (e) => setValue("email", e.target.value.trim()),
                })}
              />
              {errors.email && (
                <TextStyles.InValidFeedback>
                  {errors.email.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-2">
              <label htmlFor="role" className="mb-2">
                Role
              </label>
              <TextStyles.InputRectangle
                id="role"
                type="text"
                placeholder="Enter role"
                name="role"
                {...register("role")}
              />
              {errors.role && (
                <TextStyles.InValidFeedback>
                  {errors.role.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="phone" className="mb-2">
                Select phone code
              </label>
              <Controller
                control={control}
                name="phone_code"
                render={({ field: { onChange, value } }) => (
                  <SelectSTDCode
                    placeholder="Select code"
                    onChange={onChange}
                    defaultValue={value}
                  />
                )}
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-4">
              <label htmlFor="phone" className="mb-2">
                Phone
              </label>
              <TextStyles.InputRectangle
                id="phone"
                type="text"
                placeholder="Phone"
                name="phone"
                {...register("phone", {
                  pattern: {
                    value: /^[\d\s-]{7,16}$/,
                    message: "Please enter valid phone number.",
                  },
                })}
              />
              {errors.phone && (
                <TextStyles.InValidFeedback>
                  {errors.phone.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          </div>
          <div className="modal-footer" style={{ display: "inline-block" }}>
            <div className="d-flex justify-content-between gap-3">
              <TextStyles.CancelButton onClick={handleClose}>
                <div className="d-flex justify-content-center">Cancel</div>
              </TextStyles.CancelButton>
              <TextStyles.Button type="submit" disabled={isLoading}>
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </div>
        </form>
      </Modal>
  );
};

export default EditContact;
