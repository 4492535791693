import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjects,
  fetchProjectForOptions,
  fetchProjectsForAClient,
  createProject,
  fetchProjectById,
  changeProjectStatus,
  updateProject,
  uProjectStartDate,
  uProjectEndDate,
  uProjectBillingScheduleStartDate,
  updateProjectNotes,
  fetchProjectCounts,
} from "../service/projectService";

const projectState = {
  projects: null,
  projectOptionsData: null,
  prTotalCount: null,
  projectsForAClient: null,
  selectedProject: null,
  isLoading: false,
  error: null,
};

export const projectSlice = createSlice({
  name: "projects",
  initialState: projectState,
  reducers: {
    setSelectedProject: (state, { payload }) => {
      state.selectedProject = payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch all projects
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projects = action.payload.data.data;
        state.prTotalCount = action.payload.data.prTotalCount;
        state.isLoading = false;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Fetch all projects for options
    builder
      .addCase(fetchProjectForOptions.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(fetchProjectForOptions.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.projectOptionsData = action.payload.data;
      })
      .addCase(fetchProjectForOptions.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      });

    // Fetch all projects for a client
    builder
      .addCase(fetchProjectsForAClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectsForAClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projectsForAClient = action.payload.data;
      })
      .addCase(fetchProjectsForAClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Create a project
    builder
      .addCase(createProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProject.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Fetch project by id
    builder
      .addCase(fetchProjectById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProject = action.payload.data;
      })
      .addCase(fetchProjectById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Change project status
    builder
      .addCase(changeProjectStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeProjectStatus.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(changeProjectStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Update whole project
    builder
      .addCase(updateProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.selectedProject = action.payload.data;
        state.isLoading = false;
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Update project start date
    builder
      .addCase(uProjectStartDate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uProjectStartDate.fulfilled, (state, action) => {
        state.selectedProject.project_start_date =
          action.payload.data.project_start_date;
        state.isLoading = false;
      })
      .addCase(uProjectStartDate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Update project end date
    builder
      .addCase(uProjectEndDate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uProjectEndDate.fulfilled, (state, action) => {
        state.selectedProject.project_end_date =
          action.payload.data.project_end_date;
        state.isLoading = false;
      })
      .addCase(uProjectEndDate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Update project billing schedule start date
    builder
      .addCase(uProjectBillingScheduleStartDate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uProjectBillingScheduleStartDate.fulfilled, (state, action) => {
        state.selectedProject.billing_scheduled =
          action.payload.data.billing_scheduled;
        state.isLoading = false;
      })
      .addCase(uProjectBillingScheduleStartDate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Update project notes
    builder
      .addCase(updateProjectNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProjectNotes.fulfilled, (state, action) => {
        state.selectedProject.notes = action.payload.data.notes;
        state.isLoading = false;
      })
      .addCase(updateProjectNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Fetch project counts
    builder
      .addCase(fetchProjectCounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectCounts.fulfilled, (state, action) => {
        state.prTotalCount = action.payload.data.prTotalCount;
        state.isLoading = false;
      })
      .addCase(fetchProjectCounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const projectsActions = projectSlice.actions;

export default projectSlice.reducer;
