import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import TextStyles from "../../../../../Constants/TextStyles";
import {
  updateBrandColor,
  updateBrandLogo,
  removeBrandLogo,
  updateLogoAlignment,
} from "../../../../../store/service/AuthService";
import CustomColorPicker from "../../../Commons/CustomColorPicker";
import Colors from "../../../../../Constants/Colors";
import Styled from "../../SettingStyles";
import styled from "styled-components";

const StyledUploadBrandLogoLabel = styled.label`
  width: 180px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: ${Colors.white};
  border: 2px solid ${Colors.borderInput};
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border: 2px solid ${Colors.primary};
  }

  &:active {
    transform: scale(0.96);
  }
`;

const StyledRemoveLogoBtn = styled.button`
  width: 180px;
  padding: 12px;
  background: ${Colors.white};
  border: 2px solid ${Colors.borderInput};
  border-radius: 12px;

  &:hover {
    border: 2px solid ${Colors.primary};
  }

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    border: 2px solid ${Colors.borderInput};
    transform: scale(1);
    cursor: default;
  }
`;

const BrandAndLogo = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const brandAndLogoData = useSelector((state) => state.auth.brandAndLogoData);
  const isLoading = useSelector((state) => state.auth.isLoading);

  const [arrowForColor, setArrowForColor] = useState(false);
  const [arrowForLogo, setArrowForLogo] = useState(false);
  const [arrowForAlign, setArrowForAlign] = useState(false);
  const [image, setImage] = useState(
    brandAndLogoData &&
      brandAndLogoData.brand_logo &&
      brandAndLogoData.brand_logo.file_name
      ? true
      : false
  );

  const timeout = useRef();
  useEffect(() => {
    setImage(
      brandAndLogoData &&
        brandAndLogoData.brand_logo &&
        brandAndLogoData.brand_logo.file_name
        ? true
        : false
    );
  }, [brandAndLogoData]);

  const updateBrandColorHandlerChange = (color) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      let Obj = {
        brand_color: color,
      };
      dispatch(updateBrandColor(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          toast.success(
            <TextStyles.FontSize14px>
              {data.payload.message}
            </TextStyles.FontSize14px>
          );
        } else {
          toast.error(
            <TextStyles.FontSize14px>
              {data.error.message}!
            </TextStyles.FontSize14px>
          );
        }
      });
    }, 1000);
  };

  const uploadLogohandleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2097152) {
        toast.error(
          <TextStyles.FontSize14px>
            The logo's size exceeds the 2MB limit.
          </TextStyles.FontSize14px>
        );
      }
      var img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = function () {
        if (img.width > 512) {
          toast.error(
            <TextStyles.FontSize14px>
              The width of the logo exceeds 512-pixel limit.
            </TextStyles.FontSize14px>
          );
        } else if (img.height > 512) {
          toast.error(
            <TextStyles.FontSize14px>
              The height of the logo exceeds 512-pixel limit.
            </TextStyles.FontSize14px>
          );
        } else {
          const formData = new FormData();
          formData.append("file", file);
          dispatch(updateBrandLogo(formData)).then((data) => {
            if (data.meta.requestStatus === "fulfilled") {
              toast.success(
                <TextStyles.FontSize14px>
                  {data.payload.message}
                </TextStyles.FontSize14px>
              );
            } else {
              toast.error(
                <TextStyles.FontSize14px>
                  {data.error.message}!
                </TextStyles.FontSize14px>
              );
            }
          });
        }
      };
    }
  };

  const removeLogoHandlerChange = () => {
    const Obj = {
      file_name:
        brandAndLogoData &&
        brandAndLogoData.brand_logo &&
        brandAndLogoData.brand_logo.file_name,
    };
    dispatch(removeBrandLogo(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const updateLogoAlignmentHandler = (data) => {
    if (brandAndLogoData && brandAndLogoData.logo_alignment === data) return;

    const obj = {
      logo_alignment: data,
    };

    dispatch(updateLogoAlignment(obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const emailText = `Hi there 👋!\n
Thanks a lot for the opportunity to work with you!
Please approve this scope to get started.\n
Thanks for your business!\n${
    businessInfoData && businessInfoData.business_name
      ? businessInfoData.business_name
      : `${data.firstName} ${data.lastName}`
  }`;

  return (
    <div className="py-4">
      <TextStyles.FontSize20px>Logo and color</TextStyles.FontSize20px>
      <TextStyles.GreyFont16px className="mt-2 mb-4">
        Customize the appearance of your invoices, proposals, and email
        communication.
      </TextStyles.GreyFont16px>
      <div className="d-flex flex-wrap">
        <ul style={{ width: "100%", maxWidth: 500 }}>
          <li className="mb-3">
            <TextStyles.GreyFont18px className="d-flex align-items-center mb-2">
              <Styled.brandLogoHover
                onClick={() => setArrowForColor(!arrowForColor)}
              >
                <div>Brand color</div>
                <ExpandMoreIcon
                  sx={{ fontSize: 30, rotate: arrowForColor ? "180deg" : "" }}
                />
              </Styled.brandLogoHover>
            </TextStyles.GreyFont18px>
            {arrowForColor && (
              <CustomColorPicker
                handleChange={updateBrandColorHandlerChange}
                defaultValues={brandAndLogoData && brandAndLogoData.brand_color}
              />
            )}
          </li>

          <li className="mb-3">
            <TextStyles.GreyFont18px className="d-flex align-items-center mb-2">
              <Styled.brandLogoHover
                onClick={() => setArrowForLogo(!arrowForLogo)}
              >
                <div>Brand logo</div>
                <ExpandMoreIcon
                  sx={{ fontSize: 30, rotate: arrowForLogo ? "180deg" : "" }}
                />
              </Styled.brandLogoHover>
            </TextStyles.GreyFont18px>

            {arrowForLogo && !image && (
              <TextStyles.FontSize16px className="d-flex flex-column gap-3">
                <TextStyles.GreyFont14px
                  className="text-start"
                  style={{ maxWidth: 220 }}
                >
                  Kindly provide a JPG, JPEG or PNG file for the logo, ensuring
                  that its dimensions are within 512 x 512 pixels, and that the
                  file size does not exceed 2 MB.
                </TextStyles.GreyFont14px>

                <div className="d-flex flex-column gap-3">
                  <input
                    type="file"
                    id="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={uploadLogohandleChange}
                    style={{
                      width: "100%",
                      height: 50,
                      borderRadius: "12px 0 0 12px",
                      background: "transparent",
                      display: "none",
                    }}
                  />
                  <StyledUploadBrandLogoLabel htmlFor="file">
                    <CloudUploadIcon
                      sx={{ fontSize: 27, color: Colors.primary }}
                    />
                    <TextStyles.GreyFont16px
                      className="ms-2"
                      style={{
                        fontFamily: 500,
                      }}
                    >
                      Upload logo
                    </TextStyles.GreyFont16px>
                  </StyledUploadBrandLogoLabel>
                </div>
              </TextStyles.FontSize16px>
            )}

            {arrowForLogo && image && (
              <TextStyles.FontSize16px className="d-flex gap-3">
                <StyledRemoveLogoBtn
                  disabled={isLoading}
                  onClick={removeLogoHandlerChange}
                >
                  <TextStyles.GreyFont16px>Remove logo</TextStyles.GreyFont16px>
                </StyledRemoveLogoBtn>
              </TextStyles.FontSize16px>
            )}
          </li>

          {image && (
            <li className="mb-4">
              <TextStyles.GreyFont18px className="d-flex align-items-center mb-2">
                <Styled.brandLogoHover
                  onClick={() => setArrowForAlign(!arrowForAlign)}
                >
                  <div>Logo alignment</div>
                  <ExpandMoreIcon
                    sx={{ fontSize: 30, rotate: arrowForAlign ? "180deg" : "" }}
                  />
                </Styled.brandLogoHover>
              </TextStyles.GreyFont18px>
              {arrowForAlign && (
                <TextStyles.FontSize16px className="d-flex gap-3">
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: 220 }}
                  >
                    <div className="d-flex flex-column gap-2 w-90">
                      <TextStyles.FontSize16px className="d-flex">
                        Left
                      </TextStyles.FontSize16px>
                      <TextStyles.FontSize16px className="d-flex">
                        <button
                          disabled={isLoading}
                          onClick={() => updateLogoAlignmentHandler("start")}
                        >
                          <FormatAlignLeftIcon
                            sx={{
                              fontSize: 27,
                              color:
                                brandAndLogoData &&
                                brandAndLogoData.logo_alignment === "start"
                                  ? brandAndLogoData &&
                                    brandAndLogoData.brand_color
                                  : Colors.font2,
                            }}
                          />
                        </button>
                      </TextStyles.FontSize16px>
                    </div>
                    <div className="d-flex flex-column gap-2 w-100">
                      <TextStyles.FontSize16px className="d-flex justify-content-center">
                        Center
                      </TextStyles.FontSize16px>
                      <TextStyles.FontSize16px className="d-flex justify-content-center">
                        <button
                          disabled={isLoading}
                          onClick={() => updateLogoAlignmentHandler("center")}
                        >
                          <FormatAlignCenterIcon
                            sx={{
                              fontSize: 27,
                              color:
                                brandAndLogoData &&
                                brandAndLogoData.logo_alignment === "center"
                                  ? brandAndLogoData &&
                                    brandAndLogoData.brand_color
                                  : Colors.font2,
                            }}
                          />
                        </button>
                      </TextStyles.FontSize16px>
                    </div>
                    <div className="d-flex flex-column gap-2 w-110">
                      <TextStyles.FontSize16px className="d-flex justify-content-end">
                        Right
                      </TextStyles.FontSize16px>
                      <TextStyles.FontSize16px className="d-flex justify-content-end">
                        <button
                          disabled={isLoading}
                          onClick={() => updateLogoAlignmentHandler("end")}
                        >
                          <FormatAlignRightIcon
                            sx={{
                              fontSize: 24,
                              color:
                                brandAndLogoData &&
                                brandAndLogoData.logo_alignment === "end"
                                  ? brandAndLogoData &&
                                    brandAndLogoData.brand_color
                                  : Colors.font2,
                            }}
                          />
                        </button>
                      </TextStyles.FontSize16px>
                    </div>
                  </div>
                </TextStyles.FontSize16px>
              )}
            </li>
          )}
        </ul>

        <div
          className="p-2 p-sm-3 p-md-4"
          style={{
            width: "100%",
            maxWidth: 600,
            height: "fit-content",
            background: Colors.white,
            borderTop: `4px solid ${
              brandAndLogoData && brandAndLogoData.brand_color
                ? brandAndLogoData.brand_color
                : Colors.primary
            }`,
            boxShadow: "0px 0px 9px 0px lightgrey",
          }}
        >
          {brandAndLogoData &&
            brandAndLogoData.brand_logo &&
            brandAndLogoData.brand_logo.file_url && (
              <div
                className={
                  brandAndLogoData && brandAndLogoData.logo_alignment
                    ? `text-${brandAndLogoData.logo_alignment} mb-3`
                    : "text-center mb-3"
                }
              >
                <img
                  src={brandAndLogoData.brand_logo.file_url}
                  alt="logoImg"
                  style={{
                    maxHeight: 130,
                  }}
                />
              </div>
            )}

          <TextStyles.FontSize20px
            className="mb-1 mt-4 text-center"
            style={{ fontWeight: 700 }}
          >
            Sample preview
          </TextStyles.FontSize20px>
          {businessInfoData && businessInfoData.business_name !== null && (
            <TextStyles.GreyFont16px className="mb-5 text-center">
              {businessInfoData.business_name}
            </TextStyles.GreyFont16px>
          )}
          {businessInfoData && businessInfoData.business_name === null && (
            <TextStyles.FontSize16px className="mb-5">
              {data && `${data.firstName} ${data.lastName}`}
            </TextStyles.FontSize16px>
          )}
          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start mb-5"
            style={{ whiteSpace: "break-spaces" }}
          >
            {emailText}
          </TextStyles.FontSize14px>

          <TextStyles.Button
            style={{
              cursor: "unset",
              fontWeight: 500,
              fontSize: 16,
              background:
                brandAndLogoData && brandAndLogoData.brand_color
                  ? brandAndLogoData.brand_color
                  : Colors.primary,
            }}
          >
            Preview button
          </TextStyles.Button>
        </div>
      </div>
    </div>
  );
};

export default BrandAndLogo;
